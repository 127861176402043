/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import {
  getUserInfo,
  tryModifyEmailOrMobile,
  verifyEmail,
  sendVerifyCodeByName,
  sendPhoneVerifyCode,
} from "@shared/scripts/features/api";
import ReduxWrapper from "../utils/ReduxWrapper";

const MOBILE = 0;
const EMAIL = 1;

class Inline extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    const { title, children } = this.props;
    return (
      <div className="user-set__inline">
        <span className="user-set__lable">{title}:</span>
        {children}
      </div>
    );
  }
}

class InlineBind extends PureComponent {
  static propTypes = {
    tip: PropTypes.string,
    desc: PropTypes.string.isRequired,
    bindAction: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tip: "绑定",
  };

  render() {
    const { bindAction, tip, desc } = this.props;
    return (
      <Fragment>
        <span className="user-set__tip">{desc}</span>
        <div className="user-set__right">
          <a
            className="u-btn--red user-set__btn"
            href="javascript:;"
            alt="绑定"
            onClick={bindAction}
          >
            {tip}
          </a>
        </div>
      </Fragment>
    );
  }
}

class InlineModify extends PureComponent {
  static propTypes = {
    desc: PropTypes.string.isRequired,
    bindAction: PropTypes.func.isRequired,
    isNeedActivation: PropTypes.bool,
    children: PropTypes.element,
  };

  static defaultProps = {
    isNeedActivation: false,
    children: null,
  };

  render() {
    const { bindAction, desc, isNeedActivation, children } = this.props;
    return (
      <Fragment>
        <span className="user-set__label">
          {desc}
          {isNeedActivation && <span className="user-set__verify">未激活</span>}
        </span>
        <div className="user-set__right">
          <a
            className="u-btn--gray user-set__btn"
            href="javascript:;"
            alt="修改"
            onClick={bindAction}
          >
            修改
          </a>
          {children}
        </div>
      </Fragment>
    );
  }
}

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  fetchDate = () => {
    getUserInfo().done(res => {
      this.setState({
        user: res.user,
      });
    });
  };

  verifyEmail = () => {
    verifyEmail().done(res => {
      if (res.success === "sended") {
        Salert.success("已发送验证地址到您的邮箱，请查收！");
      } else {
        Salert.error("请重试");
      }
    });
  };

  openChooseModal = (type, activeIndex = 0, checkedType = MOBILE) => {
    this.props.actions.openModal("choose", {
      type,
      activeIndex,
      checkedType,
      user: this.state.user,
      fetchDate: this.fetchDate,
      sendVerifyCode: this.sendVerifyCode,
      phoneVerifyCode: this.phoneVerifyCode,
    });
  };

  openEmailModal = isFirstBind => {
    this.props.actions.openModal("email", {
      user: this.state.user,
      fetchDate: this.fetchDate,
      isBind: isFirstBind,
    });
  };

  openMobileModal = () => {
    this.props.actions.openModal("mobile", {
      user: this.state.user,
      fetchDate: this.fetchDate,
      phoneVerifyCode: this.phoneVerifyCode,
    });
  };

  firstSetPwd = () => {
    this.changePwd(true);
  };

  changePwd = (isFirstSet = false) => {
    this.props.actions.openModal("password", {
      isFirstSet,
      fetchDate: this.fetchDate,
      user: this.state.user,
    });
  };

  // 修改账户绑定之前 发送验证码
  sendVerifyCode = (type = MOBILE, cb = () => {}) => {
    const { user } = this.state;
    sendVerifyCodeByName(type === MOBILE ? user.mobile : user.email.email).done(
      res => {
        if (res.success) {
          Salert.success("验证码已发出!");
          cb();
        }
      }
    );
  };

  // 更改 绑定手机发送验证码
  phoneVerifyCode = (mobile, cb) => {
    sendPhoneVerifyCode(mobile).done(res => {
      if (res.success) {
        Salert.success("验证码已发出!");
        cb();
      } else {
        Salert.error(res.responseJSON.errors[0]);
      }
    });
  };

  modify(type = "mobile") {
    tryModifyEmailOrMobile(type)
      .done(res => {
        if (res.success === "ok") {
          if (type === "mobile") {
            return this.openMobileModal();
          }
          return this.openEmailModal();
        }

        return this.openChooseModal(type);
      })
      .fail(xhr => {
        const res = xhr.responseJSON;
        // email or mobile verification
        if (res.length === 2) {
          return this.openChooseModal(type);
        }
        // only mobile or only email verification
        return this.openChooseModal(
          type,
          1,
          res[0].type === "mobile" ? MOBILE : EMAIL
        );
      });
  }

  render() {
    const { mobile, email, password } = this.state.user;

    return (
      <Fragment>
        <Inline title="手机">
          {mobile === null ? (
            <InlineBind
              desc="手机用于登录账号，重置密码或者其他安全登录"
              bindAction={() => {
                this.modify("mobile");
              }}
            />
          ) : (
            <InlineModify
              desc={mobile
                .toString()
                .replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}
              bindAction={() => {
                this.modify("mobile");
              }}
            />
          )}
        </Inline>
        <Inline title="邮箱">
          {email.email === null ? (
            <InlineBind
              desc="邮箱可以用户登录账号，重置密码或者其他安全登录"
              bindAction={() => {
                this.modify("email");
              }}
            />
          ) : (
            <InlineModify
              desc={email.email}
              bindAction={() => {
                this.modify("email");
              }}
              isNeedActivation={!email.verified}
            >
              {!email.verified ? (
                <a
                  className="u-btn--red user-set__btn"
                  href="javascript:;"
                  alt="激活邮箱"
                  onClick={this.verifyEmail}
                >
                  激活
                </a>
              ) : null}
            </InlineModify>
          )}
        </Inline>
        <Inline title="账户密码">
          {password === null ? (
            <InlineBind
              desc="建议设置账户密码，以保证帐号更加安全。"
              bindAction={this.firstSetPwd}
              tip="设置"
            />
          ) : (
            <InlineModify desc="******" bindAction={() => this.changePwd()} />
          )}
        </Inline>
      </Fragment>
    );
  }
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(UserInfo);
