import React from "react";
import PropTypes from "prop-types";

class ExpertItem extends React.PureComponent {
  render() {
    const { name, desc, technologies } = this.props;

    return (
      <div className="search-record__inner">
        <a className="article-item__title" href="javascript:;">
          {name}
        </a>
        <p
          className="u-base-summary search-index__more"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
        <div className="graph__fields">
          <span className="search-index__tip">研究方向：</span>
          {technologies.map(technology => (
            <a key={technology.id} className="graph__field" href="javascript:;">
              {technology.name}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

ExpertItem.propTypes = {
  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  technologies: PropTypes.array.isRequired,
};

export default ExpertItem;
