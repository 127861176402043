import React from "react";
import PropTypes from "prop-types";

class PhaseList extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    openPannel: PropTypes.func.isRequired,
  };

  render() {
    const { list, openPannel } = this.props;
    return list.map(({ id, cover_image_url, title }, index) => (
      <div key={id} className="internal-reference__item">
        <a
          className="u-image-base internal-reference__cover"
          href="javascript:;"
          alt={title}
          onClick={() => openPannel(id, index)}
        >
          <img
            className="u-image-center"
            src={`${cover_image_url}?imageView2/1/w/564/h/320`}
            alt={title}
          />
        </a>
      </div>
    ));
  }
}

export default PhaseList;
