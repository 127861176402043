import React from "react";
import PropTypes from "prop-types";

class UserMomentLoading extends React.PureComponent {
  render() {
    const random = parseInt(Math.random() * 200, 10);

    return (
      <div className={`u-loading ${this.props.isFetchMore ? "is-show" : ""}`}>
        <svg
          version="1.1"
          preserveAspectRatio="none"
          width="100%"
          height="150px"
        >
          <rect
            clipPath={`url(#5tn917qzk63${random})`}
            x="0"
            y="0"
            width="100%"
            height="130"
            style={{ fill: `url("#3w2fipq1m1i${random}")` }}
          />
          <defs>
            <clipPath id={`5tn917qzk63${random}`}>
              <circle cx="24" cy="44" r="24" />

              <rect x="64" y="20" width="70%" height="12" />
              <rect x="64" y="52" width="30%" height="12" />
              <rect x="64" y="94" width="50%" height="12" />
            </clipPath>
            <linearGradient id={`3w2fipq1m1i${random}`}>
              <stop offset="0.25" stopColor="#f2f2f2">
                <animate
                  attributeName="offset"
                  values="-2; 1"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="0.75" stopColor="#f9f9f9">
                <animate
                  attributeName="offset"
                  values="-1.5; 1.5"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="1.25" stopColor="#f2f2f2">
                <animate
                  attributeName="offset"
                  values="-1; 2"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
  }
}

UserMomentLoading.propTypes = {
  isFetchMore: PropTypes.bool,
};

UserMomentLoading.defaultProps = {
  isFetchMore: false,
};

export default UserMomentLoading;
