import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import ARTICLES_QUERY from "@graphql/starArticles.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItemLoading from "../loading/ArticleItemLoading";
import ArticleItem from "../shared/ArticleItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(ARTICLES_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      id: props.currentUserId,
    },
  }),
})
@WaterFallWrapper(["user", "star_articles"], ArticleItemLoading)
class StarArticles extends React.Component {
  render() {
    const { edges } = this.props;

    return edges.map(edge => (
      <ArticleItem key={edge.node.path} {...edge.node} isPreciseTime />
    ));
  }
}

StarArticles.propTypes = {
  edges: PropTypes.array,
};

StarArticles.defaultProps = {
  edges: [],
};

export default ApolloWrapper(StarArticles);
