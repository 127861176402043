import React from "react";
import PropTypes from "prop-types";

const Industries = ({ industries }) => {
  if (industries.length === 0) return null;

  return (
    <span>
      <span className="industry-solution__label">行业：</span>
      {industries.map(({ id, name }) => (
        <span key={id} className="industry__tag">
          {name}
        </span>
      ))}
    </span>
  );
};

Industries.propTypes = {
  industries: PropTypes.array,
};

Industries.defaultProps = {
  industries: [],
};

export default Industries;
