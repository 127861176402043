import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class CropImage extends React.PureComponent {
  cancelHandle = () => {
    this.props.actions.closeModal();
  };

  saveHandle = () => {
    this.props.cropHandle(this.cropper.getCroppedCanvas().toDataURL());
  };

  render() {
    return (
      <Fragment>
        <Cropper
          ref={cropper => {
            this.cropper = cropper;
          }}
          src={this.props.src}
          style={{ maxHeight: 300 }}
          aspectRatio={this.props.aspectRatio}
          viewMode={1}
          guides
          autoCropArea={1}
        />
        <div className="u-text-center crop--footer">
          <button
            className="u-btn--gray crop--btn"
            type="button"
            onClick={this.cancelHandle}
          >
            取消
          </button>
          <button
            className="u-btn--red crop--btn t-last"
            type="button"
            onClick={this.saveHandle}
          >
            应用
          </button>
        </div>
      </Fragment>
    );
  }
}

CropImage.defaultProps = {
  aspectRatio: NaN,
};

CropImage.propTypes = {
  actions: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  cropHandle: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
};

export default CropImage;
