import React, { Fragment } from "react";
import PropTypes from "prop-types";

import logoPath from "logo-black.png";
import ReduxWrapper from "../utils/ReduxWrapper";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";
import SmForm from "./SmForm";
import wechatCircle from "wechat-circle.png";
import greenWechat from "wechat-circle-green.png";
import githubCircle from "github-circle.png";
import blackGithub from "github-circle-black.png";
import weiboCircle from "weibo-circle.png";
import redWeibo from "weibo-circle-red.png";

class SignModal extends React.Component {
  state = {
    toSignIn: true,
    isSignIn: !!window.currentUser,
    isSm: window.currentUser ? window.currentUser.mobile_verified : true,
  };

  wxLogin = () => {
    let url = "";
    const mdetect = require("mdetect");

    if (mdetect.isWechat()) {
      url = `${window.location.origin}/auth/wechatservice`;
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=wx8c9a93acb12e2b2d&scope=snsapi_login&redirect_uri=${window.location.origin}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    }
    window.location.href = url;
  };

  // 操作动作
  switchAction() {
    const { toSignIn } = this.state;
    this.setState({
      toSignIn: !toSignIn,
    });
  }

  // 是否登录
  setSignIn(value) {
    this.setState({
      isSignIn: !!value,
    });
  }

  // 实名
  setSm(value) {
    this.setState({
      isSm: !!value,
    });
  }

  render() {
    const { closeModal } = this.props.actions;
    const { toSignIn, isSignIn, isSm } = this.state;
    const closeFn = () => {
      if (toSignIn && isSignIn && !isSm) {
        return () =>
          $.ajax({
            url: "/logout",
            type: "GET",
            dataType: "json",
            success: res => {
              console.log(res);
              window.location.reload();
            },
          });
      } else {
        return closeModal;
      }
    };

    console.log(toSignIn, isSignIn, isSm);

    return (
      <div className="signin-modal js-signin-modal">
        <div className="signin-modal__left">
          <img className="signin-modal__logo" src={logoPath} alt="logo" />
          {toSignIn ? (
            isSignIn && !isSm ? (
              <Fragment>
                <h2 className="signin-modal__title">用户认证</h2>
                <SmForm setSm={value => this.setSm(value)} />
              </Fragment>
            ) : (
              <Fragment>
                <h2>欢迎回来</h2>
                <div className="signin-modal-wx" onClick={this.wxLogin}>
                  <i className="iconfont icon-wechat" />
                  使用微信快捷登录
                </div>
                <div className="signin-modal-line">
                  <p>或使用账号密码登录</p>
                </div>
                <SigninForm
                  switchAction={() => {
                    this.switchAction();
                  }}
                  setSm={value => this.setSm(value)}
                  setSignIn={value => this.setSignIn(value)}
                />
              </Fragment>
            )
          ) : (
            <Fragment>
              <h2>欢迎加入</h2>
              <SignupForm
                switchAction={() => {
                  this.switchAction();
                }}
                setSm={value => this.setSm(value)}
                setSignIn={value => this.setSignIn(value)}
              />
            </Fragment>
          )}
          {!(isSignIn && !isSm) && (
            <div className="signin-form__btns">
              <div className="signin-form__line">
                <p>其他快捷方式</p>
              </div>
              <a
                className="signin-form__btn wechat"
                ref="wechat"
                href="javascript:;"
                title="微信登录"
                onClick={this.wxLogin}
              >
                <img className="wechat-img" src={wechatCircle} alt="wechat" />
                <img
                  className="wechat-img--hover"
                  src={greenWechat}
                  alt="wechat"
                />
              </a>
              <a
                className="signin-form__btn github"
                href="/auth/github"
                title="GitHub登录"
              >
                <img className="github-img" src={githubCircle} alt="github" />
                <img
                  className="github-img--hover"
                  src={blackGithub}
                  alt="github"
                />
              </a>
              <a
                className="signin-form__btn weibo"
                href="/auth/weibo"
                title="微博登录"
              >
                <img className="weibo-img" src={weiboCircle} alt="weibo" />
                <img className="weibo-img--hover" src={redWeibo} alt="weibo" />
              </a>
            </div>
          )}
          <div className="signin-form__xy">
            注册即代表我已阅读并同意{" "}
            <a
              href="https://www.jiqizhixin.com/short_urls/f7dd70ed-41d4-47c0-ad8f-5871ed7a03c8"
              target="_blank"
            >
              《用户协议》
            </a>
          </div>
        </div>
        <div className="signin-modal__right">
          <div className="signin-modal__body" />
        </div>
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={closeFn()}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>
    );
  }
}

SignModal.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(SignModal);
