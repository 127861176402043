/* eslint-disable function-paren-newline */
import React from "react";
import PropTypes from "prop-types";

// import EventItem from './items/EventItem';
import ArticleItem from "./items/ArticleItem";
// import PaperItem from './items/PaperItem';
// import InstitutionItem from './items/InstitutionItem';
import SolutionItem from "./items/SolutionItem";

class CategoryAllFirstPage extends React.Component {
  render() {
    const {
      data: { articles, solutions },
      keywords,
    } = this.props;

    const rows = [];
    articles.nodes.slice(0, 2).map(article => {
      return rows.push(
        <div key={article.id} className="search-record__item">
          <ArticleItem {...article} params="all" type="文章" isCategoryAll />
        </div>
      );
    });

    if (solutions.nodes.length > 0) {
      solutions.nodes.map(solution => {
        return rows.push(
          <SolutionItem key={solution.id} {...solution} type="all">
            <a
              className="u-mute-link"
              href={`/search/solution?keywords=${keywords}`}
            >
              {" "}
              查看更多案例 &gt;
            </a>
          </SolutionItem>
        );
      });
    }

    return rows;
    // <div key={3} className="search-record__item">
    //   <PaperItem />
    // </div>,
    // <div key={5} className="search-record__item">
    //   <SolutionInAll />
    // </div>,
    // ];
  }
}

CategoryAllFirstPage.propTypes = {
  data: PropTypes.object.isRequired,
  searchInternet: PropTypes.bool,
  keywords: PropTypes.string.isRequired,
};

CategoryAllFirstPage.defaultProps = {
  searchInternet: false,
};

export default CategoryAllFirstPage;
