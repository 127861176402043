/* eslint-disable import/prefer-default-export */
// modal reducer
const initialModalState = {
  type: "hide",
  effect: "fade",
  modalType: "default",
  modalProps: null,
};

export const modal = (state = initialModalState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...state,
        type: "show",
        effect: action.effect,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case "CLOSE_MODAL":
      return { ...state, type: "hide" };
    default:
      return state;
  }
};
