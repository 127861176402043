import React from "react";
import PropTypes from "prop-types";
import { WORK } from "@shared/scripts/utils/contant";
import province from "@shared/scripts/utils/city";
import Header from "./Header";
import FormContent from "./FormContent";
import validate from "@shared/scripts/utils/inputValidate";

const CitySelect = ({ blurHandler }) => {
  return (
    <select
      className="insight-modal__select"
      name="city"
      onBlur={event => blurHandler(event)}
      defaultValue={province[0].key}
    >
      <option>请选择城市</option>
      {province.map(item => (
        <option key={item.value} value={item.value}>
          {item.key}
        </option>
      ))}
    </select>
  );
};

CitySelect.propTypes = {
  blurHandler: PropTypes.func.isRequired,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.country = "中国";
    this.city = "";

    const { defaultValue } = props;
    this.formControls = {
      school: {
        value: defaultValue.school || "",
        label: "毕业学校",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      },
      major: {
        value: defaultValue.major || "",
        label: "专业",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      },
      degree: {
        isSelect: true,
        valid: false,
        wrong: false,
        label: "学位",
        value: defaultValue.degree,
        options: [
          { key: "学士", value: "学士" },
          { key: "硕士", value: "硕士" },
          { key: "博士", value: "博士" },
          { key: "其他", value: "其他" },
        ],
        rules: {
          isRequired: true,
        },
      },
      graduation: {
        value: defaultValue.graduation_year || "",
        label: "毕业年份",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      },
    };

    if (props.checkStatus === WORK) {
      this.formControls.company = {
        value: defaultValue.company || "",
        label: "公司",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      };

      this.formControls.position = {
        value: defaultValue.position || "",
        label: "职务",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      };

      this.formControls.workYear = {
        isSelect: true,
        valid: true,
        options: [
          { key: "一年内", value: "一年内" },
          { key: "1-3年", value: "1-3年" },
          { key: "3-5年", value: "3-5年" },
          { key: "5年以上", value: "5年以上" },
        ],
        value: defaultValue.work_experience,
        label: "工作年限",
      };
    }

    this.state = {
      isFormValid: false,
      formData: null,
      country: "中国",
      update: false,
    };
  }

  getCountry = event => {
    this.setState({
      country: event.target.value,
    });
    this.getCity(event);
  };

  getCity = event => {
    const { value, name } = event.target;
    this[name] = value;
  };

  setFormState = (isFormValid, formData) => {
    this.setState({
      isFormValid,
      formData,
    });
  };

  next = () => {
    const { isFormValid, formData } = this.state;
    this.setState({ update: false });
    if (isFormValid) {
      this.props.goStep3({ ...formData, city: this.country + this.city });
    } else {
      Object.keys(this.formControls).forEach(item => {
        this.formControls[item].valid = validate(
          this.formControls[item].value || "",
          this.formControls[item].rules || {}
        );
        this.formControls[item].wrong = !this.formControls[item].valid;
      });
      this.setState({ update: true });
      if (
        !Object.keys(this.formControls).some(
          item => !this.formControls[item].valid
        )
      ) {
        this.props.goStep3({
          ...this.getFormData(this.formControls),
          city: this.country + this.city,
        });
      }
    }
  };
  getFormData = formControls => {
    const formData = {};

    Object.keys(formControls).forEach(key => {
      formData[key] = formControls[key].value;
    });

    return formData;
  };

  render() {
    const { country } = this.state;
    const {
      defaultValue: { city },
    } = this.props;

    return (
      <div className="insight-modal__step">
        <Header title="基本信息" desc="请填写基本信息" />

        <div className="insight-modal__group">
          <div className="insight-modal__label">所在地：</div>
          <div className="u-flex insight-modal__city">
            <div className="insight-modal__input t-select t-city">
              <select
                className="insight-modal__select"
                name="country"
                onChange={event => this.getCountry(event)}
              >
                <option value="中国" checked>
                  中国
                </option>
                <option value="海外">海外</option>
              </select>
            </div>
            {country === "中国" ? (
              <div className="insight-modal__input t-select t-city">
                <CitySelect blurHandler={this.getCity} />
              </div>
            ) : (
              <div className="insight-modal__input">
                <input
                  type="text"
                  className="u-reset-input insight-modal__value"
                  name="city"
                  placeholder="请输入城市"
                  defaultValue={city}
                  onBlur={event => this.getCity(event)}
                />
              </div>
            )}
          </div>
        </div>

        <FormContent
          formControls={this.formControls}
          setFormState={this.setFormState}
        />

        <div className="insight-modal__footer">
          <button
            type="button"
            className="u-reset-button u-btn--black insight-modal__btn"
            onClick={this.next}
          >
            下一步
          </button>
        </div>
      </div>
    );
  }
}

Step2.propTypes = {
  goStep3: PropTypes.func.isRequired,
  checkStatus: PropTypes.string.isRequired,
  defaultValue: PropTypes.object.isRequired,
};

export default Step2;
