import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../../shared/ImageLinkContainer";

class UserItem extends React.PureComponent {
  render() {
    const { nickname, bio, id, avatar_url } = this.props;

    return (
      <div className="u-flex search-record__start search-record__inner">
        <ImageLinkContainer
          path={`/users/${id}`}
          url={avatar_url}
          name={nickname}
          className="search-user__avatar"
          type="avatar"
        />
        <div>
          <a
            className="article-item__title"
            href={`/users/${id}`}
            target="_blank"
          >
            {nickname}
          </a>
          <p
            className="u-base-summary search-index__more search-record__summary"
            dangerouslySetInnerHTML={{ __html: bio }}
          />
        </div>
      </div>
    );
  }
}

UserItem.propTypes = {
  nickname: PropTypes.string.isRequired,
  bio: PropTypes.string,
  id: PropTypes.string.isRequired,
  avatar_url: PropTypes.string.isRequired,
};

UserItem.defaultProps = {
  bio: null,
};

export default UserItem;
