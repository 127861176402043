import React from "react";
import PropTypes from "prop-types";

import SpinnerLoading from "./SpinnerLoading";

class FetchMoreBtnWithLoading extends React.PureComponent {
  render() {
    const { isFetchMore, action } = this.props;
    return (
      <div className="u-loadmore">
        {isFetchMore ? (
          <SpinnerLoading />
        ) : (
          <a
            className="u-btn--red u-loadmore__btn"
            href="javascript:;"
            onClick={action}
          >
            加载更多
          </a>
        )}
      </div>
    );
  }
}

FetchMoreBtnWithLoading.propTypes = {
  isFetchMore: PropTypes.bool,
  action: PropTypes.func,
};

FetchMoreBtnWithLoading.defaultProps = {
  isFetchMore: false,
  action: () => {},
};

export default FetchMoreBtnWithLoading;
