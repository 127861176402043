import React from "react";
import PropTypes from "prop-types";

const Loading = ({ className }) => {
  return (
    <div className={`u-loading__container ${className}`}>
      <div className="u-loading__inner">
        <div className="u-loading__logo" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: "",
};

export default Loading;
