import Salert from "react-s-alert";
import { FilterInForm } from "@shared/scripts/utils/tool";

const editArticle = () => {
  const $form = $("#js-column-form");
  const $meta = $("#js-meta");
  let timeout = null;

  $("#js-meta-add").on("click", () => {
    $meta.append(
      '<div class="column-article__meta"><input class="column-setting__input is-meta-key js-meta-key" type="text" placeholder="元信息名称"><input class="column-setting__input is-meta-value js-meta-value" type="text" placeholder="元信息内容"></div>'
    );
  });

  const getMetadata = () => {
    const metadata = {};
    const metaValue = $(".js-meta-value");

    $(".js-meta-key").each((index, item) => {
      if (item.value === "") return;
      metadata[item.value] = metaValue.get(index).value;
    });

    return metadata;
  };

  const submitForm = (cb, isSave = true) => {
    $form.ajaxSubmit({
      url: `${$form.data("action")}${isSave ? "" : "/publish"}`,
      method: "PATCH",
      data: {
        "article[metadata]": getMetadata(),
        "article[title]": $("#js-article-title").text(),
      },
      filtering: FilterInForm,
      success: () => {
        cb();
      },
    });
  };

  $form.on("input", "input, textarea", () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      submitForm(() => {
        $(".js-form-success")
          .css({
            opacity: 1,
          })
          .animate(
            {
              opacity: 0,
            },
            5000
          );
      });
    }, 10000);
  });

  $(".js-form-submit").on("click", () => {
    clearTimeout(timeout);
    submitForm(() => {
      Salert.success("发布成功");
      window.location.href = $form.data("url");
    }, false);
  });

  $(".js-form-save").on("click", () => {
    clearTimeout(timeout);
    submitForm(() => {
      Salert.success("保存成功");
      window.location.href = $form.data("url");
    });
  });
};

export default editArticle;
