import React, { Component } from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

class SwitchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value,
      id: props.id,
      parent_id: props.parent_id,
      model: props.model,
      url: props.url,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({
      checked,
    });

    if (this.state.model === "special_column") {
      $.ajax({
        url: `/admin/special_columns/${this.state.id}`,
        type: "PATCH",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          "special_column[need_review]": this.state.checked,
        },
      });
    } else if (this.state.model === "comment") {
      $.ajax({
        url: `/admin/comments/${this.state.id}`,
        type: "PATCH",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      });
    } else if (this.state.model === "user") {
      $.ajax({
        url: `/admin/users/${this.state.id}`,
        type: "PATCH",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          "user[block]": this.state.checked,
          url: this.state.url,
        },
      });
    } else if (this.state.model === "events_guest") {
      $.ajax({
        url: `/admin/events/${this.state.parent_id}/guests/${this.state.id}`,
        type: "PATCH",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          "event_guest[show]": this.state.checked,
        },
      });
    }
  }

  render() {
    return (
      <Switch
        onChange={this.handleChange}
        checked={this.state.checked}
        onColor="#C7B083"
      />
    );
  }
}

SwitchButton.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  parent_id: PropTypes.string,
  model: PropTypes.string,
  url: PropTypes.string,
};

SwitchButton.defaultProps = {
  value: false,
  id: "",
  parent_id: "",
  model: "",
  url: "",
};

export default SwitchButton;
