import React from "react";
import PropTypes from "prop-types";

import SwitchButton from "../shared/SwitchButton";
import ImageLinkContainer from "../shared/ImageLinkContainer";

class CollectionShowModal extends React.PureComponent {
  state = {
    isActive: true,
  };

  switchActive = isActive => {
    this.setState({
      isActive,
    });
  };

  render() {
    const { isActive } = this.state;
    const { actions } = this.props;

    return (
      <div className="collection-modal">
        <div className="u-flex collection-modal__header">
          精选集名字
          <span className="u-flex collection-modal__active">
            活跃：
            <SwitchButton isActive={isActive} onSwitch={this.switchActive} />
          </span>
        </div>
        <div className="collection-modal__content">
          <div className="u-flex collection-show__item">
            <a
              className="u-image-cover collection-show__cover"
              href="javascript:;"
              alt="文章头图"
            />
            <a
              className="u-text-limit--three collection-show__title"
              href="javascript:;"
              alt="文章标题"
            >
              92分钟和26秒：这家公司的AI在标准商业合同审查PK中，完败20位顶尖律师
            </a>
          </div>
          <div className="collection-show__item">
            <a
              className="u-text-limit--three collection-show__title collection-show__margin"
              href="javascript:;"
              alt="文章标题"
            >
              强化学习
              <span className="search-index__more search-record__en">
                Deep Reinforcement Learning
              </span>
            </a>
            <div className="search-index__more">
              强化学习（英语：Reinforcement
              learning，简称RL）是机器学习中的一个领域，强调如何基于环境而行动，以取得最大化的预期利益。
            </div>
          </div>
          <div className="collection-show__item">
            <a
              className="u-text-limit--three collection-show__title"
              href="javascript:;"
              alt="文章标题"
            >
              Identifying Stable Patterns over Time for Emotion Recogition from
              EEG{" "}
            </a>
          </div>
          <div className="collection-show__item">
            <div className="search-index__more collection-show__margin">
              微软
            </div>
            <a
              className="u-text-limit--three collection-show__title"
              href="javascript:;"
              alt="文章标题"
            >
              Identifying Stable Patterns over Time for Emotion Recogition from
              EEG{" "}
            </a>
          </div>
          <div className="u-flex collection-show__item">
            <ImageLinkContainer
              path="javascript:;"
              url="未知"
              name="未知"
              className="collection-show__avatar"
            />
            <div>
              <a
                className="article-item__title collection-show__title collection-show__margin"
                href="javascript:;"
                alt="文章标题"
              >
                92分钟和26秒：这家公司的AI在标准商业合同审查PK中，完败20位顶尖律师
              </a>
              <div className="search-index__more">
                In hac habitasse platea dictumst.{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          className="collection-modal__footer collection-show__link"
          onClick={actions.closeModal}
        >
          <a className="search-index__more" href="javascript:;">
            查看精选集详情
          </a>
        </div>
      </div>
    );
  }
}

CollectionShowModal.propTypes = {
  actions: PropTypes.object.isRequired,
};

CollectionShowModal.defaultProps = {};

export default CollectionShowModal;
