import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class HandbookSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  onChange(value) {
    this.setState({
      value,
    });
  }

  getData = (keyword, url) => {
    if (!keyword) {
      return Promise.resolve({ options: [] });
    }
    const fetch_url = `/admin/${url}?q[name_or_pinyin_or_pinyin_abbr_cont]=${keyword}`;
    return fetch(fetch_url, {
      method: "GET",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => {
        return { options: json[url] };
      });
  };

  render() {
    const placeholder = "支持中文/拼音搜索";
    const { name, url, multi } = this.props;
    const { value } = this.state;
    return (
      <Select.Async
        multi={multi}
        value={value}
        placeholder={placeholder}
        onChange={val => {
          this.onChange(val);
        }}
        valueKey="id"
        labelKey="name"
        filterOptions={false}
        loadOptions={keyword => {
          return this.getData(keyword, url);
        }}
        name={name}
      />
    );
  }
}

HandbookSelector.propTypes = {
  multi: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.object,
};

HandbookSelector.defaultProps = {
  multi: true,
  name: "",
  url: "",
  value: {},
};

export default HandbookSelector;
