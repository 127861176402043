import React from "react";
// import PropTypes from 'prop-types';
import ImageLinkContainer from "./ImageLinkContainer";

const SystemNotice = () => {
  // const { moment: { comment, created_at }, user } = props;
  const created_at = "2017-11-03";
  const user = {
    avatar_url: "",
    path: "javascript:;",
    name: "大圣",
  };

  return (
    <div className="user-show__item">
      <ImageLinkContainer
        path={user.path}
        url={user.avatar_url}
        name={user.name}
        className="user-show__avatar"
        type="avatar"
      />
      <div className="user-show__body">
        <div className="user-show__title">
          <span className="user-show__title--text is-from">{user.name}</span>
        </div>
        <p className="user-show__title user-show__system">
          您申请的首席体验官计划已经通过
        </p>
        <time className="user-show__date">{$.timeago(created_at)}</time>

        <div className="user-show__content">关注了专栏</div>
      </div>
    </div>
  );
};

// SystemNotice.propTypes = {
//   moment: PropTypes.object,
//   user: PropTypes.object.isRequired
// };

// SystemNotice.defaultProps = {
//   moment: null
// };

export default SystemNotice;
