import React from "react";
import { render } from "react-dom";

import Search from "@components/shared/header/Search";
import store from "@shared/scripts/utils/store";
import { openModal } from "@shared/scripts/actions/modal";

const mountSearch = () => {
  const searchTarget = document.getElementById("js-site-search");
  const logined = document.getElementsByClassName("header-notice")[0];

  if (logined) {
    searchTarget.style.right = "130px";
  } else {
    searchTarget.style.right = "90px";
  }

  if (searchTarget === null) return;

  render(
    <Search
      logined={logined}
      keywords={searchTarget.getAttribute("data-keywords")}
    />,
    searchTarget
  );
};

const openDialog = ele => {
  ele.addClass("show");
  $(".code-modal__mask").addClass("show");
};
const closeDialog = () => {
  $(".code-modal__auto-byte").removeClass("show");
  $(".code-modal__science-ai").removeClass("show");
  $(".code-modal__mask").removeClass("show");
};

export default () => {
  const $login = $("#js-header-login");

  // 根据按钮是否存在判断是否未登录
  if ($login.length > 0) {
    $(".js-switch-sign").on("click", () => {
      store.dispatch(openModal("signin"));
    });
    window.popupLogin && store.dispatch(openModal("signin"));
    // store.dispatch(openSotaModal("sota_signin"));
  } else {
    $("#js-my-column").on("click", () => {
      store.dispatch(openModal("myColumn"));
    });

    setTimeout(() => {
      if (window.currentUser && !window.currentUser.mobile_verified) {
        store.dispatch(openModal("signin"));
      }
    }, 1000);
  }

  $(".auto-byte").on("click", () => {
    openDialog($(".code-modal__auto-byte"));
    document.body.style.overflow = "hidden";
  });

  $(".science-ai").on("click", () => {
    openDialog($(".code-modal__science-ai"));
    document.body.style.overflow = "hidden";
  });

  $(".code-modal__mask").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });

  $(".code-modal__close").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });

  mountSearch();
};
