import React from "react";
import PropTypes from "prop-types";

class ArticleItemInMobile extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const {
      path,
      title,
      cover_image_url,
      published_at,
      insight_name,
    } = this.props;

    return (
      <a className="insight-article__item" href={path}>
        <div className="u-image-base insight-article__image">
          <img alt={title} className="u-image-center" src={cover_image_url} />
        </div>
        <div className="insight-article__right">
          <div className="insight-item__title">{title}</div>
          <div className="u-flex insight-article__footer">
            <span className="insight-article__category">{insight_name}</span>
            <time
              className="insight-item__time js-time-ago"
              dateTime={published_at}
            >
              {$.timeago(published_at)}
            </time>
          </div>
        </div>
      </a>
    );
  }
}

ArticleItemInMobile.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
  published_at: PropTypes.string.isRequired,
};

ArticleItemInMobile.defaultProps = {
  cover_image_url: "",
  insight_name: "",
};

export default ArticleItemInMobile;
