import React from "react";
import PropTypes from "prop-types";

import {
  mobile_or_email_rule,
  rucaptcha_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";

class ForgetPwd extends React.Component {
  updateRucaptcha = e => {
    const $rucaptcha = $(e.target);
    $rucaptcha.attr("src", $rucaptcha.attr("src"));
  };

  submit() {
    if (!this.props.isFormValidate()) return;

    const { login_name, code } = this.props.data;
    this.props.getVerificationCode(login_name.value, code.value, () => {
      $(this.refs.rucaptcha).click();
    });
  }

  render() {
    const {
      data: { login_name, code },
      changeValue,
    } = this.props;

    return (
      <form ref="form" action="/send_verification_code" className="signin-form">
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...login_name}
          onChange={value => {
            changeValue(value, "login_name");
          }}
        />
        <div className="u-pic-code">
          <CustomizeInput
            {...code}
            onChange={value => {
              changeValue(value, "code");
            }}
          />
          <img
            ref="rucaptcha"
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => this.updateRucaptcha(e)}
          />
        </div>
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={() => {
            this.submit();
          }}
        >
          下一步
        </button>
      </form>
    );
  }
}

ForgetPwd.propTypes = {
  getVerificationCode: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  login_name: buildRules("login_name", "手机号/邮箱", [mobile_or_email_rule]),
  code: buildRules("_rucaptcha", "图形验证码", [rucaptcha_rule]),
})(ForgetPwd);
