import React from "react";
import CategoryList from "./CategoryList";
import LayoutWrapper from "./LayoutWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import SearchFrom from "./SearchFrom";

const ProduceCategory = (params, hasRightFilter = false) => {
  const List = LoadingWrapper(CategoryList);

  let SearchFromComponent = null;
  if (params === "articles") {
    SearchFromComponent = SearchFrom;
  }

  return (
    <LayoutWrapper params={params} hasRightFilter={hasRightFilter}>
      <List params={params} OtherComponent={SearchFromComponent} />
    </LayoutWrapper>
  );
};

export default ProduceCategory;
