import React from "react";
import PropTypes from "prop-types";
import validate from "@shared/scripts/utils/inputValidate";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";

class FormContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: props.formControls,
    };
  }

  componentDidMount() {
    this.getValid();
  }

  changeHandler = event => {
    const { name, value } = event.target;
    const updateControls = this.state.formControls;
    const currentEle = updateControls[name];

    currentEle.value = value;

    if (currentEle.rules && Object.keys(currentEle.rules).length > 0) {
      currentEle.valid = validate(value, currentEle.rules);
      currentEle.wrong = !currentEle.valid;
    }

    this.setState(
      {
        formControls: updateControls,
      },
      () => {
        this.getValid();
      }
    );
  };

  getValid = () => {
    const { isFormValid, formData } = this.isValid();
    this.props.setFormState(isFormValid, formData);
  };

  isValid = () => {
    const { formControls } = this.state;
    let isFormValid = true;

    isFormValid = !Object.keys(formControls).some(
      item => !formControls[item].valid
    );

    return {
      isFormValid,
      formData: isFormValid && this.getFormData(),
    };
  };

  getFormData = () => {
    const formData = {};
    const { formControls } = this.state;

    Object.keys(formControls).forEach(key => {
      formData[key] = formControls[key].value;
    });

    return formData;
  };

  render() {
    const { formControls } = this.state;
    return Object.keys(formControls).map(key => {
      const element = formControls[key];

      if (element.isSelect) {
        return (
          <FormSelect
            key={key}
            value={element.value}
            label={element.label}
            options={element.options}
            name={key}
            valid={element.valid}
            wrong={element.wrong}
            required={element.rules ? true : false}
            changeHandler={this.changeHandler}
          />
        );
      }

      return (
        <FormInput
          key={key}
          label={element.label}
          name={key}
          value={element.value}
          valid={element.valid}
          wrong={element.wrong}
          required={element.rules ? true : false}
          changeHandler={this.changeHandler}
        />
      );
    });
  }
}

FormContent.propTypes = {
  formControls: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
};

export default FormContent;
