import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../ImageLinkContainer";

const ArticleAuthor = props => {
  const { path, name, avatar_url } = props.author;
  return (
    <ImageLinkContainer
      path={path}
      url={avatar_url}
      name={name}
      className="article-item__avatar"
      type="avatar"
    />
  );
};

ArticleAuthor.propTypes = {
  author: PropTypes.object.isRequired,
};

export default ArticleAuthor;
