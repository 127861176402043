import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import CategoryAllFirstPage from "./CategoryAllFirstPage";
import CategoryList from "./CategoryList";
import LoadingWrapper from "../utils/LoadingWrapper";
import NoData from "../utils/NoData";
import SearchFrom from "./SearchFrom";

@LoadingWrapper
class CategoryListInAll extends Component {
  render() {
    const {
      searchInternet,
      edges,
      firstPageData,
      keywords,
      ...otherProps
    } = this.props;
    const hasData =
      firstPageData.articles.nodes.length === 0 &&
      firstPageData.solutions.nodes.length === 0 &&
      firstPageData.users.nodes.length === 0;

    return (
      <Fragment>
        {hasData ? (
          <NoData />
        ) : (
          <CategoryAllFirstPage
            searchInternet={searchInternet}
            data={firstPageData}
            keywords={keywords}
          />
        )}
        {edges.length > 0 && (
          <CategoryList
            params="articles"
            edges={edges}
            keywords={keywords}
            searchInternet={searchInternet}
            OtherComponent={SearchFrom}
            {...otherProps}
          />
        )}
      </Fragment>
    );
  }
}

CategoryListInAll.propTypes = {
  searchInternet: PropTypes.bool.isRequired,
  firstPageData: PropTypes.object.isRequired,
  keywords: PropTypes.string.isRequired,
  edges: PropTypes.array,
};

CategoryListInAll.defaultProps = {
  edges: [],
};

export default CategoryListInAll;
