import React from "react";
import PropTypes from "prop-types";

class FormSelect extends React.Component {
  state = {
    isFocus: false,
  };

  selectFocus = () => {
    this.setState({
      isFocus: true,
    });
  };

  selectChange = event => {
    const { value } = event.target;

    this.setState({ isFocus: value == "" });
    this.props.changeHandler(event);
  };

  render() {
    const { label, options, name, value, wrong, required } = this.props;
    const { isFocus } = this.state;

    return (
      <div className="insight-modal__group">
        <div className={`insight-modal__label ${required ? "required" : ""}`}>
          {label}：
        </div>
        <div
          className={`insight-modal__input t-select ${
            isFocus ? "t-focus" : ""
          } ${!wrong ? "" : "is-error"}`}
        >
          <select
            className="insight-modal__select"
            name={name}
            onMouseDown={this.selectFocus}
            onChange={event => this.selectChange(event)}
            defaultValue={value}
          >
            <option value="">请选择{label}</option>
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.key}
              </option>
            ))}
          </select>
          <span className="insight-modal__error">请选择正确的{label}</span>
        </div>
      </div>
    );
  }
}

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

FormSelect.defaultProps = {
  value: "",
};

export default FormSelect;
