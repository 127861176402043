import React from "react";
import PropTypes from "prop-types";
import { readNotification } from "@shared/scripts/features/api";
import ImageLinkContainer from "./ImageLinkContainer";

class CommentHasReply extends React.Component {
  state = {
    isRead: this.props.isRead,
  };

  shouldComponentUpdate(_, nextState) {
    if (this.state.isRead === nextState.isRead) {
      return false;
    }
    return true;
  }

  read = () => {
    if (this.state.isRead) return;

    readNotification(this.props.noticeId.replace(/^Notification\//, ""));
    this.setState({
      isRead: true,
    });
  };

  render() {
    const { comment, created_at, user, isNotice } = this.props;
    const { isRead } = this.state;
    if (comment === null || user === null) return null;

    return (
      <div className={`setting__comment ${isRead ? "" : "has-read"}`}>
        <div className="user-show__item">
          <ImageLinkContainer
            path={user.path}
            url={user.avatar_url}
            name={user.name}
            className="user-show__avatar"
            type="avatar"
          />
          <div className="user-show__body">
            <div className="user-show__title">
              <a
                className="user-show__title--link"
                href={user.path}
                alt={user.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {user.name}
              </a>
              {isNotice ? "回复了你在" : "评论了文章"}
              {comment.title === null || comment.path === null ? (
                <span className="user-show__title--text">
                  《&nbsp;该文章已删除&nbsp;》
                </span>
              ) : (
                <a
                  className="user-show__title--link"
                  href={comment.path}
                  alt="文章"
                  onClick={this.read}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  《&nbsp;
                  {comment.title}
                  &nbsp;》
                </a>
              )}
            </div>
            {isNotice && <div className="user-show__title">的评论</div>}
            <div className="user-show__content">
              {comment.is_deleted ? (
                <div className="user-show__nil">该评论已删除</div>
              ) : (
                comment.content
              )}
            </div>
            {!comment.is_deleted && (
              <div className="user-show__footer">
                <a
                  className="user-show__reply"
                  alt="回复"
                  href={`${comment.path}#comment`}
                  onClick={this.read}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  回复
                </a>
              </div>
            )}
          </div>
          <time className="user-show__date">{$.timeago(created_at)}</time>
        </div>
      </div>
    );
  }
}

CommentHasReply.propTypes = {
  comment: PropTypes.object,
  user: PropTypes.object,
  created_at: PropTypes.string.isRequired,
  isNotice: PropTypes.bool,
  noticeId: PropTypes.string,
  isRead: PropTypes.bool,
};

CommentHasReply.defaultProps = {
  isNotice: false,
  noticeId: null,
  comment: null,
  user: null,
  isRead: true,
};

export default CommentHasReply;
