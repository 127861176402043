import React from "react";
import PropTypes from "prop-types";

class NoticeSystematic extends React.Component {
  state = {
    isRead: false,
  };

  onClick = () => {
    this.props.onRead().done(() => {
      this.setState({ isRead: true });
    });
  };

  render() {
    const { content } = this.props.data;
    return (
      <a
        className={`notice__item ${this.state.isRead ? "is-read" : ""}`}
        href="javascript:;"
        alt="通知"
        onClick={this.onClick}
      >
        <p className="u-text-limit--one notice__line">{content}</p>
      </a>
    );
  }
}

NoticeSystematic.propTypes = {
  data: PropTypes.object.isRequired,
  onRead: PropTypes.func.isRequired,
};

export default NoticeSystematic;
