import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import COLUMNS_QUERY from "@graphql/columns.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";
import ColumnItem from "./ColumnItem";

@SkipWrapper
@graphql(COLUMNS_QUERY, {
  options: {
    variables: {
      cursor: "MTI =",
      count: 12,
    },
  },
})
@WaterFallWrapper(["columns"], null, true)
class ColumnIndex extends React.Component {
  render() {
    return (
      <div className="column-item__container">
        {this.props.edges.map(edge => (
          <ColumnItem {...edge.node} key={edge.node.id} />
        ))}
      </div>
    );
  }
}

ColumnIndex.propTypes = {
  edges: PropTypes.array,
};

ColumnIndex.defaultProps = {
  edges: [],
};

export default ApolloWrapper(ColumnIndex);
