const PARTIAL_SCHEMA = {
  __schema: {
    types: [
      {
        kind: "SCALAR",
        name: "Boolean",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "String",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Type",
        possibleTypes: null,
      },
      {
        kind: "ENUM",
        name: "__TypeKind",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Field",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__InputValue",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__EnumValue",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Query",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "User",
        possibleTypes: null,
      },
      {
        kind: "INTERFACE",
        name: "Node",
        possibleTypes: [
          {
            name: "Article",
          },
          {
            name: "Comment",
          },
          {
            name: "Corporation",
          },
          {
            name: "Daily",
          },
          {
            name: "Industry",
          },
          {
            name: "Notification",
          },
          {
            name: "Report",
          },
          {
            name: "SpecialColumn",
          },
          {
            name: "SubIndustry",
          },
          {
            name: "Technical",
          },
          {
            name: "Timeline",
          },
          {
            name: "Topic",
          },
          {
            name: "Usage",
          },
          {
            name: "User",
          },
        ],
      },
      {
        kind: "SCALAR",
        name: "ID",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ArticleConnectionWithTotalCount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ArticleEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Article",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Int",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PageInfo",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CommentConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CommentEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Comment",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SpecialColumnConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SpecialColumnEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SpecialColumn",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "NotificationConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "NotificationEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Notification",
        possibleTypes: null,
      },
      {
        kind: "UNION",
        name: "target",
        possibleTypes: [
          {
            name: "Comment",
          },
        ],
      },
      {
        kind: "OBJECT",
        name: "MomentConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "MomentEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Moment",
        possibleTypes: null,
      },
      {
        kind: "UNION",
        name: "content",
        possibleTypes: [
          {
            name: "Article",
          },
          {
            name: "Comment",
          },
        ],
      },
      {
        kind: "OBJECT",
        name: "Topic",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Report",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SubIndustry",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Industry",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SubIndustryConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SubIndustryEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "IndustryCorporationsConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "IndustryCorporationsEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "IndustryCorporations",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UsageConnectionWithTotalCount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UsageEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Usage",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Corporation",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TechnicalConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TechnicalEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Technical",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "HandbookSearchResult",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CorporationConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CorporationEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "industryConnectionWithTotalCount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "IndustryEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TechnicalConnectionWithTotalCount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ArticleConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TimelineConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TimelineEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Timeline",
        possibleTypes: null,
      },
      {
        kind: "UNION",
        name: "timeline_content",
        possibleTypes: [
          {
            name: "Article",
          },
          {
            name: "Topic",
          },
          {
            name: "Report",
          },
        ],
      },
      {
        kind: "OBJECT",
        name: "DailyConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "DailyEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Daily",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TopicConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TopicEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SearchResultConnection",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SearchResultEdge",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SearchResult",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Schema",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Directive",
        possibleTypes: null,
      },
      {
        kind: "ENUM",
        name: "__DirectiveLocation",
        possibleTypes: null,
      },
    ],
  },
};

export default PARTIAL_SCHEMA;
