/* eslint-disable no-new */
import Swiper from "swiper";

export default () => {
  new Swiper("#js-node-slider", {
    loop: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
};
