import banner from "@shared/scripts/features/banner";

const home = () => {
  banner();

  const tabs = document.querySelectorAll(".home-right__tab");
  tabs.forEach(tab => {
    tab.addEventListener("click", function() {
      // tab bar
      tabs.forEach(t => {
        t.classList.remove("home-right__tab--active");
        t.querySelector(".tab-icon").style.display = "block";
        t.querySelector(".tab-icon--active").style.display = "none";
      });
      tab.querySelector(".tab-icon").style.display = "none";
      tab.querySelector(".tab-icon--active").style.display = "block";
      this.classList.add("home-right__tab--active");

      // tab content
      if ([...this.classList].includes("home-right__tab-1")) {
        document.querySelector(".home-right__list-1").style.display = "block";
        document.querySelector(".home-right__list-2").style.display = "none";
      } else {
        document.querySelector(".home-right__list-1").style.display = "none";
        document.querySelector(".home-right__list-2").style.display = "block";
      }
    });

    // hover
    tab.addEventListener("mouseenter", function() {
      tab.querySelector(".tab-icon").style.display = "none";
      tab.querySelector(".tab-icon--active").style.display = "block";
      this.classList.add("home-right__tab--hover");
    });

    // un-hover
    tab.addEventListener("mouseleave", function() {
      const active = [...this.classList].includes("home-right__tab--active");
      this.classList.remove("home-right__tab--hover");
      if (!active) {
        tab.querySelector(".tab-icon").style.display = "block";
        tab.querySelector(".tab-icon--active").style.display = "none";
      }
    });
  });

  const openModal = () => {
    document.querySelector(".home-right__modal-root").style.display = "block";
    document.querySelector("#home-index").style.overflowY = "hidden";
  };
  const closeModal = () => {
    document.querySelector(".home-right__modal-root").style.display = "none";
    document.querySelector("#home-index").style.overflowY = "unset";
  };
  const body = document.querySelector(".home-right__body");
  const footer = document.querySelector(".home-right__footer");
  body.addEventListener("click", openModal);
  footer.addEventListener("click", openModal);
  const layer = document.querySelector(".home-right__modal-layer");
  const closeIcon = document.querySelector(".home-right__modal-content .close");
  layer.addEventListener("click", closeModal);
  closeIcon.addEventListener("click", closeModal);

  const getRecommItems = () => {
    const $loading = $("#js-filter-loading");
    $loading.removeClass("is-hidden");
  };
  getRecommItems();

  $("#js-recommend-replace").on("click", () => {
    getRecommItems();
  });

  document.getElementById("js-daily-container") &&
    document
      .getElementById("js-daily-container")
      .addEventListener("wheel", e => {
        const panel = e.currentTarget;
        if (panel.scrollHeight <= panel.clientHeight) return;

        const mt = panel.scrollTop;
        const { deltaY } = e;
        const isEnd = panel.scrollHeight - mt === panel.clientHeight;
        if (deltaY > 0 && isEnd) e.preventDefault();
      });
};

export default home;
