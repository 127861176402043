import React from "react";
import PropTypes from "prop-types";

const FilterTags = props => {
  const { tags, changeFilter, checkedTags } = props;

  return (
    <div className="category__list">
      {tags.length === 0 && <p className="is-disabled">无相关标签</p>}
      {tags.map((tag, index) => (
        <button
          key={`tag-${index}`}
          type="button"
          className={`u-reset-button u-btn--gray category__link ${
            checkedTags.indexOf(tag) > -1 ? "is-active" : ""
          }`}
          alt={tag}
          onClick={() => {
            changeFilter(tag);
          }}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

FilterTags.propTypes = {
  tags: PropTypes.array,
  checkedTags: PropTypes.array,
  changeFilter: PropTypes.func.isRequired,
};

FilterTags.defaultProps = {
  tags: [],
  checkedTags: [],
};

export default FilterTags;
