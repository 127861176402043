import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import MOMENTS_QUERY from "@graphql/userMoment.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import CommentNoReply from "../shared/CommentNoReply";
import UserMomentLoading from "../loading/UserMomentLoading";
import SkipWrapper from "../utils/SkipWrapper";
import MomentArticle from "./MomentArticle";
import MMomentArticle from "./MMomentArticle";
import WaterFallWrapper from "../utils/WaterFallWrapper";

@SkipWrapper
@graphql(MOMENTS_QUERY, {
  options: props => ({
    variables: {
      count: 10,
      cursor: "",
      id: props.user.id,
    },
  }),
})
@WaterFallWrapper(["user", "moments"], UserMomentLoading)
class UserMoment extends React.Component {
  render() {
    const { edges, user, isMobile } = this.props;
    return edges.map(edge => {
      const { node } = edge;

      if (node.content_type === "Comment") {
        return (
          <CommentNoReply
            key={node.id}
            comment={node.content}
            created_at={node.created_at}
            user={user}
          />
        );
      }
      if (node.content_type === "Article") {
        if (isMobile)
          return (
            <MMomentArticle
              key={node.id}
              createdAt={node.created_at}
              article={node.content}
              user={user}
            />
          );
        return (
          <MomentArticle
            key={node.id}
            createdAt={node.created_at}
            article={node.content}
            user={user}
          />
        );
      }

      return null;
    });
  }
}

UserMoment.propTypes = {
  edges: PropTypes.array,
  user: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

UserMoment.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(UserMoment);
