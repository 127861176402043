import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import "jquery.qrcode";

import {
  getUrlQuery,
  shareAction,
  scrollBarWith,
} from "@shared/scripts/utils/tool";
import DAILIES_QUERY from "@graphql/dailies.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import HasDataWrapper from "../utils/HasDataWrapper";
import DailyWrapper from "./DailyWrapper";
import DailyList from "./DailyList";
import DailyTime from "./DailyTime";

@graphql(DAILIES_QUERY, {
  options: {
    variables: {
      cursor: "",
    },
  },
})
@LoadingWrapper
@DailyWrapper
@HasDataWrapper(true, 2)
class Dailies extends React.Component {
  state = {
    activeIndex: -1,
  };

  componentDidMount() {
    const { location, history } = window;
    const { id } = getUrlQuery(location.search);

    if (id) {
      const url = `/dailies/${id}`;
      history.replaceState(null, "", "?");
      history.pushState("", "", url);
      $("body")
        .css("padding-right", `${scrollBarWith()}px`)
        .addClass("open-modal-layer");
      $("#js-modal").removeClass("is-hidden");
      shareAction(location.href);
      $(".js-qrcode").qrcode({ text: url, width: 120, height: 120 });
    }

    this.bindForDom();
  }

  setActiveIndex = index => {
    this.setState({
      activeIndex: index,
    });
  };

  bindForDom = () => {
    const $window = $(window);
    const $container = $(this.container);

    $window.on("scroll", () => {
      const startTop = $container.offset().top;
      const $everyday = $container.find(".js-daily-every");
      const scrollTop = $window.scrollTop();

      if (scrollTop < startTop) {
        this.setActiveIndex(-1);
        return;
      }

      let activeIndex = -1;
      $everyday.each((index, item) => {
        // 64: header height + hr height
        const top = scrollTop + 64;
        if (top >= item.offsetTop) {
          activeIndex = index;
        }
      });
      this.setActiveIndex(activeIndex);
    });
  };

  render() {
    const { dailies } = this.props;

    return (
      <div
        ref={ele => {
          this.container = ele;
        }}
      >
        {Object.keys(dailies).map((key, index) => (
          <div className="daily-every js-daily-every" key={key}>
            <DailyTime
              time={key}
              index={index}
              activeIndex={this.state.activeIndex}
            />
            <DailyList dailyList={dailies[key]} />
          </div>
        ))}
      </div>
    );
  }
}

Dailies.propTypes = {
  dailies: PropTypes.array,
};

Dailies.defaultProps = {
  dailies: [],
};

export default ApolloWrapper(Dailies);
