import React from "react";
import PropTypes from "prop-types";

class PeriodicalItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { path, title, cover_image_url } = this.props;

    return (
      <div
        className="topic-inline"
        style={{
          backgroundImage: `linear-gradient(rgba(40, 40, 40, 0.5), rgba(40, 40, 40, 0.5)), url(${cover_image_url})`,
        }}
      >
        <a
          className="topic-inline__cover"
          href={path}
          alt={title}
          rel="noopener noreferrer"
          target="_blank"
        />
        <div className="topic-inline__info">
          <a
            className="u-text-limit--two topic-inline__title"
            href={path}
            alt={title}
            rel="noopener noreferrer"
            target="_blank"
          >
            {title}
          </a>
        </div>
      </div>
    );
  }
}

PeriodicalItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  cover_image_url: PropTypes.string.isRequired,
};

PeriodicalItem.defaultProps = {
  title: "",
};

export default PeriodicalItem;
