import React from "react";
import PropTypes from "prop-types";

const CollectionHeader = ({ title, onCloseClick }) => {
  return (
    <div className="u-flex collection-modal__header">
      {title}
      <a
        className="u-modal-close"
        href="javascript:;"
        alt="关闭modal"
        onClick={onCloseClick}
      >
        <i className="iconfont icon-iconguanbi" />
      </a>
    </div>
  );
};

CollectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default CollectionHeader;
