/* eslint-disable function-paren-newline */

import React from "react";
import PropTypes from "prop-types";

// import EventItem from './items/EventItem';
import ArticleItem from "./items/ArticleItem";
// import PaperItem from './items/PaperItem';
import SolutionInAll from "./items/SolutionInAll";
// import { usage } from './routes';

class CategoryAllFirstPage extends React.Component {
  render() {
    const {
      data: { articles, solutions },
      searchInternet,
      keywords,
    } = this.props;

    const rows = [];
    articles.nodes.slice(0, 2).map((article, index) => {
      return rows.push(
        <div key={article.id} className="search-record__item">
          <ArticleItem
            index={index}
            {...article}
            keywords={keywords}
            searchInternet={searchInternet}
          />
        </div>
      );
    });

    if (solutions.nodes.length > 0) {
      rows.push(
        <div key="SolutionInAll" className="search-record__item no-hover">
          <SolutionInAll
            solutions={solutions.nodes}
            keywords={keywords}
            hasMore={solutions.has_more}
          />
        </div>
      );
    }

    return rows;
    // <div key={3} className="search-record__item">
    //   <PaperItem />
    // </div>,
    // <div key={5} className="search-record__item">
    //   <SolutionInAll />
    // </div>,
    // ];
  }
}

CategoryAllFirstPage.propTypes = {
  data: PropTypes.object.isRequired,
  searchInternet: PropTypes.bool,
  keywords: PropTypes.string,
};

CategoryAllFirstPage.defaultProps = {
  searchInternet: false,
  keywords: "",
};

export default CategoryAllFirstPage;
