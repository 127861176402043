import React from "react";
import PropTypes from "prop-types";

const GraphAboutTechnologies = ({ technologies }) => {
  if (technologies.length === 0) return null;

  return (
    <div className="search-card__about">
      <div className="search-card__sub">相关技术</div>
      <div className="graph__fields">
        {technologies.map(({ id, name, pro_url }) => {
          return (
            <a
              key={id}
              className="graph__field"
              href={`${pro_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

GraphAboutTechnologies.propTypes = {
  technologies: PropTypes.array,
};

GraphAboutTechnologies.defaultProps = {
  technologies: [],
};

export default GraphAboutTechnologies;
