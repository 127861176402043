import React from "react";
import PropTypes from "prop-types";

import MobileForm from "./MobileForm";

const MobileModal = props => {
  const {
    user,
    phoneVerifyCode,
    actions: { closeModal },
    fetchDate,
  } = props;
  return (
    <div className="user-set__modal">
      <a
        className="signin-modal__close js-switch-sign"
        href="javascript:;"
        onClick={closeModal}
      >
        <i className="iconfont icon-iconguanbi" />
      </a>
      <div className="forget__container">
        <MobileForm
          closeModal={closeModal}
          user={user}
          fetchDate={fetchDate}
          phoneVerifyCode={(mobile, cb) => phoneVerifyCode(mobile, cb)}
        />
      </div>
    </div>
  );
};

MobileModal.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  phoneVerifyCode: PropTypes.func.isRequired,
  fetchDate: PropTypes.func.isRequired,
};

export default MobileModal;
