import React from "react";
import Salert from "react-s-alert";
import PropTypes from "prop-types";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";

class SalertTemplate extends React.PureComponent {
  handleConfirm() {
    Salert.close(this.props.id);
  }

  render() {
    const { message, classNames, id, styles } = this.props;
    let icon = "icon-correct-c";

    if (classNames.indexOf("error") > -1) {
      icon = "icon-close-fill";
    }

    return (
      <div className={`flash__item  ${classNames}`} id={id} style={styles}>
        <div className="flash__left">
          <i className={`iconfont ${icon}`} />
        </div>
        <div className="flash__right">
          <h4>{message}</h4>
        </div>
        <a
          href="javascript:;"
          className="flash__closed-btn"
          onClick={() => this.handleConfirm()}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>
    );
  }
}

SalertTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  classNames: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
};

const Alert = () => {
  return (
    <Salert
      contentTemplate={SalertTemplate}
      stack={{ limit: 3 }}
      html
      timeout={3000}
      offset={34}
      position="top-right"
      effect="stackslide"
    />
  );
};

export default Alert;
