import React from "react";
import PropTypes from "prop-types";

import cover_default from "image_default/article_cover_default.svg";
import LikeAndCommentAction from "./LikeAndCommentAction";
import ImageLinkContainer from "./ImageLinkContainer";

class ArticleSimpleItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { cover_image_url, path, title, author } = this.props;

    return (
      <article className="u-shadow-effect article-simple__item">
        <ImageLinkContainer
          path={path}
          url={`${cover_image_url}?imageView2/1/w/282/h/188`}
          name={title}
          className="article-simple__cover"
        />
        <main className="article-simple__content u-flex">
          <a
            alt={title}
            href={path}
            className="u-main-title u-text-limit--two article-simple__title"
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
          <footer className="article-simple__footer u-flex">
            <div className="u-flex">
              <ImageLinkContainer
                path={author.path}
                url={author.avatar_url}
                name={author.name}
                className="article-simple__avatar"
                type="avatar"
              />
              <a
                className="article-simple__user"
                href={author.path}
                alt={author.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {author.name}
              </a>
            </div>
            <LikeAndCommentAction {...this.props} />
          </footer>
        </main>
      </article>
    );
  }
}

ArticleSimpleItem.propTypes = {
  cover_image_url: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
};

ArticleSimpleItem.defaultProps = {
  cover_image_url: cover_default,
};

export default ArticleSimpleItem;
