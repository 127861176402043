import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const DailyTime = ({ time, index, activeIndex }) => {
  return (
    <div
      className={`daily-every__time js-daily-time ${
        index === activeIndex ? "is-active" : ""
      }`}
    >
      <span className="daily-every__month">{dayjs(time).format("M")}月</span>
      <span className="daily-every__day">{dayjs(time).format("D")}</span>
    </div>
  );
};

DailyTime.propTypes = {
  time: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default DailyTime;
