import React from "react";
import PropTypes from "prop-types";

class DailyFooter extends React.Component {
  constructor(props) {
    super(props);
    this.urlReg = new RegExp("^(http)?s?://([^/?#]+)(?:[/?#]|$)", "i");
  }

  render() {
    const { url, path, likesCount } = this.props;
    const urlSet = url && url.match(this.urlReg);

    return (
      <div className="u-flex daily-inline__footer">
        <a
          className="daily-inline__link"
          alt={url}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {urlSet === null ? url : urlSet[urlSet.length - 1]}
        </a>
        <a
          className="u-like-icon js-like-action"
          data-path={path}
          href="javascript:;"
        >
          <i className="iconfont icon-like-default" />
          <span className="daily-inline__count">
            {likesCount === 0 ? "" : likesCount}
          </span>
        </a>
      </div>
    );
  }
}

DailyFooter.propTypes = {
  url: PropTypes.string,
  likesCount: PropTypes.number,
  path: PropTypes.string.isRequired,
};

DailyFooter.defaultProps = {
  url: null,
  likesCount: 0,
};

export default DailyFooter;
