import { pageScrollToTarget, setActiveNav } from "@shared/scripts/utils/tool";

const industry = () => {
  const $insutryNav = $("#industry-header__nav");
  const navOffsetTop = $("#js-industry-header").height();
  const $navItem = $(".js-nav-bar");
  const $contents = $(".js-content-type");
  const $window = $(window);
  const offsetTop = 108; // header margin top + nav height
  let activeIndex = 0;

  $navItem.on("click", function() {
    const $this = $(this);
    pageScrollToTarget($($contents[$this.index()]).offset().top - offsetTop);
  });

  $window.on("scroll", () => {
    const scrollTop = $window.scrollTop();
    // set header fix
    $insutryNav.toggleClass("is-fixed", scrollTop > navOffsetTop);
    activeIndex = setActiveNav(
      $contents,
      $navItem,
      scrollTop,
      offsetTop,
      activeIndex
    );
  });

  $window.scroll();
};

export default industry;
