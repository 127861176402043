/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import PropTypes from "prop-types";

import { getListInIndustry } from "@shared/scripts/features/api";
import {
  sidebarWithScroll,
  pageScrollToTarget,
  removeIndexFromArr,
} from "@shared/scripts/utils/tool";
import SortHeader from "./filter/SortHeader";
import CheckedListHeader from "./filter/CheckedListHeader";
import Paginate from "./filter/Paginate";
import FilterPannel from "./filter/FilterPannel";
import AddKeywords from "./filter/AddKeywords";
import Loading from "../utils/LogoLoading";
import NoData from "../utils/NoData";

const translateType = {
  solutions: "方案",
  institutions: "机构",
  default: "数据",
};

const ProduceFilter = (categories, Item, type = "default") => {
  class StatusInnerWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.checkedList = {};
      this.forcePage = undefined;

      this.state = {
        ...props.filter_condition,
        totalCount: props.count,
        firstFetch: false,
        keyword: "",
        sort: "",
        resultList: props.default_list,
      };
    }

    componentDidMount() {
      sidebarWithScroll({
        sidebarSelector: "#js-left-wrapper",
        contentSelector: "#js-right-wrapper",
        isContentHeightChange: true,
        defaultClass: "is-fixed",
      });
    }

    getCheckedList = (categoryId, targetList) => {
      const checked = this.checkedList;
      checked[categoryId] = targetList.filter(item => item.checked);
      this.checkedList = checked;
    };

    getCheckedParams() {
      const params = {};
      Object.keys(this.checkedList).forEach(key => {
        const value = this.checkedList[key];

        switch (key) {
          case "rounds":
            params.rounds = value.map(item => item.id);
            break;
          case "industries":
            params.industry_ids = value.map(item => item.id);
            break;
          case "technologies":
            params.technology_ids = value.map(item => item.id);
            break;
          case "scenarios":
            params.scenario_ids = value.map(item => item.id);
            break;
          case "institutions":
            params.institution_ids = value.map(item => item.id);
            break;
          default:
            break;
        }
      });
      return params;
    }

    setKeyword = keyword => {
      this.setState(
        {
          keyword,
        },
        () => {
          this.fetchData();
        }
      );
    };

    switchChecked = (categoryId, id, hasFilter = false, name) => {
      let targetList = this.state[categoryId];
      const targetIndex = targetList.findIndex(item => item.id === id);

      if (targetIndex === -1) {
        targetList.push({
          id,
          name,
          checked: true,
        });
      } else if (hasFilter) {
        targetList = removeIndexFromArr(targetList, targetIndex);
      } else {
        const target = targetList[targetIndex];
        target.checked = !target.checked;
      }

      // set checkList
      this.getCheckedList(categoryId, targetList);
      this.setState(
        {
          [categoryId]: targetList,
        },
        () => {
          this.fetchData();
        }
      );
    };

    clearCategoryChecked = (categoryId, hasFilter) => {
      let targetList = [];
      if (!hasFilter) {
        targetList = this.state[categoryId].map(item => ({
          ...item,
          checked: false,
        }));
      }

      // set checkList
      this.getCheckedList(categoryId, targetList);
      this.setState(
        {
          [categoryId]: targetList,
        },
        () => {
          this.fetchData();
        }
      );
    };

    switchSort = sortMode => {
      let newSort = sortMode;
      const { sort } = this.state;
      if (sort === sortMode) {
        newSort = "";
      }

      this.setState(
        {
          sort: newSort,
        },
        () => {
          this.fetchData();
        }
      );
    };

    loadPgae = page => {
      this.fetchData(page + 1);
    };

    fetchData = (page = 1) => {
      getListInIndustry(
        type,
        {
          ...this.getCheckedParams(),
        },
        this.state.keyword,
        page
      ).done(({ items, total_count }) => {
        if (page === 1) {
          this.forcePage = 0;
        } else {
          this.forcePage = undefined;
        }
        this.setState(
          {
            resultList: items,
            totalCount: total_count,
          },
          () => {
            pageScrollToTarget();
          }
        );
      });
    };

    render() {
      if (this.state.firstFetch) return <Loading />;

      const { keyword, sort, resultList, totalCount } = this.state;
      const typeName = translateType[type];

      return (
        <div className="u-container u-clearfix u-relative">
          <div
            className="u-sidebar__container t-industry industry__block industry-filter is-fixed"
            id="js-left-wrapper"
          >
            <AddKeywords setKeyword={this.setKeyword} typeName={typeName} />
            <FilterPannel
              categories={categories}
              list={this.state}
              switchChecked={this.switchChecked}
              checkedList={this.checkedList}
            />
          </div>
          <div className="u-col-3" />
          <div
            className="u-col-9 has-gutter industry__block industry--right"
            id="js-right-wrapper"
          >
            <div className="industry-checked">
              {keyword !== "" && (
                <div className="industry-checked__item t-keyword">
                  包含“
                  <span className="u-text-highlight">{keyword}</span>
                  ”的
                  {typeName}
                </div>
              )}
              <CheckedListHeader
                switchChecked={this.switchChecked}
                checkedList={this.checkedList}
                categories={categories}
                clearCategoryChecked={this.clearCategoryChecked}
                typeName={typeName}
              />
            </div>
            <SortHeader
              sort={sort}
              switchSort={this.switchSort}
              totalCount={totalCount}
              type={type}
            />
            {totalCount === 0 ? (
              <NoData className="t-inner" />
            ) : (
              resultList.map(item => <Item key={item.id} {...item} />)
            )}
            <Paginate
              initialPage={0}
              pageCount={totalCount / 25}
              loadPaginate={this.loadPgae}
              forcePage={this.forcePage}
            />
          </div>
        </div>
      );
    }
  }

  StatusInnerWrapper.propTypes = {
    filter_condition: PropTypes.object.isRequired,
    default_list: PropTypes.array.isRequired,
    count: PropTypes.number,
  };

  StatusInnerWrapper.defaultProps = {
    count: 0,
  };

  return StatusInnerWrapper;
};

export default ProduceFilter;
