import { throttle } from "lodash";

import {
  rightDirectory,
  setOnlyTargetActive,
} from "@shared/scripts/utils/tool";

const event = () => {
  const $window = $(window);
  const scrollNeedHeight = Math.ceil($("#js-event-header").height() - 60);
  const $fixedHeader = $("#js-header-fixed");
  const $eventContainer = $(".js-event-container");
  const $directory = $(".js-directory-container");
  const $titleInContainer = $("#js-event-content h1");
  let activeTitleIndex = 0;

  rightDirectory("#js-event-content", "h1", 60);

  const $directoryItem = $(".topic-directory__item");
  const eventTop = $directory.offset().top - 60;
  // 90 is postionTop when fixed
  const eventBottom =
    $eventContainer.offset().top +
    $eventContainer.height() -
    $directory.height() -
    90;

  $window.on(
    "scroll",
    throttle(() => {
      const scrollTop = Math.ceil($window.scrollTop());
      let currentActiveIndex = 0;

      if (scrollTop > scrollNeedHeight) {
        $fixedHeader.addClass("is-show");
      } else {
        $fixedHeader.removeClass("is-show");
      }

      if (scrollTop < eventTop) {
        $directory.removeClass("is-fixed is-bottom");
      } else if (scrollTop < eventBottom) {
        $directory.removeClass("is-bottom").addClass("is-fixed");
      } else {
        $directory.removeClass("is-fixed").addClass("is-bottom");
      }

      $titleInContainer.each((index, item) => {
        // 64: header height + hr height
        const top = scrollTop + 64;
        if (top >= item.offsetTop) {
          currentActiveIndex = index;
        }
      });

      if (currentActiveIndex !== activeTitleIndex) {
        activeTitleIndex = currentActiveIndex;
        setOnlyTargetActive($($directoryItem[activeTitleIndex]));
      }
    }, 10)
  );

  $window.scroll();
};

export default event;
