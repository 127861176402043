import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "../../shared/ImageContainer";

const InstitutionItem = ({ id, name, summary, type, logo_url }) => {
  return (
    <div className="industry-item t-no-hover">
      <div className="industry-item__body">
        <div className="industry__institution">
          <ImageContainer
            url={logo_url}
            name={name}
            className="industry__institution--logo t-default"
            theme="t-logo"
          />
          <div className="industry__institution--right">
            {/* <div className="u-flex"> */}
            <h4 className="industry-solution__title u-text-limit--two">
              <a
                className="industry-solution__target"
                href={`/graph/institutions/${id}`}
                target="_blank"
                alt={name}
              >
                {name}
              </a>
              <span className="industry__scale">{type}</span>
            </h4>
            {/* <span className="newsletter-top__heat">
                <i className="iconfont icon-huo u-mr-6" />20
              </span>
            </div> */}
            <p className="industry-solution__label t-block u-text-limit--two">
              {summary}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

InstitutionItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  type: PropTypes.string,
  logo_url: PropTypes.string,
};

InstitutionItem.defaultProps = {
  summary: "",
  type: "其他",
  logo_url: "",
};

export default InstitutionItem;
