import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../../shared/ImageLinkContainer";

const ContentList = ({ list }) => {
  return list.map(({ id, title, cover_image_url, path }) => (
    <div key={id} className="internal-phase__inline">
      <ImageLinkContainer
        path={path}
        url={`${cover_image_url}?imageView2/1/w/448/h/252`}
        name={title}
        className="internal-phase__cover"
      />
      <h6 className="internal-phase__title">{title}</h6>
    </div>
  ));
};

ContentList.propTypes = {
  list: PropTypes.array.isRequired,
};

class PhaseContent extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array,
    isShow: PropTypes.bool,
    activePhaseIndex: PropTypes.number,
  };

  static defaultProps = {
    list: [],
    isShow: false,
    activePhaseIndex: 0,
  };

  render() {
    const { isShow, list, activePhaseIndex } = this.props;

    return (
      <div className={`internal-phase ${isShow ? "is-show" : ""}`}>
        <div className="u-container internal-phase__container">
          <div
            className={`internal-phase__before t-index-${activePhaseIndex}`}
          />
          <div className="u-flex-wrap internal-phase__content">
            <ContentList list={list} />
          </div>
        </div>
      </div>
    );
  }
}

export default PhaseContent;
