import store from "@shared/scripts/utils/store";
import { openModal } from "@shared/scripts/actions/modal";
import { getDocumentFile } from "@shared/scripts/features/api";

export default () => {
  $("#js-get-document").on("click", function() {
    const { email, id } = this.dataset;
    getDocumentFile(id);
    store.dispatch(
      openModal("document", {
        email,
      })
    );
  });

  $("#js-pro-document").on("click", function() {
    const { url, apply_pending } = this.dataset;
    store.dispatch(openModal("charge", { url, apply_pending }));
  });
};
