import React from "react";
import PropTypes from "prop-types";

class SolutionItem extends React.PureComponent {
  render() {
    const {
      className,
      corporation_name,
      technologies,
      name,
      brand_name,
      path,
    } = this.props;

    return (
      <a
        className={`solution-item ${className}`}
        alt={corporation_name}
        rel="noopener noreferrer"
        target="_blank"
        href={path}
      >
        <div className="u-flex solution-item__header">
          <div className="solution-item__name">{corporation_name}</div>
          <div className="solution-item__tags">
            {technologies.map(item => (
              <span key={item.id} className="u-btn--gray solution-item__tag">
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div className="u-flex solution-item__footer">
          <span className="solution-item__title">{name}</span>
          <span>{brand_name}</span>
        </div>
      </a>
    );
  }
}

SolutionItem.propTypes = {
  corporation_name: PropTypes.string,
  technologies: PropTypes.array,
  name: PropTypes.string.isRequired,
  brand_name: PropTypes.string,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SolutionItem.defaultProps = {
  className: "search-record__inner is-large",
  corporation_name: "",
  brand_name: "",
  technologies: [],
};

export default SolutionItem;
