import { sidebarWithScroll } from "@shared/scripts/utils/tool";

const insight = () => {
  /* insight sidebar */
  sidebarWithScroll({
    sidebarSelector: ".insight-side",
    contentSelector: ".insight--container",
    isContentHeightChange: true,
    defaultClass: "is-fixed",
    mountCondition: sidebarAllHeight => {
      const clientHeight = $(window).height();
      if (
        clientHeight - sidebarAllHeight >
        document.getElementById("footer").clientHeight
      ) {
        return false;
      }

      return true;
    },
  });
};

export default insight;
