import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../ImageLinkContainer";

const defaultAvatar = require("image_default/expert.jpg");

const GraphAboutExperts = ({ experts }) => {
  if (experts.length === 0) return null;

  return (
    <div className="search-card__about">
      <div className="graph__title u-flex">
        <b className="graph__sub">相关人物</b>
        {/* <a href="javascript:;" className="search-index__more">查看全部35个</a> */}
      </div>
      <div className="search-card__experts">
        {experts.map(({ id, name, avatar_url, pro_url }) => {
          return (
            <div key={id} className="search-card__expert">
              <ImageLinkContainer
                path={`${pro_url}`}
                url={avatar_url || defaultAvatar}
                name={name}
                className="search-card__expert--avatar"
              />
              <span>{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

GraphAboutExperts.propTypes = {
  experts: PropTypes.array,
};

GraphAboutExperts.defaultProps = {
  experts: [],
};

export default GraphAboutExperts;
