import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";
import {
  submitInsightForm,
  getInsightData,
} from "@shared/scripts/features/api";

import Step1 from "./modal/Step1";
import Step2 from "./modal/Step2";
import Step3 from "./modal/Step3";
import Complete from "./modal/Complete";
import ReduxWrapper from "../utils/ReduxWrapper";

class InsightModal extends React.Component {
  constructor(props) {
    super(props);
    this.step2Data = null;
    this.STEP1 = 1;
    this.STEP2 = 2;
    this.STEP3 = 3;
    this.COMPLETE = 10;
    this.completeMsg = {};
    this.formDefaultvalue = {};

    this.state = {
      currentStep: this.STEP1,
      checkStatus: props.checkStatus,
    };

    this.getDefaultValue();
  }

  getDefaultValue = () => {
    getInsightData().done(res => {
      this.formDefaultvalue = res || {};
    });
  };

  setCurrentStep = stepIndex => {
    this.setState({
      currentStep: stepIndex,
    });
  };

  setCheckStatus = checkStatus => {
    this.setState({
      checkStatus,
    });
  };

  prevStep = () => {
    const { currentStep } = this.state;
    this.setCurrentStep(currentStep - 1);
  };

  close = () => {
    if (this.state.currentStep === this.COMPLETE) {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
    this.props.actions.closeModal();
    $(".js-insight-modal").remove();
  };

  goStep2 = () => {
    if (!this.state.checkStatus) return;

    this.setState({
      currentStep: this.STEP2,
    });
  };

  goStep3 = step2Data => {
    this.step2Data = step2Data;
    this.setCurrentStep(this.STEP3);
  };

  complete = () => {
    this.setCurrentStep(this.COMPLETE);
  };

  continueScan = () => {
    if (this.props.isMobile) {
      this.close();
    } else {
      const { actions, currentUser } = this.props;
      actions.openModal(
        "insightTip",
        { modalClassName: "t-tip", avatarUrl: currentUser.avatar_url },
        "fade"
      );
    }
  };

  submitForm = step3Data => {
    const data = {
      ...this.step2Data,
      ...step3Data,
      category: this.state.checkStatus,
      graduation_year: this.step2Data.graduation,
      full_name: step3Data.name,
      work_experience: this.step2Data.workYear,
    };

    submitInsightForm(data)
      .done(res => {
        this.completeMsg = {
          pubinfo: res.pubinfo,
          reveal: res.reveal,
        };
        this.complete();
      })
      .fail(() => {
        Salert.error("请重试");
      });
  };

  getContent = () => {
    const { currentStep, checkStatus } = this.state;
    if (currentStep === 1)
      return (
        <Step1
          goStep2={this.goStep2}
          checkStatus={checkStatus}
          setCheckStatus={this.setCheckStatus}
        />
      );
    if (currentStep === 2)
      return (
        <Step2
          defaultValue={this.formDefaultvalue}
          checkStatus={checkStatus}
          goStep3={this.goStep3}
        />
      );
    if (currentStep === 3)
      return (
        <Step3
          defaultValue={this.formDefaultvalue}
          submitForm={this.submitForm}
        />
      );
    return (
      <Complete
        currentUser={this.props.currentUser}
        msg={this.completeMsg}
        next={this.continueScan}
      />
    );
  };

  render() {
    const { currentStep } = this.state;

    return (
      <div className="insight-modal">
        {(currentStep === 2 || currentStep === 3) && (
          <a className="insight-modal__icon t-return" onClick={this.prevStep}>
            <i className="iconfont icon-previous" />
          </a>
        )}
        <a className="insight-modal__icon t-close" onClick={this.close}>
          <i className="iconfont icon-close1" />
        </a>
        {this.getContent()}
      </div>
    );
  }
}

InsightModal.propTypes = {
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  checkStatus: PropTypes.string,
};

InsightModal.defaultProps = {
  isMobile: false,
  checkStatus: null,
};

const mapState = state => ({
  currentUser: state.currentUser.info,
});

export default ReduxWrapper(InsightModal, mapState);
