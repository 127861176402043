import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import PARTIAL_SCHEMA from "@shared/scripts/features/particl_schema";

const client = () => {
  const httpLink = new HttpLink({
    uri: "/graphql",
    credentials: "include",
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: PARTIAL_SCHEMA,
  });

  const errorLink = onError(({ networkError }) => {
    // eslint-disable-next-line no-console
    console.log("networkError:", networkError);
  });

  // use with apollo-client
  const link = errorLink.concat(httpLink);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      fragmentMatcher,
    }),
  });

  return apolloClient;
};

export default client;
