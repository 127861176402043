import React from "react";
import { render } from "react-dom";

import Search from "@components/search/Search";
import ReportSearch from "@components/shared/ReportSearch";

const search = () => {
  render(<Search />, document.getElementById("js-search-app"));

  $(document).on("click", ".js-search-article", function() {
    const { keywords, id } = $(this).data();
    if (id.indexOf("AlmostHumanSpiderItem") > -1) return;

    if (keywords && id) {
      render(
        <ReportSearch keywords={keywords} id={id} />,
        document.getElementById("js-search-report")
      );
    }
  });
};

export default search;
