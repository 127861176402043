import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import CATEGORY_QUERY from "@graphql/category.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import MArticleItem from "../shared/MArticleItem";
import ArticleItemLoading from "../loading/ArticleItemLoading";

@graphql(CATEGORY_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      count: 10,
      title: props.title,
    },
  }),
})
@WaterFallWrapper(["category"], ArticleItemLoading)
class MCategoryShow extends React.Component {
  static propTypes = {
    edges: PropTypes.array,
  };

  static defaultProps = {
    edges: [],
  };

  render() {
    const { edges } = this.props;

    return edges.map(edge => (
      <MArticleItem key={edge.node.id} {...edge.node} theme="author" />
    ));
  }
}

export default ApolloWrapper(MCategoryShow);
