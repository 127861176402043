import { sidebarDropDown, sidebarWithScroll } from "@shared/scripts/utils/tool";

const member = () => {
  sidebarDropDown();
  sidebarWithScroll({
    sidebarSelector: "#js-sidebar-container",
    contentSelector: "#js-right-container",
    isContentHeightChange: true,
    defaultClass: "is-fixed",
    mountCondition: sidebarAllHeight => {
      const clientHeight = $(window).height();
      if (
        clientHeight - sidebarAllHeight >
        document.getElementById("footer").clientHeight
      ) {
        return false;
      }

      return true;
    },
  });

  const $qrcode = $(".js-qrcode");
  $qrcode.qrcode({
    text: `${document.location.origin}/users/${$qrcode.data("id")}/post`,
    width: 60,
    height: 60,
    correctLevel: 0,
  });

  const $modal = $("#js-modal");
  const $modalClose = $(".js-modal-close");
  const $welcome = $("#js-welcome");
  const $welcomeBtn = $("#js-welcome-btn");

  $modalClose.on("click", () => {
    $modal.addClass("is-hidden");
  });

  $welcomeBtn.on("click", () => {
    $welcome.addClass("is-show-footer");
  });
};

export default member;
