import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

class TypeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      value: props.value,
    };
  }

  handleSelectChange(value) {
    this.setState({ value });
  }

  render() {
    const { name, multi } = this.props;
    const { value } = this.state;

    return (
      <Select
        multi={multi}
        value={value}
        placeholder="选择类型"
        options={this.state.options}
        onChange={val => {
          this.handleSelectChange(val);
        }}
        name={name}
      />
    );
  }
}

TypeSelector.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  multi: PropTypes.bool,
};

TypeSelector.defaultProps = {
  name: "",
  multi: true,
  options: [],
  value: null,
};

export default TypeSelector;
