import { readInReadLater } from "@shared/scripts/features/api";

export default () => {
  $(".js-read-button").on("click", function(event) {
    event.preventDefault();
    const $this = $(this);
    const targetId = $this.data("id");
    if (targetId) {
      readInReadLater(targetId).done(() => {
        const $item = $(`.js-read-item[data-id="${targetId}"]`);
        $item
          .addClass("t-finished")
          .find(".read-item__speed")
          .css("width", "100%")
          .find(".read-item__count")
          .html("100%");

        $(this).remove();
      });
    }
  });
};
