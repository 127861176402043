/* eslint-disable function-paren-newline */
import Salert from "react-s-alert";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { updateUserInfo } from "@shared/scripts/features/api";
import UploadImageWrapper from "../utils/UploadImageWrapper";

const randomcover = require("cover/theme-1.jpg");

const UserMemberHtml = () => <span className="insight--pro t-middle">Pro</span>;

class UploadAvatar extends React.PureComponent {
  static propTypes = {
    uploadSuccessCb: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
  };

  render() {
    const { uploadSuccessCb, uploadUrl } = this.props;
    return (
      <UploadImageWrapper
        containerClass="user-header__avatar-mask"
        editContainerClass="u-flex user-header__avatar-edit"
        iconfontClass="icon-xiangji"
        field="avatar"
        uploadUrl={uploadUrl}
        uploadSuccessCb={uploadSuccessCb}
        aspectRatio={1}
        params="user"
        text="更换头像"
      />
    );
  }
}

class UploadCover extends React.PureComponent {
  static propTypes = {
    uploadSuccessCb: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
  };

  render() {
    const { uploadSuccessCb, uploadUrl } = this.props;
    return (
      <UploadImageWrapper
        containerClass="user-header__edit-mask"
        editContainerClass="user-header__edit-button u-btn-upload"
        iconfontClass="icon-tupian u-mr-6"
        field="background_image"
        uploadUrl={uploadUrl}
        uploadSuccessCb={uploadSuccessCb}
        aspectRatio={4 / 1}
        params="user"
        text="编辑或上传封面图片"
      />
    );
  }
}

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      background_image: props.user.background_image_url,
      avatar: props.user.avatar_url,
      name: props.user.name,
      bio: props.user.bio || "",
      risked: props.user.risked,
    };

    this.uploadUrl = "/settings/profile";
  }

  componentDidMount() {
    $(window).on("scroll", this.scrollHandle);
  }

  componentWillUnmount() {
    $(window).off("scroll", this.scrollHandle);
  }

  textChange = (name, value) => {
    this.setState({ [name]: value });
  };

  scrollHandle = () => {
    const $header = $(this.refs.header);
    const $window = $(window);
    const scrollTop = Math.ceil($window.scrollTop());

    // $user-cover-h
    $header.toggleClass("is-fixed", scrollTop >= 336);
  };

  uploadSuccessCb = (field, base64) => {
    this.setState({ [field]: base64 });
    Salert.success("更新成功");
  };

  saveChange = () => {
    const { name, bio } = this.state;
    updateUserInfo(name, bio).done(() => {
      this.setState({ name, bio, isEdit: false });
      Salert.success("更新成功");
    });
  };

  toChange = () => {
    this.cacheName = this.state.name;
    this.cacheBio = this.state.bio;
    this.setState({ isEdit: true }, () => {
      this.refs.nameInput.focus();
    });
  };

  toFollow = () => {
    Salert.success("关注成功");
  };

  cancelSave = () => {
    this.setState({ name: this.cacheName, bio: this.cacheBio, isEdit: false });
  };

  render() {
    const { currentUserId, user } = this.props;

    return (
      <div className="user-header" ref="header">
        <div className="u-image-base user-header__cover">
          <img
            className="u-image-center"
            src={this.state.background_image || randomcover}
            alt="封面图片"
          />
          {this.state.isEdit && (
            <UploadCover
              uploadUrl={this.uploadUrl}
              uploadSuccessCb={this.uploadSuccessCb}
            />
          )}
        </div>
        <div className="u-container">
          <div className="user-header__thumb">{this.state.name}</div>
          <div className="u-avatar-base user-header__avatar">
            <img
              className="u-image-center "
              src={this.state.avatar}
              alt="用户头像"
            />
            {this.state.isEdit && (
              <UploadAvatar
                uploadUrl={this.uploadUrl}
                uploadSuccessCb={this.uploadSuccessCb}
              />
            )}
          </div>
          <div className="u-flex user-header__info">
            <div className="user-header__data">
              {!this.state.isEdit ? (
                <Fragment>
                  <div className="user-header__title">
                    <span>{this.state.name}</span>
                    {user.member && <UserMemberHtml />}
                  </div>
                  <div className="u-text-limit--two user-header__bio">
                    {this.state.bio || "这个家伙很懒，什么也没有留下"}
                  </div>
                </Fragment>
              ) : (
                <div className="user-header__edit-filed">
                  <input
                    type="text"
                    ref="nameInput"
                    name="name"
                    placeholder="昵称"
                    value={this.state.name}
                    onChange={e => this.textChange("name", e.target.value)}
                  />
                  <textarea
                    type="text"
                    name="bio"
                    placeholder="简介"
                    value={this.state.bio}
                    onChange={e => this.textChange("bio", e.target.value)}
                  />
                </div>
              )}
            </div>
            {currentUserId === user.id ? (
              !this.state.isEdit ? (
                !this.state.risked ? (
                  <button
                    type="button"
                    className="u-btn--black user-header__btn is-default"
                    onClick={this.toChange}
                  >
                    <i className="iconfont icon-edit1" />
                    编辑个人资料
                  </button>
                ) : (
                  <span />
                )
              ) : (
                <div>
                  <button
                    type="button"
                    className="u-btn--gray user-header__btn"
                    onClick={this.cancelSave}
                  >
                    取消
                  </button>
                  <button
                    type="button"
                    className="u-btn--red user-header__btn"
                    onClick={this.saveChange}
                  >
                    保存
                  </button>
                </div>
              )
            ) : null
            // <a
            // className="u-btn--red user-header__btn"
            //   onClick={this.toFollow}
            // >
            //   关注
            // </a>
            }
          </div>
        </div>
      </div>
    );
  }
}

UserHeader.propTypes = {
  currentUserId: PropTypes.string,
  user: PropTypes.object.isRequired,
};

UserHeader.defaultProps = {
  currentUserId: "",
};

export default UserHeader;
