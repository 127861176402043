import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { removeIndexFromArr } from "@shared/scripts/utils/tool";
import FilterTags from "./FilterTags";
import Loading from "../utils/LogoLoading";

class FilterWrappper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTags: [],
    };
  }

  changeFilter(tag) {
    const { checkedTags } = this.state;
    const index = checkedTags.indexOf(tag);
    if (index > -1) {
      this.setState(
        {
          checkedTags: removeIndexFromArr(checkedTags, index),
        },
        () => {
          this.props.tagsChange(this.state.checkedTags);
        }
      );
    } else {
      this.setState(
        {
          checkedTags: [...checkedTags, tag],
        },
        () => {
          this.props.tagsChange(this.state.checkedTags);
        }
      );
    }
  }

  render() {
    const { tagsList, isFetching, children } = this.props;
    const { checkedTags } = this.state;

    return (
      <Fragment>
        <FilterTags
          key="filterTags"
          checkedTags={checkedTags}
          tags={tagsList}
          changeFilter={tag => {
            this.changeFilter(tag);
          }}
        />
        {children}
        {isFetching && <Loading className="t-has-white-bg" />}
      </Fragment>
    );
  }
}

FilterWrappper.propTypes = {
  tagsList: PropTypes.array,
  isFetching: PropTypes.bool,
  children: PropTypes.element.isRequired,
  tagsChange: PropTypes.func.isRequired,
};

FilterWrappper.defaultProps = {
  tagsList: [],
  isFetching: false,
};

export default FilterWrappper;
