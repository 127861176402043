import React from "react";
import PropTypes from "prop-types";

import {
  getValueFromString,
  getKeywordsFromHistory,
} from "@shared/scripts/utils/url";
import FetchMoreBtnWithLoading from "../utils/FetchMoreBtnWithLoading";
import * as routes from "./routes";

const FetchDataWrapper = Component => {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);

      const { history } = routes;
      this.keywords = getKeywordsFromHistory(history);
      this.oldHistorySearch = history.location.search;
      this.oldHistorySearchParams = getValueFromString(history.location.search);

      this.page = 1;
    }

    state = {
      isAllLoading: true,
      isReFetch: false,
      edges: [],
      isFetchMore: false,
      hasNextPage: true,
      tagList: [],
      firstPageData: {},
    };

    componentDidMount() {
      this.reFetchAllData();

      this.historyListen = routes.history.listen(location => {
        const { search } = location;
        if (search === this.oldHistorySearch) return;

        const newHistorySearchParams = getValueFromString(search);
        const isNotChangeSearchInternal =
          newHistorySearchParams.searchInternet ===
          this.oldHistorySearchParams.searchInternet;
        // update
        this.oldHistorySearch = search;
        this.oldHistorySearchParams = newHistorySearchParams;

        if (isNotChangeSearchInternal) {
          if (this.props.isCategoryAll) {
            this.reFetchDataInAll();
          } else {
            this.reFetchDataList();
          }
        } else {
          this.reFetchAllData();
        }
      });
    }

    componentWillUnmount() {
      this.historyListen();
    }

    getDataList = ({ cb = () => {}, isAdd = true, isRefreshTags = false }) => {
      const { params } = this.props;

      return this.createGetDataAjax(params)
        .done(res => {
          const { nodes, hasNextPage } = res[params];
          const { edges } = this.state;
          const newState = {
            edges: isAdd ? [...edges, ...nodes] : nodes,
            hasNextPage,
          };

          if (isRefreshTags && this.props.hasRightFilter) {
            newState.tagList = res.tags_list;
          }

          this.setState(newState, () => {
            this.page += 1;
          });
        })
        .always(() => {
          cb();
        });
    };

    /* category all: start */
    getDataInAll = (isChangeTagList = false) => {
      return this.createGetDataAjax("all").done(res => {
        const { articles, tags_list } = res;
        if (isChangeTagList) {
          this.setState({
            firstPageData: res,
            tagList: tags_list,
            edges: articles.nodes.slice(2),
            hasNextPage: articles.hasNextPage,
          });
        } else {
          this.setState({
            firstPageData: res,
            edges: articles.nodes.slice(2),
            hasNextPage: articles.hasNextPage,
          });
        }

        this.page += 1;
      });
    };

    reFetchDataInAll = () => {
      if (this.state.isReFetch) return;

      this.page = 1;
      this.toggleIsReFetch(true);
      this.getDataInAll().always(() => {
        this.toggleIsReFetch(false);
      });
    };
    /* category all: end */

    createGetDataAjax = params => {
      const {
        published,
        tags,
        isExactMatch,
        sortMode,
      } = this.oldHistorySearchParams;
      const url = `/api/v1/search?type=${params}&page=${
        this.page
      }&keywords=${encodeURI(this.keywords)}`;

      return $.ajax({
        url,
        method: "GET",
        datType: "json",
        data: {
          published,
          tags,
          is_exact_match: isExactMatch,
          sort: sortMode,
        },
      });
    };

    reFetchAllData = () => {
      this.toggleAllLoading(true);

      this.page = 1;
      const allFetch = this.props.isCategoryAll
        ? this.getDataInAll(true)
        : this.getDataList({ isAdd: false, isRefreshTags: true });

      allFetch.done(() => {
        this.toggleAllLoading(false);
      });
    };

    reFetchDataList = () => {
      if (this.state.isReFetch) return;

      this.page = 1;
      this.toggleIsReFetch(true);
      this.getDataList({
        cb: () => {
          this.toggleIsReFetch(false);
        },
        isAdd: false,
      });
    };

    fetchMoreDataList = () => {
      if (this.state.isFetchMore) return;

      this.toggleIsFetch(true);
      this.getDataList({
        cb: () => {
          this.toggleIsFetch(false);
        },
      });
    };

    toggleAllLoading = status => {
      this.setState({
        isAllLoading: status,
      });
    };

    toggleIsFetch = status => {
      this.setState({
        isFetchMore: status,
      });
    };

    toggleIsReFetch = status => {
      this.setState({
        isReFetch: status,
      });
    };

    render() {
      const {
        isAllLoading,
        edges,
        isReFetch,
        isFetchMore,
        hasNextPage,
        tagList,
        firstPageData,
      } = this.state;

      return (
        <Component
          {...this.props}
          keywords={this.keywords}
          data={{ loading: isAllLoading }}
          searchInternet={
            this.props.hasSearch && this.oldHistorySearchParams.searchInternet
          }
          isReFetch={isReFetch}
          isFetchMore={isFetchMore}
          hasNextPage={hasNextPage}
          fetchData={this.fetchMoreDataList}
          edges={edges}
          tagList={tagList}
          FetchBtn={FetchMoreBtnWithLoading}
          firstPageData={firstPageData}
        />
      );
    }
  }

  Wrapper.propTypes = {
    published: PropTypes.string,
    tags: PropTypes.array,
    isExactMatch: PropTypes.bool,
    searchInternet: PropTypes.bool,
    hasSearch: PropTypes.bool,
    sortMode: PropTypes.string,
    params: PropTypes.string,
    isCategoryAll: PropTypes.bool,
    hasRightFilter: PropTypes.bool,
  };

  Wrapper.defaultProps = {
    hasSearch: false,
    published: "0",
    tags: [],
    isExactMatch: false,
    searchInternet: true,
    sortMode: "about",
    params: "articles",
    isCategoryAll: false,
    hasRightFilter: false,
  };

  return Wrapper;
};

export default FetchDataWrapper;
