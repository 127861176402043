/* eslint-disable import/prefer-default-export */

export const collection = {
  openCollection() {
    return { type: "OPEN_COLLECTION" };
  },
  closeCollection() {
    return { type: "CLOSE_COLLECTION" };
  },
  createCollection() {
    return { type: "CREATE_COLLECTION" };
  },
  saveCollection({ name, icon, id }) {
    return { type: "SAVE_COLLECTION", payload: { icon, name, id } };
  },
};

export const searchFilter = {
  updatePublishFilter(publishedTime) {
    return { type: "UPDATE_PUBLISHED_TIME", payload: { publishedTime } };
  },
  updateMatchMode(isExactMatch) {
    return { type: "UPDATE_MATCH_MODE", payload: { isExactMatch } };
  },
  updateSearchMode(searchInternet) {
    return { type: "UPDATE_SEARCH_MODE", payload: { searchInternet } };
  },
  updateTagFilter(tags) {
    return { type: "UPDATE_TAG_FILTER", payload: { tags } };
  },
  updateSortMode(sortMode) {
    return { type: "UPDATE_SORT_MODE", payload: { sortMode } };
  },
};
