import React from "react";
import PropTypes from "prop-types";

import Origin from "../../shared/graph/Origin";
import AboutInstitutions from "../../shared/graph/AboutInstitutions";
import AboutTechnologies from "../../shared/graph/AboutTechnologies";
import ImageContainer from "../../shared/ImageContainer";

const Expert = ({
  name,
  summary,
  avatar_url,
  technologies,
  institutions,
  map_summary_urls,
  completeness,
  id,
}) => {
  return (
    <div className="search-index__block search-card">
      {avatar_url && (
        <ImageContainer
          url={avatar_url}
          name={name}
          className="search-card__avatar t-logo"
        />
      )}
      {completeness !== "level1" ? (
        <a
          className="u-main-title graph__keyword"
          href={`/graph/experts/${id}`}
          target="_blank"
        >
          {name}
        </a>
      ) : (
        <div className="graph__keyword">{name}</div>
      )}
      <p className="graph__content">{summary}</p>
      <Origin sources={map_summary_urls} />
      <div className="search-card__hr" />
      <AboutTechnologies technologies={technologies} />
      <AboutInstitutions institutions={institutions} />
    </div>
  );
};

Expert.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  avatar_url: PropTypes.string,
  map_summary_urls: PropTypes.array,
  institutions: PropTypes.array,
  technologies: PropTypes.array,
  completeness: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

Expert.defaultProps = {
  summary: "",
  avatar_url: null,
  map_summary_urls: [],
  institutions: [],
  technologies: [],
};

export default Expert;
