import React from "react";
import PropTypes from "prop-types";
import DocumentItemDetail from "./DocumentItemDetail";

class DcoumentItemWithRightcover extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { path, cover_image_url, title } = this.props;

    return (
      <a className="insight-item__inline insight-document__item" href={path}>
        <DocumentItemDetail {...this.props} />
        <div className="u-image-base insight-document__image">
          <img alt={title} className="u-image-center" src={cover_image_url} />
        </div>
      </a>
    );
  }
}

DcoumentItemWithRightcover.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
};

DcoumentItemWithRightcover.defaultProps = {
  cover_image_url: "",
};

export default DcoumentItemWithRightcover;
