import React from "react";
import { render } from "react-dom";
import { shareAction } from "@shared/scripts/utils/tool";

export const openPostModal = () => {
  $(".js-switch-modal").on("click", () => {
    $("#js-post-modal").toggleClass("is-hidden");
  });
};

export const submitColumnForm = () => {
  $(".js-form-submit").on("click", () => {
    const $form = $(".js-column-form");
    $form.ajaxSubmit({
      url: $form.attr("action"),
      method: "PATCH",
      dataType: "json",
      success: () => {
        $(".js-form-success")
          .css({
            opacity: 1,
          })
          .animate(
            {
              opacity: 0,
            },
            5000
          );
      },
    });
  });
};

export const generateQRcode = () => {
  $(".js-qrcode").qrcode({
    text: document.location.href,
    width: 120,
    height: 120,
  });
  shareAction();
};

export const subHeaderAction = () => {
  const $window = $(window);
  const $subHeader = $("#js-sub-header");
  const headerHeight = Math.ceil($subHeader.innerHeight() - 60);

  $window.on("scroll", () => {
    if (Math.ceil($window.scrollTop()) >= headerHeight) {
      $subHeader.addClass("is-fixed");
    } else {
      $subHeader.removeClass("is-fixed");
    }
  });
};

export const columnHeadeAction = () => {
  const $window = $(window);
  $window.on("scroll", () => {
    // 60: header height
    if (Math.ceil($window.scrollTop()) > 60) {
      $(".js-column-header").addClass("is-fixed");
      $(".js-sidebar-container").addClass("is-fixed");
    } else {
      $(".js-column-header").removeClass("is-fixed");
      $(".js-sidebar-container").removeClass("is-fixed");
    }
  });
};

export const renderComponent = (
  Component,
  targetId = "js-industry-container"
) => {
  const target = document.getElementById(targetId);
  if (target === null) return;

  try {
    const initialData = JSON.parse(target.getAttribute("data-initial"));
    render(<Component {...initialData} />, target);
    target.setAttribute("data-initial", "");
  } catch (e) {
    target.innerHTML("出错啦");
    // eslint-disable-next-line no-console
    console.error("existData can not parse to JSON object");
  }
};
