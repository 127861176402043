import React from "react";
import PropTypes from "prop-types";

import GraphHeader from "./GraphHeader";
import Origin from "../../graph/Origin";

const Techology = ({ id, name, summary, map_summary_urls, completeness }) => {
  return (
    <div className="search-panel__graph">
      <GraphHeader
        id={id}
        type="技术"
        name={name}
        summary={summary}
        path={`/graph/technologies/${id}`}
        completeness={completeness}
      />
      <Origin sources={map_summary_urls} />
    </div>
  );
};

Techology.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  map_summary_urls: PropTypes.array,
  completeness: PropTypes.string,
};

Techology.defaultProps = {
  summary: "",
  map_summary_urls: [],
  completeness: "level2",
};

export default Techology;
