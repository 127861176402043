import React from "react";
import PropTypes from "prop-types";

import LikeAndCommentAction from "./LikeAndCommentAction";

class TopicItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { cover_image_url, path, title, author, isEvent } = this.props;

    return (
      <div
        className="topic-inline has-tag"
        style={{
          backgroundImage: `linear-gradient(rgba(40, 40, 40, 0.5), rgba(40, 40, 40, 0.5)), url(${cover_image_url})`,
        }}
        data-tag={isEvent ? "活动" : "专题"}
      >
        <a
          className="topic-inline__cover"
          href={path}
          alt={title}
          rel="noopener noreferrer"
          target="_blank"
        />
        <div className="topic-inline__info">
          <a
            className="u-text-limit--two topic-inline__title"
            href={path}
            alt={title}
            rel="noopener noreferrer"
            target="_blank"
          >
            {title}
          </a>
          <div className="topic-inline__other">
            {!isEvent && (
              <a
                className="topic-inline__link u-like-icon"
                href={author.path}
                alt={author.name}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="iconfont icon-seeusero" />
                {author.name}
              </a>
            )}
            {/* <span className="topic-inline__link"><i className="iconfont icon-iconlist" />14</span> */}
            <LikeAndCommentAction
              {...this.props}
              iconClassName="topic-inline__link"
            />
          </div>
        </div>
      </div>
    );
  }
}

TopicItem.propTypes = {
  cover_image_url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object,
  isEvent: PropTypes.bool,
};

TopicItem.defaultProps = {
  isEvent: false,
  author: null,
};

export default TopicItem;
