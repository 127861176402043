import React from "react";

const SpinnerLoading = () => {
  return (
    <div className="u-loadmore__spinner">
      <div className="u-loadmore__bounce u-loadmore__bounce1" />
      <div className="u-loadmore__bounce u-loadmore__bounce2" />
      <div className="u-loadmore__bounce u-loadmore__bounce3" />
    </div>
  );
};

export default SpinnerLoading;
