export const searchHeader = {
  all: "全部",
  article: "文章",
  // card: '知识',
  // report: '论文',
  solution: "应用案例",
  // institution: '机构',
  // expert: '人物',
  user: "用户",
};

// TODO: don't use number as key
export const publishedTime = {
  0: "未选择",
  7: "1周内",
  30: "1月内",
  90: "3月内",
  180: "6月内",
  366: "1年内",
};

export const sortAllMode = {
  relevance: "相关度",
  time: "时间",
  overall: "综合",
};

export const sourceName = {
  PedailySpiderItem: "投资界",
  ChinaventureSpiderItem: "投中网",
  ThirtysixSpiderItem: "36氪",
  AlmostHumanSpiderItem: "机器之心",
  TmtSpiderItem: "钛媒体",
  SinaSpiderItem: "新浪新闻",
  ThePaperSpiderItem: "澎湃新闻",
  YiouSpiderItem: "亿欧网",
  YiCaiSpiderItem: "第一财经",
  XinHuaSpiderItem: "新华网",
  RenMingSpiderItem: "人民网",
  NewsQQSpiderItem: "腾讯科技",
  WeiXinGZHSpiderItem: "微信公众号",
  FengHuangSpiderItem: "凤凰网",
  AlmostHumanNewSpiderItem: "机器之心新闻数据",
};

export const graphTypes = {
  technologies: "技术",
  experts: "人物",
  institutions: "机构",
};

export const categoriesTranslate = {
  入门: "basic",
  产业: "industry",
  工程: "practice",
  理论: "theory",
};
