import React from "react";
import { flowRight } from "lodash";
import CategoryList from "./CategoryList";

import HasDataWrapper from "../utils/HasDataWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import FetchDataWrapper from "./FetchDataWrapper";

const ProduceCategory = params => {
  const Catetorytype = flowRight(
    FetchDataWrapper(params),
    LoadingWrapper,
    HasDataWrapper(true)
  )(CategoryList);

  return <Catetorytype />;
};

export default ProduceCategory;
