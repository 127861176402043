import store from "@shared/scripts/utils/store";
import { openModal } from "@shared/scripts/actions/modal";

export default () => {
  const $openBtn = $(".js-open-insight");

  $openBtn.on("click", function() {
    const { status } = this.dataset;
    store.dispatch(openModal("insight", { checkStatus: status }));
  });
};
