import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import EVENT_QUERY from "@graphql/events.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import TopicItem from "../shared/TopicItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";

@graphql(EVENT_QUERY, {
  options: {
    variables: {
      cursor: "",
      count: 10,
    },
  },
})
@WaterFallWrapper(["events"])
class EventList extends React.Component {
  render() {
    const { edges } = this.props;

    return (
      <div className="topic-inline__list">
        {edges.map(({ node }) => (
          <TopicItem
            key={node.id}
            {...node}
            title={node.name}
            {...node.content}
            isEvent
          />
        ))}
      </div>
    );
  }
}

EventList.propTypes = {
  edges: PropTypes.array,
};

EventList.defaultProps = {
  edges: [],
};

export default ApolloWrapper(EventList);
