import React from "react";
import PropTypes from "prop-types";
import UUID from "uuid-js";
import {
  readImageFile,
  makeBoundary,
  multipart,
  string2ArrayBuffer,
} from "@shared/scripts/utils/tool";
import { updateImage } from "@shared/scripts/features/api";
import ReduxWrapper from "./ReduxWrapper";

class UploadImageWrapper extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    containerClass: PropTypes.string.isRequired,
    iconfontClass: PropTypes.string.isRequired,
    editContainerClass: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    uploadUrl: PropTypes.string.isRequired,
    uploadSuccessCb: PropTypes.func.isRequired,
    aspectRatio: PropTypes.number.isRequired,
    params: PropTypes.string.isRequired,
  };

  cropHandle = (base64, field) => {
    const { params } = this.props;
    const boundary = makeBoundary();
    const multipartString = multipart(
      base64,
      boundary,
      `${params}[${field}]`,
      `${field}-${UUID.create().toString()}`
    );
    const arrayBuffer = string2ArrayBuffer(multipartString);
    const { uploadUrl, uploadSuccessCb } = this.props;

    return updateImage(uploadUrl, boundary, arrayBuffer).done(() => {
      this.props.actions.closeModal();
      uploadSuccessCb(field, base64);
    });
  };

  uploadAvatarImage = e => {
    const { actions, field, aspectRatio } = this.props;

    readImageFile(e).then(result => {
      actions.openModal("cropImage", {
        src: result,
        aspectRatio,
        cropHandle: base64 => this.cropHandle(base64, field),
      });
    });
  };

  changeImage = () => {
    this.refs.input.click();
  };

  render() {
    const {
      containerClass,
      editContainerClass,
      iconfontClass,
      text,
    } = this.props;

    return (
      <div className={containerClass}>
        <input
          type="file"
          accept="image/*"
          ref="input"
          onChange={this.uploadAvatarImage}
        />
        <a
          className={editContainerClass}
          href="javascript:;"
          onClick={this.changeImage}
        >
          <i className={`iconfont ${iconfontClass}`} />
          {text}
        </a>
      </div>
    );
  }
}

export default ReduxWrapper(UploadImageWrapper);
