import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import { pwd_rule, buildRules } from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";

class ResetPwd extends React.Component {
  submit() {
    if (!this.props.isFormValidate()) return;

    const { password, pwd } = this.props.data;
    if (password.value !== pwd.value) {
      Salert.error("两次输入密码不一致");
      return;
    }

    this.props.resetPwd(password.value);
  }

  render() {
    const {
      data: { password, pwd },
      changeValue,
    } = this.props;

    return (
      <form
        ref="form"
        action="/password_reset"
        className="signin-form js-forget-form"
      >
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          type="password"
          {...password}
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <CustomizeInput
          type="password"
          {...pwd}
          onChange={value => {
            changeValue(value, "pwd");
          }}
        />
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={() => {
            this.submit();
          }}
        >
          完成
        </button>
      </form>
    );
  }
}

ResetPwd.propTypes = {
  resetPwd: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  password: buildRules("password", "新密码", [pwd_rule]),
  pwd: buildRules("pwd", "再次输入密码", [pwd_rule]),
})(ResetPwd);
