/* eslint-disable no-alert */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { render } from "react-dom";

import Salert from "react-s-alert";
import {
  getVerificationCodeByName,
  sendVerifyCodeWithName,
  resetPassword,
} from "@shared/scripts/features/api";
import ForgetPwd from "./forget/ForgetPwd";
import ResetPwd from "./forget/ResetPwd";
import VerificationCode from "./forget/VerificationCode";
import RucaptchaModal from "./forget/RucaptchaModal";
import ReduxWrapper from "../utils/ReduxWrapper";

class RetrievePwd extends React.Component {
  constructor() {
    super();

    this.state = {
      activeIndex: 0,
      loginName: "",
    };
  }

  getVerificationCode(loginName, rucaptcha, cb, hasTip = false) {
    getVerificationCodeByName(loginName, rucaptcha)
      .done(res => {
        if (res.success) {
          if (hasTip) {
            Salert.success("验证码已发送！");
          }

          this.switchIndex(1);
          this.props.actions.closeModal();

          if (this.props.isMobile) {
            $(".js-forget-modal").removeClass("is-visible");
            $("body").removeClass("open-modal");
          }
        }
      })
      .fail(xhr => {
        if (this.props.isMobile) {
          alert(xhr.responseText);
        }
        cb();
      });
  }

  setloginNameAndSend(name, rucaptcha, cb) {
    this.setState({
      loginName: name,
    });
    this.getVerificationCode(name, rucaptcha, cb);
  }

  switchIndex(index) {
    this.setState({
      activeIndex: index,
    });
  }

  sendVerify(code) {
    sendVerifyCodeWithName(this.state.loginName, code).done(res => {
      if (res.success) {
        this.switchIndex(2);
      }
    });
  }

  resetPwd(password) {
    const location = window.location;

    resetPassword(this.state.loginName, password).done(res => {
      if (res.success) {
        if (window.location.search) {
          window.location.href = `${location.protocol}//${location.host.replace(
            "www",
            "pro"
          )}`;
        } else {
          window.location.href = "/";
        }
      }
    });
  }

  resend() {
    if (this.props.isMobile) {
      $(".js-forget-modal").addClass("is-visible");
      $("body").addClass("open-modal");
      render(
        <RucaptchaModal
          resendTo={(rucaptcha, cb) => {
            this.getVerificationCode(this.state.loginName, rucaptcha, cb, true);
          }}
        />,
        document.getElementById("js-modal-main")
      );
      return;
    }

    this.props.actions.openModal("rucaptcha", {
      resendTo: (rucaptcha, cb) => {
        this.getVerificationCode(this.state.loginName, rucaptcha, cb, true);
      },
    });
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <div className="u-container">
        <div className="u-col-10 forget-step__container">
          <div
            className={`forget-step ${activeIndex === 0 ? "is-active" : ""}`}
          >
            <span
              className={`forget-step__index ${
                activeIndex > 0 ? "is-pass" : ""
              }`}
            >
              {activeIndex > 0 ? <i className="iconfont icon-true" /> : "1"}
            </span>
            <p className="forget-step__title">验证账号</p>
            <p className="forget-step__sub">完成账号验证</p>
          </div>
          <div
            className={`forget-step is-middle ${
              activeIndex === 1 ? "is-vaild" : ""
            }`}
          >
            <span
              className={`forget-step__index ${
                activeIndex > 1 ? "is-pass" : ""
              }`}
            >
              {activeIndex > 1 ? (
                <i className="iconfont icon-true" />
              ) : activeIndex === 1 ? (
                <i className="iconfont icon-iconguanbi" />
              ) : (
                "2"
              )}
            </span>
            <p className="forget-step__title">确认验证码</p>
            <p className="forget-step__sub">请输入验证码</p>
          </div>
          <div className="forget-step__line is-first" />
          <div className="forget-step__line" />
          <div className="forget-step">
            <span className="forget-step__index">3</span>
            <p className="forget-step__title">重置密码</p>
            <p className="forget-step__sub">请输入新密码</p>
          </div>
        </div>
        <div className="forget__container">
          {activeIndex === 0 && (
            <ForgetPwd
              getVerificationCode={(loginName, rucaptcha, cb) => {
                this.setloginNameAndSend(loginName, rucaptcha, cb);
              }}
            />
          )}
          {activeIndex === 1 && (
            <Fragment>
              <p className="forget__tip">
                我们已经向您的注册账户
                {this.state.loginName}
                &nbsp;发送了一个验证码，请您
                <span>注意查收</span>
              </p>
              <VerificationCode
                sendVerify={code => {
                  this.sendVerify(code);
                }}
              />
              <p className="forget__tip forget__send">
                没有收到？
                <span
                  onClick={() => {
                    this.resend();
                  }}
                >
                  重新发送
                </span>
              </p>
            </Fragment>
          )}
          {activeIndex === 2 && (
            <ResetPwd
              resetPwd={password => {
                this.resetPwd(password);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

RetrievePwd.propTypes = {
  actions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

RetrievePwd.defaultProps = {
  isMobile: false,
};

export default ReduxWrapper(RetrievePwd);
