import React from "react";
import PropTypes from "prop-types";

import DailyFooter from "./DailyFooter";

class DailyItem extends React.PureComponent {
  render() {
    const { path, title, content, url, likes_count } = this.props;

    return (
      <div className="daily-every__inline">
        <div className="daily-every__item">
          <div>
            <a
              className="u-text-limit--two daily-every__title js-open-modal"
              href={path}
              data-remote="true"
            >
              {title}
            </a>
            <div
              className="u-text-limit--three daily__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <DailyFooter url={url} likesCount={likes_count} path={path} />
        </div>
      </div>
    );
  }
}

DailyItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  url: PropTypes.string,
  likes_count: PropTypes.number,
};

DailyItem.defaultProps = {
  content: "",
  url: "",
  likes_count: 0,
};

const DailyList = ({ dailyList }) => {
  return (
    <div className="daily-every__list">
      {dailyList.map(item => (
        <DailyItem key={item.id} {...item} />
      ))}
    </div>
  );
};

DailyList.propTypes = {
  dailyList: PropTypes.array.isRequired,
};

export default DailyList;
