/* eslint-disable no-new */
import React from "react";
import PropTypes from "prop-types";
import Swiper from "swiper";

class SolutionInAll extends React.Component {
  componentDidMount() {
    if (this.props.solutions.length > 0) {
      new Swiper("#js-all-usages", {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 23,
        navigation: {
          nextEl: ".js-paginnation-next",
          prevEl: ".js-paginnation-pre",
          disabledClass: "is-disabled",
        },
      });
    }
  }

  redirectUrl = () => {
    const query = window.location.search;
    window.location.href = `/search/solution${query}`;
  };

  render() {
    const { solutions, keywords, hasMore } = this.props;
    if (solutions.length < 1) return null;

    return (
      <div className="search-record__inner">
        <h3 className="search-record__title">
          涉及“
          {keywords}
          ”的应用案例
        </h3>
        <div
          className="swiper-container search-record__usage--contianer"
          id="js-all-usages"
        >
          <div className="swiper-wrapper">
            {solutions.map(usage => (
              <a
                key={usage.id}
                className="swiper-slide search-record__usage"
                href={usage.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="search-index__more">
                  {usage.corporation_name}
                </div>
                <div className="u-text-limit--one search-record__link t-usage">
                  {usage.name}
                </div>
              </a>
            ))}
            {hasMore && (
              // {`/search/solution?keywords=${keywords}`}
              <a
                className="swiper-slide search-record__usage"
                href="javascript:;"
                alt="查看更多应用案例"
                onClick={this.redirectUrl}
              >
                <p className="search-record__usage--more">
                  {"查看更多应用案例>"}
                </p>
              </a>
            )}
          </div>
        </div>
        <a
          className="search-pagination__btn t-prev js-paginnation-pre"
          href="javascript:;"
        >
          <i className="iconfont icon-prev3" />
        </a>
        <a
          className="search-pagination__btn t-next js-paginnation-next"
          href="javascript:;"
        >
          <i className="iconfont icon-next2" />
        </a>
      </div>
    );
  }
}

SolutionInAll.propTypes = {
  solutions: PropTypes.array,
  keywords: PropTypes.string,
  hasMore: PropTypes.bool,
};

SolutionInAll.defaultProps = {
  solutions: [],
  keywords: "",
  hasMore: false,
};

export default SolutionInAll;
