import React from "react";
import PropTypes from "prop-types";

class FetchMoreBtn extends React.PureComponent {
  render() {
    const { action, isFetchMore } = this.props;
    return (
      <div className={`u-loadmore ${isFetchMore ? "is-hidden" : ""}`}>
        <a
          className="u-btn--red u-loadmore__btn"
          href="javascript:;"
          onClick={action}
        >
          加载更多
        </a>
      </div>
    );
  }
}

FetchMoreBtn.propTypes = {
  isFetchMore: PropTypes.bool,
  action: PropTypes.func.isRequired,
};

FetchMoreBtn.defaultProps = {
  isFetchMore: false,
};

export default FetchMoreBtn;
