import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import TOPICS_QUERY from "@graphql/topics.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import TopicItem from "../shared/TopicItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";

@graphql(TOPICS_QUERY, {
  options: {
    variables: {
      cursor: "",
      count: 10,
    },
  },
})
@WaterFallWrapper(["topics"])
class TopicList extends React.Component {
  render() {
    const { edges } = this.props;

    return (
      <div className="topic-inline__list">
        {edges.map(edge => (
          <TopicItem key={edge.node.id} {...edge.node} />
        ))}
      </div>
    );
  }
}

TopicList.propTypes = {
  edges: PropTypes.array,
};

TopicList.defaultProps = {
  edges: [],
};

export default ApolloWrapper(TopicList);
