import React from "react";
import ProduceFilter from "./ProduceFilter";
import QuestItem from "./item/QuestItem";

const categories = [
  {
    id: "huotou",
    name: "需求状态",
    icon: "icon-xuqiuzhuangtai",
    hasFilter: false,
  },
  { id: "lingyu", name: "行业", icon: "icon-lingyu", hasFilter: false },
  { id: "jishu", name: "技术", icon: "icon-jishu", hasFilter: true },
];

// { id: 'jishu', name: '机构', icon: 'icon-jigou1', hasFilter: true }

const Requirements = () => {
  const Container = ProduceFilter(categories, QuestItem, "quests");
  return <Container />;
};

export default Requirements;
