import React from "react";
import PropTypes from "prop-types";
import cover_default from "image_default/article_cover_default.svg";
import ImageLinkContainer from "./ImageLinkContainer";
import LikeAndCommentAction from "./LikeAndCommentAction";
import ArticleAuthor from "./article/ArticleAuthor";
import ArticleCategory from "./article/ArticleCategory";
import ArticlePublishedTime from "./article/ArticlePublishedTime";

const themes = {
  author: () => ArticleAuthor,
  category: () => ArticleCategory,
  time: () => ArticlePublishedTime,
};

class MArticleItem extends React.Component {
  FooterLeft = themes[this.props.theme]();

  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const {
      cover_image_url,
      path,
      title,
      author,
      category_name,
      category_path,
      published_at,
    } = this.props;
    const { FooterLeft } = this;

    return (
      <article className="article-item__container">
        <main className="article-item__left">
          <a
            className="article-item__title"
            href={path}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="u-flex">
            <FooterLeft
              author={author}
              categoryName={category_name}
              categoryPath={category_path}
              publishedAt={published_at}
            />
            <LikeAndCommentAction {...this.props} />
          </div>
        </main>
        <ImageLinkContainer
          path={path}
          url={`${cover_image_url}?imageView2/1/w/130/h/118`}
          name={title}
          className="article-item__cover"
        />
      </article>
    );
  }
}

MArticleItem.propTypes = {
  cover_image_url: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
  category_name: PropTypes.string,
  category_path: PropTypes.string,
  published_at: PropTypes.string,
  theme: PropTypes.string,
};

MArticleItem.defaultProps = {
  cover_image_url: cover_default,
  category_name: "",
  category_path: "",
  published_at: "",
  theme: "category",
};

export default MArticleItem;
