import React from "react";
import PropTypes from "prop-types";

const GraphOrigin = ({ sources }) => {
  if (sources.length === 0) return null;

  return (
    <div className="graph__title graph__origins">
      <i className="iconfont icon-link2 u-mr-10" />
      来源：
      {sources.map(({ name, link }) => (
        <a
          key={name}
          className="graph__origin"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      ))}
    </div>
  );
};

GraphOrigin.propTypes = {
  sources: PropTypes.array,
};

GraphOrigin.defaultProps = {
  sources: [],
};

export default GraphOrigin;
