/* eslint-disable no-useless-escape */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import { email_rule, buildRules } from "@shared/scripts/utils/validation_rules";
import { settingEmail } from "@shared/scripts/features/api";
import FormWrapper from "../../utils/FormWrapper";
import CustomizeInput from "../../shared/CustomizeInput";

class EmailForm extends React.Component {
  submit() {
    if (!this.props.isFormValidate()) return;

    const { email } = this.props.data;
    const { closeModal, fetchDate } = this.props;
    settingEmail(email.value).done(() => {
      Salert.success("修改成功");
      fetchDate();
      closeModal();
    });
  }

  render() {
    const {
      data: { email },
      changeValue,
    } = this.props;

    return (
      <div className="user-set__form">
        <p className="forget__resend-tip">修改邮箱</p>
        <CustomizeInput
          {...email}
          onChange={value => {
            changeValue(value, "email");
          }}
        />
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={() => {
            this.submit();
          }}
        >
          保存
        </button>
      </div>
    );
  }
}

EmailForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fetchDate: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  email: buildRules("user[email]", "请输入邮箱地址", [email_rule]),
})(EmailForm);
