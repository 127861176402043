import React from "react";
import PropTypes from "prop-types";

import ImageLinkContainer from "../../shared/ImageLinkContainer";

class CategoryList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
  };

  render() {
    const { list } = this.props;

    return list.map(({ id, path, cover_image_url, title, sequential }) => (
      <div key={id} className="internal-reference__item">
        <div className="internal-reference__inline">
          <span className="internal-reference__index">
            Vol.
            {sequential}
          </span>
          <ImageLinkContainer
            path={path}
            url={`${cover_image_url}?imageView2/1/w/564/h/320`}
            name={title}
            className="internal-reference__cover"
          />
        </div>
      </div>
    ));
  }
}

export default CategoryList;
