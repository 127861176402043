import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import CATEGORY_QUERY from "@graphql/category.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItemLoading from "../loading/ArticleItemLoading";
import ArticleItem from "../shared/ArticleItem";
import MArticleItem from "../shared/MArticleItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(CATEGORY_QUERY, {
  options: {
    variables: {
      cursor: "MTI=",
      count: 10,
      title: "industry",
    },
  },
})
@WaterFallWrapper(["category"], ArticleItemLoading)
class IndustryArticles extends React.Component {
  articleItem = this.props.isMobile ? MArticleItem : ArticleItem;

  render() {
    const { edges } = this.props;
    const Article = this.articleItem;

    return edges.map(({ node }) => (
      <Article key={node.id} {...node} isHasCategory={false} />
    ));
  }
}

IndustryArticles.propTypes = {
  edges: PropTypes.array,
  isMobile: PropTypes.bool,
};

IndustryArticles.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(IndustryArticles);
