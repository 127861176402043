import React from "react";
import PropTypes from "prop-types";
import ReduxWrapper from "../utils/ReduxWrapper";

class SearchAddChosen extends React.Component {
  openChosen = () => {
    this.props.actions.openModal("collectionAdd");
  };

  render() {
    return (
      <div className="search-chosen__open" onClick={this.openChosen}>
        <i className="iconfont icon-plus u-plus-base search-chosen__circle" />
      </div>
    );
  }
}

SearchAddChosen.propTypes = {
  actions: PropTypes.object.isRequired,
};

SearchAddChosen.defaultProps = {};

export default ReduxWrapper(SearchAddChosen);
