import React from "react";
import PropTypes from "prop-types";

const DocumentItemDetail = props => {
  const { title, summary, published_at } = props;

  return (
    <div className="insight-document__left">
      <div className="insight-item__title">{title}</div>
      <p className="insight-item__summary u-text-thin u-text-limit--three">
        {summary}
      </p>
      <time
        className="insight-item__time u-text-thin insight-document__time"
        dateTime={published_at}
      >
        {$.timeago(published_at)}
      </time>
    </div>
  );
};

DocumentItemDetail.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  published_at: PropTypes.string.isRequired,
};

DocumentItemDetail.defaultProps = {
  description: "",
};

export default DocumentItemDetail;
