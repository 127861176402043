import * as modalReducers from "./modal";
import * as userReducers from "./user";
import * as searchReducers from "./search";
import * as sotaModalReducers from "./sota_modal";

export default {
  ...modalReducers,
  ...userReducers,
  ...searchReducers,
  ...sotaModalReducers,
};
