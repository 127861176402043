import React from "react";
import PropTypes from "prop-types";

const GraphAboutInstitutions = ({ institutions }) => {
  if (institutions.length === 0) return null;

  return (
    <div className="search-card__about">
      <div className="graph__title u-flex">
        <b className="search-card__sub">相关机构</b>
        {/* <a href="javascript:;" className="search-index__more">查看全部35个</a> */}
      </div>
      <div className="graph__mechas">
        {institutions.map(({ id, name, pro_url }) => {
          return (
            <a
              key={id}
              className="graph__mecha"
              href={`${pro_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

GraphAboutInstitutions.propTypes = {
  institutions: PropTypes.array,
};

GraphAboutInstitutions.defaultProps = {
  institutions: [],
};

export default GraphAboutInstitutions;
