import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import dayjs from "dayjs";

import FetchMoreBtn from "../utils/FetchMoreBtn";
import DailiesLoading from "./DailiesLoading";

const DailyWrapper = Component => {
  class Wrapper extends React.Component {
    state = {
      isFetchMore: false,
      dailies: {},
    };

    componentDidMount() {
      this.resolveData();
    }

    resolveData = () => {
      const { edges } = this.props.data.dailies;
      const { dailies } = this.state;

      edges.forEach(({ node }) => {
        const date = dayjs(node.created_at).format("YYYY-MM-DD");
        if (dailies[date] === undefined) {
          dailies[date] = [];
        }
        dailies[date].push(node);
      });

      this.setState({
        dailies,
      });
    };

    fetchData = () => {
      this.setState({
        isFetchMore: true,
      });

      const { fetchMore, variables, dailies } = this.props.data;
      variables.cursor = dailies.pageInfo.endCursor;

      fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { edges, pageInfo } = fetchMoreResult.dailies;

          return update(previousResult, {
            dailies: {
              edges: { $set: edges },
              pageInfo: { $set: pageInfo },
            },
          });
        },
      }).then(() => {
        this.resolveData();
        this.setState({ isFetchMore: false });
      });
    };

    render() {
      const { isFetchMore, dailies } = this.state;
      const { data, isMobile } = this.props;
      const {
        edges,
        pageInfo: { hasNextPage },
      } = data.dailies;

      return (
        <Component
          edges={edges}
          dailies={dailies}
          LoadingComponent={DailiesLoading}
          hasNextPage={hasNextPage}
          FetchBtn={FetchMoreBtn}
          isFetchMore={isFetchMore}
          fetchData={this.fetchData}
          isMobile={isMobile}
        />
      );
    }
  }

  Wrapper.propTypes = {
    data: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
  };

  Wrapper.defaultProps = {
    isMobile: false,
  };

  return Wrapper;
};

export default DailyWrapper;
