import React from "react";
import { bindActionCreators } from "redux";
import { connect, Provider } from "react-redux";

import actions from "../../shared/scripts/actions";
import store from "../../shared/scripts/utils/store";

const ReduxWrapper = (Component, mapState = f => f, options = {}) => {
  const NewComponent = connect(
    mapState,
    dispatch => ({ actions: bindActionCreators(actions, dispatch) }),
    null,
    options
  )(Component);

  const Wrapper = props => {
    return (
      <Provider store={store}>
        <NewComponent {...props} />
      </Provider>
    );
  };

  Wrapper.displayName = `ReduxProvider(${Component.name})`;
  return Wrapper;
};

export default ReduxWrapper;
