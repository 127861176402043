import React from "react";
import PropTypes from "prop-types";

import Technologies from "./Technologies";
import Industries from "./Industries";

const QuestItem = ({
  path,
  name,
  technicals,
  industries,
  corporationName,
  createdAt,
}) => {
  return (
    <div className="industry-item t-no-hover">
      <div className="industry-item__body">
        <div className="u-flex">
          <h4 className="industry-solution__title u-text-limit--two">
            <a
              className="industry-solution__target"
              href={path}
              target="_blank"
              alt={name}
            >
              {name}
            </a>
          </h4>
          <span className="industry-solution__label t-second">
            {corporationName}
          </span>
        </div>
        <div className="industry-solution__tags">
          <Technologies technicals={technicals} />
          <Industries industries={industries} />
        </div>
        <div className="u-flex industry-solution__footer">
          <time className="industry-solution__time">
            发布时间：
            {createdAt}
          </time>
          <a
            className="u-btn--gray industry-solution__status"
            href={path}
            target="_blank"
            alt={name}
          >
            已解决
          </a>
        </div>
      </div>
    </div>
  );
};

QuestItem.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  technicals: PropTypes.array,
  industries: PropTypes.array,
  corporationName: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
};

QuestItem.defaultProps = {
  corporationName: "",
  industries: [],
  technicals: [],
};

export default QuestItem;
