import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "../../ImageContainer";

class NoticeComment extends React.Component {
  state = {
    isRead: false,
  };

  onClick = () => {
    this.props.onRead().done(() => {
      this.setState({ isRead: true });
    });
  };

  render() {
    const { actor, content, target } = this.props.data;

    return (
      <a
        className={`notice__item ${this.state.isRead ? "is-read" : ""}`}
        href={`${target.path}#comment`}
        alt="通知"
        onClick={this.onClick}
      >
        <ImageContainer
          url={actor.avatar_url}
          name={actor.name}
          className="notice__avatar"
          type="avatar"
        />
        <div className="notice__body">
          <p className="u-text-limit--one notice__line">
            <span className="notice__responser">{actor.name}</span>
            <span className="notice__tip">{content}</span>
          </p>
          <p className="u-text-limit--one notice__line">{target.content}</p>
        </div>
      </a>
    );
  }
}

NoticeComment.propTypes = {
  data: PropTypes.object.isRequired,
  onRead: PropTypes.func.isRequired,
};

export default NoticeComment;
