import React from "react";
import PropTypes from "prop-types";

import {
  rucaptcha_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";

class RucaptchaModal extends React.Component {
  updateRucaptcha = e => {
    const $rucaptcha = $(e.target);
    $rucaptcha.attr("src", $rucaptcha.attr("src"));
  };

  submit() {
    if (!this.props.isFormValidate()) return;

    this.props.resendTo(this.props.data.code.value, () => {
      $(this.refs.rucaptcha).click();
    });
  }

  render() {
    const {
      data: { code },
      changeValue,
    } = this.props;

    return (
      <div className="forget__modal">
        <p className="forget__resend-tip">请输入图形验证码</p>
        <div className="u-pic-code">
          <CustomizeInput
            {...code}
            onChange={value => {
              changeValue(value, "code");
            }}
          />
          <img
            ref="rucaptcha"
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => this.updateRucaptcha(e)}
          />
        </div>
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={() => {
            this.submit();
          }}
        >
          发送
        </button>
      </div>
    );
  }
}

RucaptchaModal.propTypes = {
  resendTo: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  code: buildRules("_rucaptcha", "图形验证码", [rucaptcha_rule]),
})(RucaptchaModal);
