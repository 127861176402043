import React, { PureComponent } from "react";
import { func, string, bool } from "prop-types";

class SwitchButton extends PureComponent {
  static propTypes = {
    onSwitch: func.isRequired,
    id: string,
    theme: string,
    isActive: bool,
  };

  static defaultProps = {
    theme: "t-black",
    id: "",
    isActive: false,
  };

  render() {
    const { theme, onSwitch, isActive } = this.props;
    const isActiveClass = isActive ? "is-active" : "";

    return (
      <div
        id={this.props.id}
        className={`rc-switch__btn ${theme} ${isActiveClass}`}
        onClick={() => onSwitch(!isActive)}
      >
        <span className="rc-switch__inner">
          <i className="iconfont icon-turn-on rc-switch__icon" />
        </span>
      </div>
    );
  }
}

export default SwitchButton;
