import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import COMMENTS_QUERY from "@graphql/notificationReplies.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import CommentHasReply from "../shared/CommentHasReply";
import UserMomentLoading from "../loading/UserMomentLoading";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(COMMENTS_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      id: props.currentUserId,
    },
  }),
})
@WaterFallWrapper(["user", "replies"], UserMomentLoading)
class NoticeReplies extends React.Component {
  render() {
    const { edges } = this.props;

    return edges.map(edge => {
      const { id, target, actor, created_at, read_at } = edge.node;
      return (
        <CommentHasReply
          key={id}
          comment={target}
          created_at={created_at}
          user={actor}
          isNotice
          noticeId={id}
          isRead={read_at !== null}
        />
      );
    });
  }
}

NoticeReplies.propTypes = {
  edges: PropTypes.array,
};

NoticeReplies.defaultProps = {
  edges: [],
};

export default ApolloWrapper(NoticeReplies);
