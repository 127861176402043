import React from "react";
import PropTypes from "prop-types";

const CheckedListHeader = ({
  clearCategoryChecked,
  switchChecked,
  checkedList,
  categories,
}) => {
  return categories.map(({ id: categoryId, name, hasFilter }) => {
    const list = checkedList[categoryId];
    if (list === undefined || list.length === 0) return null;

    return (
      <div className="industry-checked__item" key={`checke-${categoryId}`}>
        <span className="industry-checked__label">{name}：</span>
        <div className="industry-checked__right">
          {list.map(({ id, name: itemName }) => (
            <span
              key={`checked-${id}`}
              className="industry-checked__inline"
              href="javascript:;"
            >
              {itemName}
              <a
                className="industry-checked__remove"
                href="javascript:;"
                onClick={() => switchChecked(categoryId, id, hasFilter)}
              >
                <i className="iconfont icon-close2" />
              </a>
            </span>
          ))}
          {list.length > 5 && (
            <span
              className="industry-checked__clear"
              onClick={() => clearCategoryChecked(categoryId, hasFilter)}
            >
              清除所有
            </span>
          )}
        </div>
      </div>
    );
  });
};

CheckedListHeader.propTypes = {
  clearCategoryChecked: PropTypes.func.isRequired,
  switchChecked: PropTypes.func.isRequired,
  checkedList: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

export default CheckedListHeader;
