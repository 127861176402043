import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { searchBase } from "@shared/scripts/features/api";
import SearchPanel from "./SearchPanel";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.debounceFetch = _.debounce(this.fetchData, 1000);

    this.state = {
      keywords: props.keywords || "",
      logined: props.logined || false,
      isFetching: true,
      data: {},
      isOpenPannel: false,
    };
  }

  componentWillUnmount() {
    this.debounceFetch.cancel();
  }

  changeHandle = value => {
    if (value === "") {
      this.setState({
        data: {},
        keywords: "",
        isFetching: true,
      });
      this.debounceFetch.cancel();
    } else {
      this.setState({
        keywords: value,
      });
      this.debounceFetch(value);
    }
  };

  fetchData = value => {
    if (value === "") return;

    this.toggleFetch(true);
    searchBase(value)
      .done(res => {
        this.setState({
          data: res,
        });
      })
      .always(() => {
        this.toggleFetch(false);
      });
  };

  toggleFetch = value => {
    this.setState({
      isFetching: value,
    });
  };

  toggleOpenPannle = value => {
    this.setState({
      isOpenPannel: value,
    });
  };

  OpenPannel = () => {
    this.toggleOpenPannle(true);
    this.input.focus();
  };

  addFocus = event => {
    event.preventDefault();
    this.input.focus();
  };

  keydownHandle = event => {
    const { keywords } = this.state;
    if (keywords === "") return;

    if (event.which === 13) {
      event.preventDefault();
      window.location = `/search/all?keywords=${encodeURI(keywords)}`;
    }
  };

  resetInput = () => {
    this.setState({
      keywords: "",
      data: {},
      isFetching: true,
    });
  };

  addBlur = () => {
    this.input.blur();
  };

  render() {
    const { keywords, isOpenPannel, logined } = this.state;

    return [
      <div
        key="header-search-input"
        className={`header-search ${isOpenPannel ? "is-focus" : ""} ${
          isOpenPannel ? "i-is-focus" : ""
        }`}
        ref="headerSearch"
      >
        <a
          className="header-search__btn t-left"
          href="javascript:;"
          alt="搜索"
          onClick={this.OpenPannel}
        >
          <i className="iconfont icon-sreach" />
        </a>
        <input
          ref={input => {
            this.input = input;
          }}
          className={`header-search__input ${
            isOpenPannel ? "i-is-focus" : ""
          } ${isOpenPannel && logined ? "i-is-focus--login" : ""}`}
          type="text"
          placeholder="探索机器之心"
          value={keywords}
          onFocus={() => this.toggleOpenPannle(true)}
          onBlur={() => this.toggleOpenPannle(false)}
          onKeyDown={this.keydownHandle}
          onChange={e => {
            this.changeHandle(e.target.value);
          }}
        />
        <a
          className="header-search__btn t-right"
          href="javascript:;"
          alt="清空搜索框"
          onMouseDown={this.resetInput}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>,
      <SearchPanel
        key="header-search-panel"
        preventBlur={this.addFocus}
        changeHandle={this.changeHandle}
        {...this.state}
      />,
    ];
  }
}

Search.propTypes = {
  keywords: PropTypes.string,
};

Search.defaultProps = {
  keywords: "",
};

export default Search;
