/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import {
  mobile_or_email_rule,
  pwd_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

import wxIcon from "sota-login/we-chat";
import gitIcon from "sota-login/github-circle";
import wxHIcon from "sota-login/we-chat--hover";
import gitHIcon from "sota-login/github-circle--hover";

class SigninForm extends React.Component {
  state = {
    wxLink: wxIcon,
    gitLink: gitIcon,
  };

  wxLogin = () => {
    let url = "";
    const mdetect = require("mdetect");

    if (mdetect.isWechat()) {
      url = `${window.location.origin}/auth/wechatservice`;
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=wx8c9a93acb12e2b2d&scope=snsapi_login&redirect_uri=${window.location.origin}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    }
    window.location.href = url;
  };

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.signinForm);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        if (res.success) {
          Salert.success("登录成功");
          window.location.reload();
        }
      },
    });
  }

  render() {
    const {
      data: { login_name, password },
      changeValue,
    } = this.props;

    return (
      <form
        ref="signinForm"
        action="/login"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <div className="form-header-area">
          <p className="form-header-title">登录</p>
          <p className="form-header-tips">
            尚未注册？立即<span onClick={this.props.switchState}>创建用户</span>
          </p>
        </div>
        <input name="utf8" type="hidden" value="✓" />
        <input name="remember_me" type="hidden" value="0" />
        <CustomizeInput
          {...login_name}
          onChange={value => {
            changeValue(value, "login_name");
          }}
        />
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          登录机器之心账号
        </a>
        <p className="signin-form__tip text-right">
          <label className="signin-form__remember">
            <input
              className="signin-form__checkbox"
              type="checkbox"
              name="remember_me"
              value="1"
              placeholder="记住密码"
            />
            记住密码
          </label>
          <span>
            <a
              className="signin-form__signup signin-form__forget"
              href="/forget_password"
              alt="忘记密码"
            >
              <i className="iconfont icon-tip" />
              忘记密码
            </a>
          </span>
        </p>
        <div className="form-other">
          <p>- 其他登录方式-</p>
          <div>
            <a
              ref="wechat"
              href="javascript:;"
              title="微信登录"
              onClick={this.wxLogin}
            >
              <img
                onMouseEnter={() =>
                  this.setState({
                    ...this.state,
                    wxLink: wxHIcon,
                  })
                }
                onMouseOut={() =>
                  this.setState({
                    ...this.state,
                    wxLink: wxIcon,
                  })
                }
                src={this.state.wxLink}
                alt="wx"
              />
            </a>
            <a href="/auth/github" title="GitHub登录">
              <img
                onMouseEnter={() =>
                  this.setState({
                    ...this.state,
                    gitLink: gitHIcon,
                  })
                }
                onMouseOut={() =>
                  this.setState({
                    ...this.state,
                    gitLink: gitIcon,
                  })
                }
                src={this.state.gitLink}
                alt="github"
              />
            </a>
          </div>
        </div>
        <div className="form-footer">
          注册即代表我已阅读并同意{" "}
          <a
            href="https://www.jiqizhixin.com/short_urls/5f217ec7-6c22-4843-8953-3851e8c3b467"
            target="_blank"
          >
            《用户协议》
          </a>
        </div>
      </form>
    );
  }
}

SigninForm.propTypes = {
  switchState: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  login_name: buildRules("login_name", "手机号/邮箱", [mobile_or_email_rule]),
  password: buildRules("password", "密码", [pwd_rule]),
})(SigninForm);
