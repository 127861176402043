import React from "react";
import PropTypes from "prop-types";
import DocumentItemDetail from "./DocumentItemDetail";

class DcoumentItemWithLeftcover extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { path, cover_image_url, title, charged } = this.props;

    return (
      <div className="insight-item__half">
        <a className="insight-item__inline insight-document__item" href={path}>
          <div className="u-image-base insight-document__image--left">
            <img alt={title} className="u-image-center" src={cover_image_url} />
            {charged ? <span className="charged">付费</span> : null}
          </div>
          <DocumentItemDetail {...this.props} />
        </a>
      </div>
    );
  }
}

DcoumentItemWithLeftcover.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
};

DcoumentItemWithLeftcover.defaultProps = {
  cover_image_url: "",
};

export default DcoumentItemWithLeftcover;
