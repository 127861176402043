import React from "react";
import PropTypes from "prop-types";

const ArticlePublishedTime = props => {
  const { publishedAt } = props;
  return (
    <time className="article-item__publish">{$.timeago(publishedAt)}</time>
  );
};

ArticlePublishedTime.propTypes = {
  publishedAt: PropTypes.any,
};

ArticlePublishedTime.defaultProps = {
  publishedAt: null,
};

export default ArticlePublishedTime;
