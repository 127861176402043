import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import TIMELINE_QUERY from "@graphql/timelineInHome.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItemLoading from "../loading/ArticleItemLoading";
import ArticleItem from "../shared/ArticleItem";
import MArticleItem from "../shared/MArticleItem";
import TopicItem from "../shared/TopicItem";
import DocumentItem from "../shared/DocumentItem";
import PeriodicalItem from "../shared/PeriodicalItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(TIMELINE_QUERY, {
  options: props => ({
    variables: {
      cursor: props.cursor,
      count: 10,
      category: props.categoryName,
      type: "everyone",
    },
  }),
})
@WaterFallWrapper(["timelines"], ArticleItemLoading, false, -1)
class HomeNewest extends React.Component {
  articleItem = this.props.isMobile ? MArticleItem : ArticleItem;

  render() {
    const { edges } = this.props;
    const Article = this.articleItem;

    return edges.map(edge => {
      const { node } = edge;

      if (node.content_type === "Article") {
        return <Article key={node.id} {...node.content} remote />;
      }
      if (node.content_type === "Topic") {
        return <TopicItem key={node.id} {...node.content} />;
      }
      if (node.content_type === "Periodical") {
        return <PeriodicalItem key={node.id} {...node.content} />;
      }
      if (node.content_type === "Event") {
        return (
          <TopicItem
            key={node.id}
            title={node.content.name}
            {...node.content}
            isEvent
          />
        );
      }
      if (node.content_type === "Document") {
        return <DocumentItem key={node.id} {...node.content} />;
      }
      return null;
    });
  }
}

HomeNewest.propTypes = {
  edges: PropTypes.array,
  isMobile: PropTypes.bool,
};

HomeNewest.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(HomeNewest);
