/* eslint-disable no-param-reassign */
//  parse out a fragment
export const parseData = (params, data) => {
  return params.reduce((accu, key) => {
    return accu[key];
  }, data);
};

// combine to a whole object
export const combData = (params, fragment) => {
  const obj = {};
  const last = params.length - 1;
  const result = params.slice(0, last).reduce((p, c) => {
    p[c] = {};
    return p[c];
  }, obj);
  result[params[last]] = fragment;

  return obj;
};
