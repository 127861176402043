import React from "react";

const SkipWrapper = Component => {
  class Wrapper extends React.PureComponent {
    state = {
      skip: true,
    };

    componentDidMount() {
      const $window = $(window);
      const removeSkip = () => {
        const $target = $(this.refs.wrapper);

        if ($target.closest(".js-u-item").hasClass("is-active")) {
          const nowScroll = $window.scrollTop();
          const targetAllHeight = $target.offset().top - 10;

          if (Math.ceil(nowScroll + $(window).height()) >= targetAllHeight) {
            this.setState(
              {
                skip: false,
              },
              () => {
                $window.off("scroll", "", removeSkip);
              }
            );
          }
        }
      };

      $window.on("scroll", removeSkip);
    }

    render() {
      return (
        <div ref="wrapper">
          {!this.state.skip && <Component {...this.props} />}
        </div>
      );
    }
  }
  return Wrapper;
};

export default SkipWrapper;
