import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import { pwd_rule, buildRules } from "@shared/scripts/utils/validation_rules";
import { settingPassword } from "@shared/scripts/features/api";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";

class PasswordModal extends React.Component {
  submit() {
    const { isFirstSet, actions, fetchDate } = this.props;
    const { password, originPassword } = this.props.data;
    if (isFirstSet) {
      if (password.value === "" || !password.valid) return;
    } else if (!this.props.isFormValidate()) {
      return;
    }

    settingPassword(password.value, originPassword.value).done(() => {
      if (!isFirstSet) {
        fetchDate();
      }
      Salert.success("修改成功");
      actions.closeModal();
    });
  }

  render() {
    const {
      data: { originPassword, password },
      changeValue,
      actions,
      isFirstSet,
    } = this.props;

    return (
      <div className="user-set__modal">
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={actions.closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
        <div className="forget__container">
          <p className="forget__resend-tip">
            {isFirstSet ? "设置密码" : "修改密码"}
          </p>
          <form ref="form">
            <input name="utf8" type="hidden" value="✓" />
            {!isFirstSet && (
              <CustomizeInput
                type="password"
                {...originPassword}
                onChange={value => {
                  changeValue(value, "originPassword");
                }}
              />
            )}
            <CustomizeInput
              type="password"
              {...password}
              onChange={value => {
                changeValue(value, "password");
              }}
            />
            <button
              className="u-btn--black signin-form__submit"
              type="button"
              onClick={() => {
                this.submit();
              }}
            >
              保存
            </button>
          </form>
        </div>
      </div>
    );
  }
}

PasswordModal.propTypes = {
  actions: PropTypes.object.isRequired,
  isFirstSet: PropTypes.bool,
  fetchDate: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

PasswordModal.defaultProps = {
  isFirstSet: false,
};

export default FormWrapper({
  originPassword: buildRules("origin_password", "原密码", [pwd_rule]),
  password: buildRules("password", "输入密码", [pwd_rule]),
})(PasswordModal);
