const socialShare = {
  setting: {
    width: 700,
    height: 400,
  },

  getCoords: type => {
    if (type === "left")
      return (window.innerWidth - socialShare.setting.width) / 2;

    return (window.innerHeight - socialShare.setting.height) / 2;
  },

  openWindow(url) {
    window.open(
      url,
      "",
      `width=${this.setting.width}, height=${
        this.setting.height
      }, top=${this.getCoords("top")}, left=${this.getCoords(
        "left"
      )}, toolbar=no, menubar=no, scrollbars=no, location=yes, resizable=no, status=no`
    );
  },

  run({ type, url, text, des, thumb, appkey }) {
    const newUrl = encodeURIComponent(url || document.location);
    const newText = encodeURIComponent(text || document.title);
    const newDes = encodeURIComponent(des || "");
    const newThumb = thumb || "";
    const newappkey = appkey || "1342877212";

    let jump;
    switch (type) {
      case "weibo":
        jump = `http://service.weibo.com/share/share.php?url=${newUrl}&appkey=${newappkey}&title=${newText}&pic=${newThumb}&ralateUid=3996876140`;
        break;
      case "twitter":
        jump = `https://twitter.com/intent/tweet?url=${newUrl}&text=${newText}&via=Synced`;
        break;
      case "linkedin":
        jump = `https://www.linkedin.com/shareArticle?mini=true&url=${newUrl}&title=${newText}&summary=${newDes}&source=Synced`;
        break;
      default:
        break;
    }

    socialShare.openWindow(jump);
  },
};

module.exports = socialShare;
