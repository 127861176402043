import React from "react";
import PropTypes from "prop-types";

import Origin from "../../shared/graph/Origin";
import AboutInstitutions from "../../shared/graph/AboutInstitutions";
import AboutExperts from "../../shared/graph/AboutExperts";

const Techology = ({
  id,
  name,
  map_summary_urls,
  summary,
  experts,
  institutions,
  completeness,
}) => {
  return (
    <div className="search-index__block search-card">
      {completeness !== "level1" ? (
        <a
          className="u-main-title graph__keyword"
          href={`/graph/technologies/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      ) : (
        <div className="graph__keyword">{name}</div>
      )}
      <p className="graph__content">{summary}</p>
      <Origin sources={map_summary_urls} />
      {(institutions.length > 0 || experts.length > 0) && (
        <div className="search-card__hr" />
      )}
      <AboutInstitutions institutions={institutions} />
      <AboutExperts experts={experts} />
    </div>
  );
};

Techology.propTypes = {
  id: PropTypes.string.isRequired,
  completeness: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  map_summary_urls: PropTypes.array,
  summary: PropTypes.string,
  experts: PropTypes.array,
  institutions: PropTypes.array,
};

Techology.defaultProps = {
  map_summary_urls: [],
  summary: "",
  experts: [],
  institutions: [],
};

export default Techology;
