import React from "react";
// import PropTypes from 'prop-types';
import PropTypes from "prop-types";
import _ from "lodash";
import { getFilterInIndustry } from "@shared/scripts/features/api";

const ResultPannel = ({ addFocus, switchChecked, results }) => {
  return (
    <div className="industry-search__pannel" onMouseDown={addFocus}>
      {results.length === 0 && <p className="u-text-center">无相关数据</p>}
      {results.map(({ id, name, checked }, index) => (
        <a
          key={`type-1-${id}`}
          className="u-flex industry-filter__inline t-result"
          href="javascript:;"
          onMouseDown={() => switchChecked(index)}
        >
          <span>
            {name}
            {/* <span className="u-ml-10 industry-filter__count">(242)</span> */}
          </span>
          <span
            className={`u-flex industry-filter__checked ${
              checked ? "is-checked" : ""
            }`}
          >
            <i className="iconfont icon-true industry-filter__true" />
          </span>
        </a>
      ))}
    </div>
  );
};

ResultPannel.propTypes = {
  switchChecked: PropTypes.func.isRequired,
  addFocus: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
};

class FilterInput extends React.Component {
  state = {
    keywords: "",
    isShowPannel: false,
    results: [],
  };

  componentDidMount() {
    this.debounceFetch = _.debounce(this.fetchData, 1000);
  }

  componentWillUnmount() {
    this.debounceFetch.cancel();
  }

  toggleOpenPannle = status => {
    this.setState({
      isShowPannel: status,
    });
  };

  inputBlur = () => {
    this.toggleOpenPannle(false);
    this.setState({
      keywords: "",
      results: [],
    });
  };

  changeHandle = keywords => {
    this.setState({
      keywords,
    });
    this.debounceFetch(keywords);
  };

  fetchData = value => {
    if (value === "") return;

    // this.toggleFetch(true);
    getFilterInIndustry(this.props.categoryId, value).done(res => {
      const { categoryList } = this.props;
      const resList = res;

      // keep the state checked
      resList.forEach((targetItem, index) => {
        const findRes = categoryList.find(item => item.id === targetItem.id);
        if (findRes === undefined) return;

        resList[index].checked = findRes.checked;
      });

      this.setState({
        results: resList,
      });
    });
    // .always(() => {
    //   this.toggleFetch(false);
    // });
  };

  addFocus = e => {
    e.preventDefault();
    this.refs.searchInput.focus();
  };

  addBlur = () => {
    this.refs.searchInput.blur();
  };

  switchChecked = index => {
    const { switchChecked, categoryId } = this.props;
    const { results } = this.state;

    const target = results[index];
    target.checked = !target.checked;
    this.setState({
      results,
    });
    const { id, name } = target;
    switchChecked(categoryId, id, true, name);
  };

  render() {
    const { keywords, isShowPannel, results } = this.state;

    return (
      <div className="industry-filter__inline t-input u-relative">
        <div className="u-flex industry-search__container t-white">
          <i className="iconfont icon-sreach u-mr-6 industry-search__icon" />
          <input
            ref="searchInput"
            className="industry-search__input"
            type="text"
            placeholder="搜索"
            value={keywords}
            onFocus={() => this.toggleOpenPannle(true)}
            onBlur={() => this.inputBlur()}
            onChange={e => {
              this.changeHandle(e.target.value);
            }}
          />
          {keywords && (
            <span
              className="u-ml-6 industry-search__close"
              onClick={this.addBlur}
            >
              <i className="iconfont icon-close2" />
            </span>
          )}
        </div>
        {isShowPannel && (
          <ResultPannel
            addFocus={e => this.addFocus(e)}
            switchChecked={this.switchChecked}
            results={results}
          />
        )}
      </div>
    );
  }
}

FilterInput.propTypes = {
  switchChecked: PropTypes.func.isRequired,
  categoryList: PropTypes.array.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default FilterInput;
