import React from "react";
// import PropTypes from 'prop-types';

class PaperItem extends React.PureComponent {
  render() {
    const { author, link, pubdate, snippet, title } = {
      author: "刘晓坤",
      id: "AlmostHumanSpiderItem/0f70b053-2e80-4430-973c-f624de35f243",
      link: "https://www.jiqizhixin.com/articles/2017-10-25-4",
      pubdate: "2017-10-25T11:11:00Z",
      snippet:
        "<em>深度学习</em>工程师起薪30万，据LinkedIn一季度估计市场存在500万人才缺口。在全球最大招聘职位搜索引擎 Indeed上，<em>深度学习</em>工程师已成为最热门职位，被各大公司争先聘用。 \n本系列课程由fast",
      title: "从零开始精通深度学习",
    };

    return (
      <div className="search-record__inner search-report">
        <a
          className="article-item__title"
          href={link}
          dangerouslySetInnerHTML={{ __html: title }}
          data-remote={this.remote}
          target={this.remote ? "_self" : "_blank"}
        />
        <p
          className="search-index__more u-base-summary search-record__foreword"
          dangerouslySetInnerHTML={{ __html: snippet }}
        />
        <div className="u-flex">
          <div className="article-item__author">
            <a className="article-item__name" href="javascript:;" alt={author}>
              {author}
            </a>
            <span className="article-item__publish">{$.timeago(pubdate)}</span>
          </div>
          <span className="search-index__from">来源:paper weekly</span>
        </div>

        <div className="search-record__hr" />
        <div className="search-index__more search-report__tip">
          如果有相关论文就显示
        </div>
        <p className="u-text-limit--one search-report__more">
          <a
            className="search-report__about"
            href="javascript:;"
            alt="相关论文"
          >
            马斯克向美国媒体发动的愚蠢战争大公司
          </a>
        </p>
      </div>
    );
  }
}

export default PaperItem;
