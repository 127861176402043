import React from "react";
import { getKeywordsFromHistory } from "@shared/scripts/utils/url";
import _ from "lodash";
import FetchMoreBtnWithLoading from "../utils/FetchMoreBtnWithLoading";

import { history } from "../search/routes";

const FetchDataWrapper = (type, isCategoryAll = false) => Component => {
  class Wrapper extends React.Component {
    constructor() {
      super();
      this.keywords = getKeywordsFromHistory(history);
      this.page = 1;
      this.debounceReFetch = _.debounce(this.fetchMoreData, 500);
    }

    state = {
      isFetchMore: false,
      isAllLoading: true,
      edges: [],
      hasNextPage: false,
      firstPageData: {},
    };

    componentDidMount() {
      if (isCategoryAll) {
        this.fetchFirstDateInIndex();
      } else {
        this.fetchFirstDate();
      }
    }

    componentWillUnmount() {
      this.debounceReFetch.cancel();
    }

    createGetDataAjax = innertype => {
      const url = `/api/v1/search?type=${innertype}&page=${
        this.page
      }&keywords=${encodeURI(this.keywords)}`;

      return $.ajax({
        url,
        method: "GET",
        datType: "json",
      });
    };

    createGetData = (cb = () => {}) => {
      this.createGetDataAjax(type)
        .done(res => {
          const { nodes, hasNextPage } = res[type];
          const { edges } = this.state;

          this.setState(
            {
              edges: [...edges, ...nodes],
              hasNextPage,
            },
            () => {
              this.page += 1;
            }
          );
        })
        .always(() => {
          cb();
        });
    };

    fetchMoreData = () => {
      this.toggleFetchMore(true);
      this.createGetData(this.toggleFetchMore);
    };

    fetchFirstDate = () => {
      this.toggleAllLoading(true);
      this.createGetData(this.toggleAllLoading);
    };

    fetchFirstDateInIndex = () => {
      this.toggleAllLoading(true);
      this.createGetDataAjax("all")
        .done(res => {
          const { articles } = res;
          this.setState({
            firstPageData: res,
            edges: articles.nodes.slice(2),
            hasNextPage: articles.hasNextPage,
          });
          this.page += 1;
        })
        .always(() => {
          this.toggleAllLoading(false);
        });
    };

    toggleFetchMore = (status = false) => {
      this.setState({
        isFetchMore: status,
      });
    };

    toggleAllLoading = (status = false) => {
      this.setState({
        isAllLoading: status,
      });
    };

    render() {
      const {
        isFetchMore,
        edges,
        hasNextPage,
        isAllLoading,
        firstPageData,
      } = this.state;

      return (
        <Component
          data={{ loading: isAllLoading }}
          keywords={this.keywords}
          firstPageData={firstPageData}
          params={type}
          edges={edges}
          hasNextPage={hasNextPage}
          FetchBtn={FetchMoreBtnWithLoading}
          isFetchMore={isFetchMore}
          fetchData={this.fetchMoreData}
        />
      );
    }
  }

  Wrapper.propTypes = {};

  Wrapper.defaultProps = {};

  return Wrapper;
};

export default FetchDataWrapper;
