import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "react-select/dist/react-select.css";

class InformationSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  onChange(value) {
    this.setState({
      value,
    });
  }

  getUrl = () => {
    const { url, hasType } = this.props;
    return hasType ? document.getElementById("js-fetch-type").value : url;
  };

  getData = keyword => {
    if (!keyword) {
      return Promise.resolve({ options: [] });
    }
    const url = this.getUrl();
    const fetch_url = `/admin/graph/${url}?name=${keyword}`;
    return fetch(fetch_url, {
      method: "GET",
      credentials: "include",
    })
      .then(response => response.json())
      .then(json => {
        return { options: json[url] };
      });
  };

  render() {
    const { name, multi, creatable } = this.props;
    const { value } = this.state;
    const ReactSelect = creatable ? Select.AsyncCreatable : Select.Async;
    return (
      <ReactSelect
        multi={multi}
        value={value}
        placeholder="支持模糊搜索／别名搜索"
        onChange={val => {
          this.onChange(val);
        }}
        valueKey="id"
        labelKey="name"
        filterOptions={creatable ? undefined : false}
        loadOptions={keyword => {
          return this.getData(keyword);
        }}
        name={name}
      />
    );
  }
}

InformationSelector.propTypes = {
  creatable: PropTypes.bool,
  multi: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hasType: PropTypes.bool,
};

InformationSelector.defaultProps = {
  creatable: false,
  multi: true,
  name: "",
  url: "",
  value: [],
  hasType: false,
};

export default InformationSelector;
