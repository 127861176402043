import React from "react";
import ImageContainer from "../../ImageContainer";

const NoticeDaily = () => {
  return (
    <div className="notice__item">
      <ImageContainer url="" name="图片" className="notice__cover" />
      <div className="notice__right">
        <div className="notice__title">
          <span>AI Daily</span>
          <span>下午 4:55</span>
        </div>
        <div className="u-text-limit--two notice__summary">
          诺基亚与小米签署专利协议，共同探索人工智能、物联网、虚拟现实等等等等
        </div>
      </div>
    </div>
  );
};

export default NoticeDaily;
