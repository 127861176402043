import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import CATEGORY_QUERY from "@graphql/category.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import ArticleSimpleItem from "../shared/ArticleSimpleItem";
import FilterWrappper from "./FilterWrappper";
import HasDataWrapper from "../utils/HasDataWrapper";
import ArticleSimpleItemLoading from "../loading/ArticleSimpleItemLoading";
import FetchDataWrapper from "./FetchDataWrapper";

@HasDataWrapper(true, 2)
class ArticleList extends React.PureComponent {
  static propTypes = {
    edges: PropTypes.array,
    isFetchMore: PropTypes.bool,
  };

  static defaultProps = {
    edges: [],
    isFetchMore: false,
  };

  render() {
    const { edges, isFetchMore } = this.props;

    return (
      <div className="column-item__container">
        {edges.map(edge => (
          <div
            key={edge.node.id}
            className="column-item category-show__article"
          >
            <ArticleSimpleItem {...edge.node} />
          </div>
        ))}
        <ArticleSimpleItemLoading isFetchMore={isFetchMore} />
      </div>
    );
  }
}

@graphql(CATEGORY_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      count: 12,
      title: props.title,
      filterTags: [],
    },
  }),
})
@LoadingWrapper
@FetchDataWrapper(["category"])
class CategoryShow extends React.Component {
  render() {
    return (
      <FilterWrappper {...this.props}>
        <ArticleList {...this.props} />
      </FilterWrappper>
    );
  }
}
export default ApolloWrapper(CategoryShow);
