/* eslint-disable import/prefer-default-export */

export const collectionPanel = {
  addTo: "addTo",
  create: "create",
};

const initialCollectionState = {
  currentPanel: collectionPanel.addTo,
  list: [],
  isOpen: false,
};

const initialFilterState = {
  // currently this isOpen is uselss, but maybe will use in later, just keep it for now
  publishedTime: "0",
  searchInternet: true,
  isExactMatch: false,
  tags: [],
  sortMode: "overall",
};

export const collection = (state = initialCollectionState, action) => {
  switch (action.type) {
    case "OPEN_COLLECTION":
      return { ...state, isOpen: true };
    case "CLOSE_COLLECTION":
      return { ...state, currentPanel: collectionPanel.addTo };
    case "CREATE_COLLECTION":
      return { ...state, currentPanel: collectionPanel.create };
    case "SAVE_COLLECTION":
      return {
        ...state,
        currentPanel: collectionPanel.addTo,
        list: [
          ...state.list,
          {
            // let's use lodash id first, will replace with server response id
            id: action.payload.id,
            icon: action.payload.icon,
            name: action.payload.name,
          },
        ],
      };
    default:
      return state;
  }
};

export const searchFilter = (state = initialFilterState, action) => {
  switch (action.type) {
    case "UPDATE_PUBLISHED_TIME":
      return { ...state, publishedTime: action.payload.publishedTime };
    case "UPDATE_MATCH_MODE":
      return { ...state, isExactMatch: action.payload.isExactMatch };
    case "UPDATE_TAG_FILTER":
      return { ...state, tags: action.payload.tags };
    case "UPDATE_SEARCH_MODE":
      return { ...state, searchInternet: action.payload.searchInternet };
    case "UPDATE_SORT_MODE":
      return { ...state, sortMode: action.payload.sortMode };
    default:
      return state;
  }
};
