import React from "react";
import PropTypes from "prop-types";

import FilterInput from "./FilterInput";

class FilterPannel extends React.Component {
  state = {
    activeType: 0,
  };

  setActiveType = index => {
    let newValue = index;
    if (this.state.activeType === index) {
      newValue = -1;
    }

    this.setState({
      activeType: newValue,
    });
  };

  render() {
    const { activeType } = this.state;
    const { categories, list, switchChecked, checkedList } = this.props;

    return (
      <div className="industry-filter__condition">
        {categories.map(
          (
            { id: categoryId, name: categoryName, icon, hasFilter },
            categoryIndex
          ) => (
            <div
              key={categoryId}
              className={`industry-filter__item t-${categories.length} ${
                activeType === categoryIndex ? "is-active" : ""
              }`}
            >
              <div
                className="u-flex industry-filter__inline t-main"
                onClick={() => this.setActiveType(categoryIndex)}
              >
                <span className="u-flex">
                  <i className={`iconfont ${icon} industry-filter__icon`} />
                  {categoryName}
                  {checkedList[categoryId] &&
                    checkedList[categoryId].length > 0 && (
                      <span className="u-ml-10 industry-filter__total">
                        {checkedList[categoryId].length}
                      </span>
                    )}
                </span>
                <i className="iconfont icon-xiangshangjiantou industry-filter__arrowdown" />
              </div>
              <div className="industry-filter__list">
                {hasFilter && (
                  <FilterInput
                    switchChecked={switchChecked}
                    categoryList={list[categoryId]}
                    categoryId={categoryId}
                  />
                )}
                {list[categoryId].map(({ id, name, checked }) => (
                  <a
                    key={id}
                    className="u-flex industry-filter__inline t-sub"
                    href="javascript:;"
                    onClick={() => switchChecked(categoryId, id, hasFilter)}
                  >
                    <span>
                      {name}
                      {/* <span className="u-ml-10 industry-filter__count">(242)</span> */}
                    </span>
                    <span
                      className={`u-flex industry-filter__checked ${
                        checked ? "is-checked" : ""
                      }`}
                    >
                      <i className="iconfont icon-true industry-filter__true" />
                    </span>
                  </a>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

FilterPannel.propTypes = {
  categories: PropTypes.array.isRequired,
  list: PropTypes.object.isRequired,
  switchChecked: PropTypes.func.isRequired,
  checkedList: PropTypes.object.isRequired,
};

export default FilterPannel;
