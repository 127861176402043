import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import FormContent from "./FormContent";
import validate from "@shared/scripts/utils/inputValidate";

class Step3 extends React.Component {
  constructor(props) {
    super(props);

    const { defaultValue } = props;
    this.formControls = {
      name: {
        value: defaultValue.full_name || "",
        label: "姓名",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      },
      mobile: {
        value: defaultValue.mobile || "",
        label: "手机号码",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
          isMobile: true,
        },
      },
      wechat: {
        value: defaultValue.wechat || "",
        label: "微信ID",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
        },
      },
      email: {
        value: defaultValue.email || "",
        label: "邮箱",
        valid: false,
        wrong: false,
        rules: {
          isRequired: true,
          isEmail: true,
        },
      },
    };

    this.state = {
      isFormValid: false,
      formData: null,
      update: false,
    };
  }

  setFormState = (isFormValid, formData) => {
    this.setState({
      isFormValid,
      formData,
    });
  };

  submit = () => {
    const { isFormValid, formData } = this.state;
    this.setState({ update: false });
    if (isFormValid) {
      this.props.submitForm(formData);
    } else {
      Object.keys(this.formControls).forEach(item => {
        this.formControls[item].valid = validate(
          this.formControls[item].value,
          this.formControls[item].rules || {}
        );
        this.formControls[item].wrong = !this.formControls[item].valid;
      });
      this.setState({ update: true });
      if (
        !Object.keys(this.formControls).some(
          item => !this.formControls[item].valid
        )
      ) {
        this.props.submitForm({ ...this.getFormData(this.formControls) });
      }
    }
  };

  getFormData = formControls => {
    const formData = {};

    Object.keys(formControls).forEach(key => {
      formData[key] = formControls[key].value;
    });

    return formData;
  };

  render() {
    return (
      <div
        className="insight-modal__step"
        ref={form => {
          this.$form = form;
        }}
      >
        <Header title="个人信息" desc="请填写个人信息" />

        <FormContent
          formControls={this.formControls}
          setFormState={this.setFormState}
        />

        <div className="insight-modal__footer">
          <button
            type="button"
            className="u-reset-button u-btn--black insight-modal__btn"
            onClick={this.submit}
          >
            完成
          </button>
        </div>
      </div>
    );
  }
}

Step3.propTypes = {
  submitForm: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
};

export default Step3;
