import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import * as routes from "../search/routes";

import CategoryAll from "./CategoryAll";
import CategoryArticle from "./CategoryArticle";
// import CategoryCard from './CategoryCard';
// import CategoryReport from './CategoryReport';
// import CategoryInstitution from './CategoryInstitution';
import CategorySolution from "./CategorySolution";
// import CategoryExpert from './CategoryExpert';
import CategoryUser from "./CategoryUser";
import SearchNav from "./SearchNav";

const Search = () => (
  <Router history={routes.history}>
    <div>
      <SearchNav />
      <div className="category__container search-result">
        <Switch>
          <Redirect exact from="/" to={routes.all} />
          <Route path={routes.all} component={CategoryAll} />
          <Route path={routes.article} component={CategoryArticle} />
          <Route path={routes.solution} component={CategorySolution} />
          {/* <Route path={routes.card} component={CategoryCard} />
          <Route path={routes.report} component={CategoryReport} />
          <Route path={routes.institution} component={CategoryInstitution} />
          <Route path={routes.expert} component={CategoryExpert} /> */}
          <Route path={routes.user} component={CategoryUser} />
        </Switch>
      </div>
    </div>
  </Router>
);

export default Search;
