import React from "react";
import PropTypes from "prop-types";
import arrowImg from "dropdown-arrow.png";

class CustomizeSelect extends React.Component {
  componentDidMount() {
    window.addEventListener(
      "click",
      e => {
        if (
          !this.refs.selectInput.contains(e.target) &&
          !this.refs.selectInputIcon.contains(e.target)
        ) {
          this.setState({
            iFocus: false,
          });
        }
      },
      false
    );
  }

  state = {
    iFocus: false,
    value: "",
  };

  onChange(v) {
    this.props.onChange(v);
  }

  render() {
    const {
      type,
      placeholder,
      valid,
      msg,
      name,
      disabled,
      options,
    } = this.props;
    const { iFocus, value } = this.state;
    const dataArr = disabled ? { disabled: true } : {};

    return (
      <div className={`u-input__container ${valid ? "" : "is-invalid"}`}>
        <input
          name={name}
          ref="selectInput"
          className={`u-input__input u-input__select ${
            value === "" ? "" : "has-value"
          } ${disabled ? "is-disabled" : ""}`}
          readOnly
          value={value}
          type={type}
          onClick={() =>
            this.setState({
              iFocus: !iFocus,
            })
          }
          autoComplete="off"
          {...dataArr}
        />
        <img
          ref="selectInputIcon"
          className="arrow-img"
          src={arrowImg}
          alt="arrowImg"
          onClick={() =>
            this.setState({
              iFocus: !iFocus,
            })
          }
        />
        <span className="u-input__label">{placeholder}</span>
        <p className="u-input__tip">{msg}</p>
        {iFocus && (
          <div className="u-input__select-panel">
            {options.map((item, index) => (
              <div
                className={`u-input__select-panel-item ${value === item.value &&
                  "u-input__select-panel-item--checked"}`}
                key={index}
                onClick={() => {
                  this.setState({
                    value: item.value,
                  });
                  this.onChange(item.value);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

CustomizeSelect.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  msg: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.any,
};

CustomizeSelect.defaultProps = {
  name: "",
  type: "text",
  placeholder: "",
  valid: true,
  value: "",
  msg: "",
  disabled: false,
  onChange: () => {},
  options: [],
};

export default CustomizeSelect;
