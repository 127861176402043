import React from "react";
import PropTypes from "prop-types";

class DcoumentItemWithLeftcover extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const {
      path,
      cover_image_url,
      title,
      published_at,
      description,
    } = this.props;

    return (
      <a className="insight-document__item" href={path}>
        <div className="u-image-base insight-document__image">
          <img alt={title} className="u-image-center" src={cover_image_url} />
        </div>
        <div className="insight-document__left">
          <div className="insight-item__title">{title}</div>
          <p className="insight-item__summary u-text-limit--two">
            {description}
          </p>
          <time
            className="insight-item__time insight-document__time js-time-ago"
            dateTime={published_at}
          >
            {$.timeago(published_at)}
          </time>
        </div>
      </a>
    );
  }
}

DcoumentItemWithLeftcover.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
  published_at: PropTypes.string.isRequired,
  description: PropTypes.string,
};

DcoumentItemWithLeftcover.defaultProps = {
  cover_image_url: "",
  description: "",
};

export default DcoumentItemWithLeftcover;
