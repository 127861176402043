import React from "react";
import PropTypes from "prop-types";

import GraphHeader from "./GraphHeader";
import AboutTechnologies from "../../graph/AboutTechnologies";
import ImageContainer from "../../ImageContainer";

const Institution = ({
  id,
  name,
  summary,
  website,
  logo_url,
  technologies,
  completeness,
}) => {
  return (
    <div className="search-panel__graph">
      <div className="u-flex">
        <div>
          <GraphHeader
            id={id}
            type="机构"
            name={name}
            summary={summary}
            path={`/graph/institutions/${id}`}
            completeness={completeness}
          />
          {website && (
            <a
              className="tips-card__title tips-card__link"
              href={website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="iconfont icon-link2 u-ml-10" />
              {website}
            </a>
          )}
        </div>
        <ImageContainer
          url={logo_url}
          name={name}
          className="tips-card__logo"
          theme="t-logo"
        />
      </div>
      <AboutTechnologies technologies={technologies} />
    </div>
  );
};

Institution.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  website: PropTypes.string,
  logo_url: PropTypes.string,
  technologies: PropTypes.array,
  completeness: PropTypes.string,
};

Institution.defaultProps = {
  summary: "",
  website: "",
  logo_url: "",
  technologies: [],
  completeness: "level2",
};

export default Institution;
