import React from "react";
import PropTypes from "prop-types";

class ArticleItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const {
      path,
      title,
      cover_image_url,
      insight_name,
      published_at,
    } = this.props;

    return (
      <article className="insight-item__half">
        <a
          className="insight-item__inline insight-article__inline"
          href={path}
          target="_blank"
        >
          <div className="u-image-base insight-article__cover">
            <img alt={title} className="u-image-center" src={cover_image_url} />
          </div>
          <div className="insight-item__title">{title}</div>
          <div className="u-flex insight-article__footer">
            <div className="insight-article__category">{insight_name}</div>
            <time
              className="insight-item__time u-text-thin"
              dateTime={published_at}
            >
              {$.timeago(published_at)}
            </time>
          </div>
        </a>
      </article>
    );
  }
}

ArticleItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
  insight_name: PropTypes.string,
  published_at: PropTypes.string.isRequired,
};

ArticleItem.defaultProps = {
  cover_image_url: "",
  description: "",
  insight_name: "",
};

export default ArticleItem;
