/* eslint-disable react/prefer-stateless-function */
import React from "react";

class EventItem extends React.PureComponent {
  render() {
    return (
      <div className="search-result__item">
        <div className="search-result__title">
          <span className="u-btn--red search-result__status">报名中</span>
          INTERFACE#4：从技术到产品，搜狗为我们解读了神经机器翻译的现状
        </div>
        <span className="u-mute-link">
          <span className="search-result__type">活动</span>
          &nbsp; · &nbsp;2018/12/23 14:00-17:00
        </span>
      </div>
    );
  }
}

export default EventItem;
