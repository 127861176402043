import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "react-select/dist/react-select.css";

class MultiSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  handleOnChange(value) {
    this.setState({ value });
  }

  render() {
    const { multi, name, placeholder, options, creatable } = this.props;
    const ReactSelect = creatable ? Select.Creatable : Select;
    return (
      <ReactSelect
        name={name}
        multi={multi}
        value={this.state.value}
        options={options}
        placeholder={placeholder}
        onChange={val => {
          this.handleOnChange(val);
        }}
      />
    );
  }
}

MultiSelector.propTypes = {
  creatable: PropTypes.bool,
  multi: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
};

MultiSelector.defaultProps = {
  creatable: true,
  multi: true,
  name: "",
  placeholder: "",
  options: [],
  value: [],
};

export default MultiSelector;
