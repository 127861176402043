import React from "react";
import PropTypes from "prop-types";

const ArticleItemLoading = props => {
  return (
    <div className={`u-loading ${props.isFetchMore ? "is-show" : ""}`}>
      <div className="u-has-bg article-item__container article-item__loading" />
    </div>
  );
};

ArticleItemLoading.propTypes = {
  isFetchMore: PropTypes.bool,
};

ArticleItemLoading.defaultProps = {
  isFetchMore: false,
};

export default ArticleItemLoading;
