import React from "react";
import PropTypes from "prop-types";
import CollectionHeader from "./CollectionHeader";

const CollectionItem = ({ id, name, onClick }) => (
  <div className="u-flex collection-modal__item" onClick={() => onClick(id)}>
    <span>{name}</span>
    <i className="iconfont icon-plus u-plus-base collection-modal__plus" />
  </div>
);

CollectionItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CollectionList = ({
  onCloseClick,
  list,
  onCreateCollectionClick,
  onCollectionItemClick,
}) => {
  return (
    <div className="collection-modal">
      <CollectionHeader title="添加到精选集" onCloseClick={onCloseClick} />
      <div className="collection-modal__content">
        <p className="collection-modal__tip">常用精选集</p>
        <div
          className="u-flex collection-modal__item"
          onClick={onCollectionItemClick}
        >
          <span>科技</span>
          <i className="iconfont icon-plus u-plus-base collection-modal__plus" />
        </div>
        <div
          className="u-flex collection-modal__item"
          onClick={onCollectionItemClick}
        >
          <span>科技</span>
          <i className="iconfont icon-plus u-plus-base collection-modal__plus" />
        </div>
        <div className="collection-modal__hr" />
        <p className="collection-modal__tip">全部精选集</p>
        {list.map(x => (
          <CollectionItem {...x} key={x.id} onClick={onCollectionItemClick} />
        ))}
      </div>
      <div className="collection-modal__footer">
        <a
          className="collection-modal__create"
          href="javscript:;"
          alt="创建精选集"
          onClick={onCreateCollectionClick}
        >
          <i className="iconfont icon-plus u-plus-base collection-modal__creation" />
          创建精选集
        </a>
      </div>
    </div>
  );
};

CollectionList.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  onCreateCollectionClick: PropTypes.func.isRequired,
  onCollectionItemClick: PropTypes.func.isRequired,
};

export default CollectionList;
