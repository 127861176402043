import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import { cancelBind } from "@shared/scripts/features/api";

class ConfirmModal extends React.Component {
  confirm = () => {
    const { type, actions, fetchDate } = this.props;
    cancelBind(type).done(res => {
      if (res.success) {
        fetchDate();
        Salert.success("取消绑定成功");
        actions.closeModal();
      }
    });
  };

  render() {
    const { closeModal } = this.props.actions;
    return (
      <div className="u-confirm__modal">
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
        <p className="forget__resend-tip">确认解除绑定？</p>
        <div className="u-confirm__container">
          <button
            className="u-btn--black signin-form__submit"
            type="button"
            onClick={this.confirm}
          >
            确认
          </button>
          <button
            className="u-btn--gray signin-form__submit"
            type="button"
            onClick={closeModal}
          >
            取消
          </button>
        </div>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  actions: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fetchDate: PropTypes.func.isRequired,
};

export default ConfirmModal;
