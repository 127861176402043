import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import "react-select/dist/react-select.css";

class SelectorOption extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    isFocused: PropTypes.bool.isRequired,
    onFocus: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
  };

  handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelect(this.props.option, e);
  };

  handleMouseEnter = e => {
    this.props.onFocus(this.props.option, e);
  };

  handleMouseMove = e => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, e);
  };

  render() {
    const { className, option, children } = this.props;

    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={option.name}
      >
        <div className="u-select">
          <a
            href="javascript:;"
            className="u-select__user"
            style={{ backgroundImage: `url(${option.avatar_url})` }}
          />
          {children}
        </div>
      </div>
    );
  }
}

class AuthorSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  onChange(value) {
    this.setState({
      value,
    });
  }

  getData = keyword => {
    if (!keyword) {
      return Promise.resolve({ options: [] });
    }

    const fetch_url = `/admin/users?q[name_or_username_or_email_or_mobile_or_pinyin_or_pinyin_abbr_cont]=${keyword}`;
    return fetch(fetch_url, {
      method: "GET",
      credentials: "include",
    })
      .then(response => response.json())
      .then(res => {
        return { options: res.users };
      });
  };

  render() {
    const { multi, name } = this.props;

    const { value } = this.state;
    const Selector = Select.Async;

    return (
      <div>
        <Selector
          multi={multi}
          value={value}
          placeholder="支持中文/拼音搜索"
          onChange={val => {
            this.onChange(val);
          }}
          valueKey="id"
          labelKey="name"
          filterOptions={false}
          loadOptions={keyword => {
            return this.getData(keyword);
          }}
          name={name}
          noResultsText="无匹配项"
          optionComponent={SelectorOption}
        />
      </div>
    );
  }
}

AuthorSelector.propTypes = {
  multi: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
};

AuthorSelector.defaultProps = {
  multi: true,
  name: "",
};

export default AuthorSelector;
