import React, { Fragment } from "react";
import PropTypes from "prop-types";

import EmailForm from "./EmailForm";
import MobileForm from "./MobileForm";
import VerificationForm from "./VerificationForm";

const MOBILE = 0;
const EMAIL = 1;

class ChooseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: props.activeIndex,
      checkedType: props.checkedType,
    };
  }

  componentDidMount() {
    // only one way verification, show 2 step not 1 step
    const { activeIndex, sendVerifyCode, checkedType } = this.props;
    if (activeIndex === 1) {
      sendVerifyCode(checkedType);
    }
  }

  switchIndex(index) {
    this.setState({
      activeIndex: index,
    });
  }

  switchChecked(index) {
    if (index === this.state.checked) return;
    this.setState({
      checkedType: index,
    });
  }

  render() {
    const { activeIndex, checkedType } = this.state;
    const {
      type,
      user,
      actions: { closeModal },
      sendVerifyCode,
      phoneVerifyCode,
      fetchDate,
    } = this.props;

    return (
      <div className="user-set__modal">
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
        <div className="forget__container">
          {activeIndex === 0 && (
            <Fragment>
              <p className="forget__resend-tip">请选择验证方式</p>
              <div className="user-set__options">
                <a
                  className={`user-set__option ${
                    checkedType === MOBILE ? "is-active" : ""
                  }`}
                  href="javascript:;"
                  alt="手机验证"
                  onClick={() => this.switchChecked(MOBILE)}
                >
                  <i className="iconfont icon-shouji1" />
                  <span>手机验证</span>
                </a>
                <a
                  className={`user-set__option ${
                    checkedType === EMAIL ? "is-active" : ""
                  }`}
                  href="javascript:;"
                  alt="邮箱验证"
                  onClick={() => this.switchChecked(EMAIL)}
                >
                  <i className="iconfont icon-youxiang" />
                  <span>邮箱验证</span>
                </a>
              </div>
              <button
                className="u-btn--black signin-form__submit"
                type="button"
                onClick={() => {
                  this.switchIndex(1);
                  sendVerifyCode(checkedType);
                }}
              >
                下一步
              </button>
            </Fragment>
          )}
          {/* 下一步后 永远是1没有2 */}
          {activeIndex === 1 && (
            <VerificationForm
              checkedType={checkedType}
              user={user}
              fetchDate={fetchDate}
              nextStep={() => {
                this.switchIndex(2);
              }}
              sendVerifyCode={cb => sendVerifyCode(checkedType, cb)}
            />
          )}
          {activeIndex === 2 &&
            (type === "mobile" ? (
              <MobileForm
                closeModal={closeModal}
                user={user}
                fetchDate={fetchDate}
                phoneVerifyCode={phoneVerifyCode}
              />
            ) : (
              <EmailForm
                user={user}
                fetchDate={fetchDate}
                closeModal={closeModal}
              />
            ))}
        </div>
      </div>
    );
  }
}

ChooseModal.propTypes = {
  actions: PropTypes.object.isRequired,
  sendVerifyCode: PropTypes.func.isRequired,
  phoneVerifyCode: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fetchDate: PropTypes.func.isRequired,
  type: PropTypes.string,
  activeIndex: PropTypes.number,
  checkedType: PropTypes.number,
};

ChooseModal.defaultProps = {
  type: "mobile",
  activeIndex: 0,
  checkedType: MOBILE,
};

export default ChooseModal;
