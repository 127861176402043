import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import ARTICLES_QUERY from "@graphql/userArticles.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import UserMomentLoading from "../loading/UserMomentLoading";
import MomentArticle from "./MomentArticle";
import MMomentArticle from "./MMomentArticle";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(ARTICLES_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      id: props.user.id,
    },
  }),
})
@WaterFallWrapper(["user", "articles"], UserMomentLoading)
class UserArticle extends React.Component {
  Item = this.props.isMobile ? MMomentArticle : MomentArticle;

  render() {
    const { edges, user } = this.props;
    const Article = this.Item;
    return edges.map(edge => (
      <Article
        key={edge.node.id}
        article={edge.node}
        user={user}
        createdAt={edge.node.published_at}
      />
    ));
  }
}

UserArticle.propTypes = {
  edges: PropTypes.array,
  user: PropTypes.object,
  isMobile: PropTypes.bool,
};

UserArticle.defaultProps = {
  edges: [],
  user: {},
  isMobile: false,
};

export default ApolloWrapper(UserArticle);
