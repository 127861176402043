import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import {
  mobile_rule,
  vercode_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";
import CountDownWrapper from "../../utils/CountDownWrapper";

class MobileForm extends React.Component {
  sendCode = () => {
    if (this.props.countDownNum > 0) return;

    const { mobile } = this.props.data;
    if (mobile.value === "" || !mobile.valid) return;

    const { countdown, phoneVerifyCode } = this.props;
    phoneVerifyCode(mobile.value, () => {
      countdown();
    });
  };

  bindMobile = () => {
    if (!this.props.isFormValidate()) return;

    const { closeModal, fetchDate } = this.props;
    const $form = $(this.form);
    $form.ajaxSubmit({
      url: "/settings/mobile",
      type: "PATCH",
      dataType: "json",
      success: () => {
        Salert.success("绑定成功");
        fetchDate();
        closeModal();
      },
    });
  };

  render() {
    const {
      data: { mobile, code },
      changeValue,
      countDownNum,
    } = this.props;

    return (
      <div className="user-set__form">
        <p className="forget__resend-tip">更改手机号码</p>
        <form
          ref={ele => {
            this.form = ele;
          }}
        >
          <CustomizeInput
            {...mobile}
            onChange={value => {
              changeValue(value, "mobile");
            }}
          />
          <div className="u-pic-code">
            <CustomizeInput
              {...code}
              onChange={value => {
                changeValue(value, "code");
              }}
            />
            <button
              type="button"
              className={`u-reset-button u-input__btn ${
                countDownNum === 0 ? "" : "is-disabled"
              }`}
              onClick={this.sendCode}
              alt="获取验证码"
            >
              {countDownNum === 0 ? "获取验证码" : `重新发送(${countDownNum})`}
            </button>
          </div>
        </form>
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={this.bindMobile}
        >
          绑定
        </button>
      </div>
    );
  }
}

MobileForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  phoneVerifyCode: PropTypes.func.isRequired,
  fetchDate: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
  countDownNum: PropTypes.number.isRequired,
  countdown: PropTypes.func.isRequired,
};

export default FormWrapper({
  mobile: buildRules("user[mobile]", "手机号", [mobile_rule]),
  code: buildRules("user[phone_verify_code]", "验证码", [vercode_rule]),
})(CountDownWrapper()(MobileForm));
