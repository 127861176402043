import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import CollectionHeader from "./CollectionHeader";

class AddCollection extends React.Component {
  state = {
    activeIcon: "",
    name: "foo",
  };

  componentDidMount() {
    this.$name.focus();
  }

  onNameInputChange = () => {
    this.setState({ name: this.$name.value });
  };

  getData = () => {
    return {
      name: this.state.name,
      icon: this.state.activeIcon,
      id: _.uniqueId(),
    };
  };

  changeIcon = name => {
    this.setState({ activeIcon: name });
  };

  render() {
    const { activeIcon } = this.state;

    return (
      <div className="collection-modal">
        <CollectionHeader
          title="创建精选集"
          onCloseClick={this.props.onCloseClick}
        />
        <div className="collection-modal__content">
          <input
            className="u-reset-input collection-modal__input"
            type="text"
            name="name"
            placeholder="精选集名字"
            ref={node => {
              this.$name = node;
            }}
            value={this.state.name}
            onChange={this.onNameInputChange}
          />
          <p className="collection-modal__tip">选择展示图标</p>
          <div>
            <span
              className={`iconfont icon-plus u-plus-base search-chosen__circle collection-modal__icon ${
                activeIcon === "foo" ? "is-active" : ""
              }`}
              onClick={() => this.changeIcon("foo")}
            />
            <span
              className={`iconfont icon-plus u-plus-base search-chosen__circle collection-modal__icon ${
                activeIcon === "bar" ? "is-active" : ""
              }`}
              onClick={() => this.changeIcon("bar")}
            />
          </div>
        </div>
        <div className="collection-modal__footer collection-modal__btns">
          <a
            className="u-btn--red collection-modal__btn"
            href="javscript:;"
            alt="添加"
            onClick={() => this.props.onSave(this.getData())}
          >
            添加
          </a>
          <a
            className="u-btn--gray collection-modal__btn"
            href="javscript:;"
            alt="取消"
            onClick={this.props.onCloseClick}
          >
            取消
          </a>
        </div>
      </div>
    );
  }
}

AddCollection.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddCollection;
