import React from "react";
// import PropTypes from 'prop-types';
import PropTypes from "prop-types";

class AddKeywords extends React.Component {
  state = {
    value: "",
    isFocus: false,
  };

  toggleFocus = status => {
    this.setState({
      isFocus: status,
    });
  };

  changeKeywords = () => {
    this.props.setKeyword(this.state.value);
  };

  keydownHandle = event => {
    if (event.which === 13) {
      this.changeKeywords();
    }
  };

  clearValue = () => {
    this.changeValue("");
  };

  changeValue = value => {
    this.setState({
      value,
    });
  };

  render() {
    const { value, isFocus } = this.state;

    return (
      <div className="industry-search">
        <div
          className={`u-flex industry-search__container t-gray ${
            isFocus ? "is-focus" : ""
          }`}
        >
          <input
            ref="searchInput"
            className="industry-search__input"
            type="text"
            onFocus={() => this.toggleFocus(true)}
            onBlur={() => this.toggleFocus(false)}
            placeholder={`搜索${this.props.typeName}`}
            value={value}
            onKeyDown={this.keydownHandle}
            onChange={e => {
              this.changeValue(e.target.value);
            }}
          />
          {value === "" ? (
            <i
              className="iconfont icon-sreach industry-search__plus"
              onMouseDown={this.changeKeywords}
            />
          ) : (
            <i
              className="iconfont icon-iconguanbi industry-search__plus"
              onMouseDown={this.clearValue}
            />
          )}
        </div>
      </div>
    );
  }
}

AddKeywords.propTypes = {
  setKeyword: PropTypes.func.isRequired,
  typeName: PropTypes.string.isRequired,
};

export default AddKeywords;
