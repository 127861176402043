import React from "react";
import PropTypes from "prop-types";

import InstitutionItem from "./items/InstitutionItem";
import ArticleItem from "./items/ArticleItem";
import SolutionItem from "./items/SolutionItem";
import UserItem from "./items/UserItem";

const theme = {
  institutions: () => InstitutionItem,
  articles: () => ArticleItem,
  technologies: () => InstitutionItem,
  experts: () => InstitutionItem,
  papers: () => ArticleItem,
  solutions: () => SolutionItem,
  users: () => UserItem,
};

class CategoryList extends React.Component {
  Item = theme[this.props.params]();

  render() {
    const { edges, params } = this.props;
    const { Item } = this;

    return edges.map(edge => <Item key={edge.id} {...edge} type={params} />);
  }
}

CategoryList.propTypes = {
  edges: PropTypes.array,
  params: PropTypes.string,
};

CategoryList.defaultProps = {
  edges: [],
  params: "articles",
};

export default CategoryList;
