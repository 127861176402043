/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import Salert from "react-s-alert";
import { createComment, deleteTarget, getCommentReplies } from "./api";

const replyHTML = (currentUser, replyNickname) => {
  return `
    <div class="comment-inline comment-open js-reply-container">
      <div class="u-flex-center comment-inline__header">
        <div class="u-avatar-base comment-inline__avatar">
          <image class="u-image-center" src="${currentUser.avatarUrl}" />
        </div>
        <div class="comment-inline__user">
          <span class="comment-inline__from">${currentUser.nickname}</span>
          <span class="comment-inline__time">回复</span>
          <span class="comment-inline__from">${replyNickname}</span>
        </div>
      </div>
      <div class="comment-editor__body t-reply">
        <textarea class="comment-editor__area js-reply-textarea" placeholder="请输入内容"></textarea>
        <div class="comment-editor__footer">
          <button class="u-reset-button u-btn-trans comment-editor__btn js-close-reply" type="button">取消</button>
          <button class="u-btn--border comment-editor__btn js-reply-comment" type="button">发布</button>
        </div>
      </div>
    </div>
  `;
};

const removeReplyContainer = () => {
  $(".js-reply-container").remove();
};

const isLogin = () => {
  return $(".js-comment-disabled").length === 0;
};

export default (isMobile = false) => {
  const $comment = $("#comment");
  const $editor = $(".js-comment-editor");
  const $textarea = $(".js-comment-textarea");
  const $commentSubmitBtn = $(".js-comment-submit");
  const $commentList = $(".js-comment-list");

  const url = $comment.data("url");
  const currentUser = {
    id: $comment.data("user-id"),
    avatarUrl: $comment.data("user-avatar"),
    nickname: $comment.data("user-name"),
  };

  $(".js-time-create").timeago();

  // editor
  $editor.on("click", () => {
    $editor.addClass("comment-open");
    $textarea.focus();
  });

  $textarea.on("blur", () => {
    if ($textarea.val() !== "") return;

    $editor.removeClass("comment-open");
  });

  // more comments
  $(".js-comment-more").on("click", function() {
    const $this = $(this);
    const id = $this.data("id");

    getCommentReplies(id).done(res => {
      $(this)
        .before(res)
        .remove();
    });
  });

  const alertSuccess = msg => {
    if (isMobile) return alert(msg);

    return Salert.success(msg);
  };

  let isSubmit = false;
  const createCommentAction = (targetUrl, content, cb) => {
    if (isSubmit) return;
    if (content === "") return;

    createComment(targetUrl, content)
      .done(res => {
        alertSuccess("评论将在审核通过后发布");
        cb(res);
      })
      .fail(xhr => {
        Salert.error(xhr);
      })
      .always(() => {
        isSubmit = false;
      });
  };

  // submit comment
  $commentSubmitBtn.on("mousedown", event => {
    event.preventDefault();

    createCommentAction(`${url}/comments`, $textarea.val(), res => {
      $textarea.val("");
      $editor.removeClass("comment-open");
      const $commentNone = $(".js-comment-none");
      if ($commentNone.length === 1) $commentNone.remove();
      $commentList.before(`<div class="comment__item">${res}</div>`);
    });
  });

  // delete comment and reply comment
  $comment.on("click", ".js-comment-delete, .js-comment-reply", event => {
    const $currentTarget = $(event.currentTarget);
    const type = $currentTarget.data("type");
    const $target = $currentTarget.parent().parent();

    const id = $target.data("id");
    if (type === "delete") {
      const del = confirm("是否删除此评论");
      if (!del) return;

      deleteTarget(`/comments/${id}`).done(() => {
        alertSuccess("删除成功");
        $target.remove();
      });
      return;
    }

    if (type === "reply") {
      if (!isLogin()) {
        $currentTarget.next().click();
        return;
      }

      removeReplyContainer();
      const replyNickname = $target.data("user");
      $target.after(replyHTML(currentUser, replyNickname));
      const $replyText = $(".js-reply-textarea");
      $replyText.focus();

      $(".js-close-reply").on("click", removeReplyContainer);
      $replyText.on("blur", () => {
        if ($replyText.val() !== "") return;

        removeReplyContainer();
      });

      $(".js-reply-comment").on("mousedown", e => {
        e.preventDefault();

        createCommentAction(
          `/comments/${id}/reply`,
          $(".js-reply-textarea").val(),
          res => {
            removeReplyContainer();
            $target.after(res);
          }
        );
      });
    }
  });
};
