import React, { Component } from "react";
import PropTypes from "prop-types";

import searchSelectors from "@shared/scripts/selectors/search";
import ReduxWrapper from "../utils/ReduxWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";

import FetchDataWrapper from "./FetchDataWrapper";
import SearchFilterList from "./SearchFilterList";
import Expert from "./card/Expert";
import Technology from "./card/Technology";
import Institution from "./card/Institution";
import InstitutionInAll from "./items/InstitutionInAll";
import CategoryListInAll from "./CategoryListInAll";

@FetchDataWrapper
@LoadingWrapper
class CategoryAll extends Component {
  render() {
    const {
      tagList,
      published,
      tags,
      isExactMatch,
      searchInternet,
      sortMode,
      isReFetch,
      actions,
      edges,
      firstPageData,
      keywords,
      hasSearch,
      ...otherProps
    } = this.props;
    const { card_node: cardNode, institutions } = firstPageData;
    const hasCard = cardNode !== undefined;

    return (
      <div className="u-container u-clearfix page-search__container">
        <div className="u-col-9 u-min-item">
          <CategoryListInAll
            searchInternet={searchInternet}
            firstPageData={firstPageData}
            keywords={keywords}
            edges={edges}
            {...otherProps}
            data={{ loading: isReFetch }}
          />
        </div>
        <div key="all_right" className="u-col-3 has-gutter page-search__right">
          <SearchFilterList
            disabled={false}
            tagList={tagList}
            published={published}
            tags={tags}
            isExactMatch={isExactMatch}
            searchInternet={searchInternet}
            sortMode={sortMode}
            hasSearch={hasSearch}
            actions={actions}
          />
          {hasCard && cardNode.technology && (
            <Technology {...cardNode.technology} />
          )}
          {hasCard && cardNode.expert && <Expert {...cardNode.expert} />}
          {hasCard && cardNode.institution && (
            <Institution {...cardNode.institution} />
          )}
          <InstitutionInAll
            institutions={institutions.nodes}
            keywords={keywords}
          />
        </div>
      </div>
    );
  }
}

CategoryAll.propTypes = {
  actions: PropTypes.object.isRequired,
  published: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  isExactMatch: PropTypes.bool.isRequired,
  searchInternet: PropTypes.bool.isRequired,
  sortMode: PropTypes.string.isRequired,
  isReFetch: PropTypes.bool,
  tagList: PropTypes.array,
  params: PropTypes.string,
  isCategoryAll: PropTypes.bool,
  edges: PropTypes.array,
  firstPageData: PropTypes.object,
  keywords: PropTypes.string,
  hasSearch: PropTypes.bool,
};

CategoryAll.defaultProps = {
  isReFetch: true,
  tagList: [],
  params: "articles",
  isCategoryAll: true,
  edges: [],
  firstPageData: {},
  keywords: "",
  hasSearch: false,
};

const mapStateToProps = state => {
  const {
    publishedTime,
    tags,
    isExactMatch,
    searchInternet,
    sortMode,
  } = searchSelectors.getSearchFilter(state);
  return {
    published: publishedTime,
    tags,
    isExactMatch,
    searchInternet,
    sortMode,
    hasSearch: searchSelectors.hasSearch(state),
  };
};

export default ReduxWrapper(CategoryAll, mapStateToProps);
