import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import NoData from "../utils/NoData";
import LoadingWrapper from "../utils/LoadingWrapper";
import HasDataWrapper from "../utils/HasDataWrapper";
import FetchDataWrapper from "./FetchDataWrapper";

import CategoryAllFirstPage from "./CategoryAllFirstPage";
import CategoryList from "./CategoryList";

@FetchDataWrapper("articles", true)
@LoadingWrapper
@HasDataWrapper(true)
class CategoryAll extends Component {
  render() {
    const { edges, firstPageData, keywords } = this.props;
    const hasData =
      firstPageData.articles.nodes.length === 0 &&
      firstPageData.solutions.nodes.length === 0 &&
      firstPageData.users.nodes.length === 0;
    if (hasData) return <NoData />;

    return (
      <Fragment>
        <CategoryAllFirstPage data={firstPageData} keywords={keywords} />
        {edges.length > 0 && <CategoryList params="articles" edges={edges} />}
      </Fragment>
    );
  }
}

CategoryAll.propTypes = {
  edges: PropTypes.array,
  firstPageData: PropTypes.object,
  keywords: PropTypes.string,
};

CategoryAll.defaultProps = {
  edges: [],
  firstPageData: {},
  keywords: "",
};

export default CategoryAll;
