import React from "react";
import PropTypes from "prop-types";
import { scrollBarWith } from "@shared/scripts/utils/tool";
import ReduxWrapper from "./ReduxWrapper";
import EffectSwitch from "./EffectSwitch";

import CollectionShowModal from "../collection/CollectionShowModal";
import CollectionModal from "../collection/CollectionModal";
import ConfirmModal from "../setting/set/ConfirmModal";
import ChooseModal from "../setting/set/ChooseModal";
import MobileModal from "../setting/set/MobileModal";
import EmailModal from "../setting/set/EmailModal";
import PasswordModal from "../setting/set/PasswordModal";
import SignModal from "../signin/SignModal";
import CropImage from "./CropImage";
import MyColumnModal from "../shared/header/MyColumnModal";
import RucaptchaModal from "../user/forget/RucaptchaModal";
import DocumentModal from "../document/DocumentModal";
import InsightModal from "../insight/InsightModal";
import InsightTipModal from "../insight/InsightTipModal";
import ChargeModal from "../charge/ChargeModal";
import SotaSignModal from "../sota_signin/SignModal";

const modalThemes = {
  collectionShow: () => CollectionShowModal,
  collectionAdd: () => CollectionModal,
  myConfirm: () => ConfirmModal,
  choose: () => ChooseModal,
  mobile: () => MobileModal,
  email: () => EmailModal,
  password: () => PasswordModal,
  signin: () => SignModal,
  sota_signin: () => SotaSignModal,
  cropImage: () => CropImage,
  myColumn: () => MyColumnModal,
  rucaptcha: () => RucaptchaModal,
  document: () => DocumentModal,
  insight: () => InsightModal,
  insightTip: () => InsightTipModal,
  charge: () => ChargeModal,
  default: () => null,
};

class Modal extends React.Component {
  componentWillUpdate(nextProps) {
    if (nextProps.modal.type === "hide") {
      $("body")
        .removeClass("open-modal-layer")
        .attr("style", "");
    } else {
      $("body")
        .css("padding-right", `${scrollBarWith()}px`)
        .addClass("open-modal-layer");
    }
  }

  render() {
    const { modal, actions } = this.props;
    const { modalProps } = modal;
    const Inner = modalThemes[modal.modalType]();

    return (
      <div className="modal-layer">
        <EffectSwitch effect={modal.effect} condition={modal.type === "show"}>
          <div
            className={`modal-layer__container ${modalProps &&
              modalProps.modalClassName}`}
          >
            {Inner && <Inner {...modalProps} actions={actions} />}
          </div>
        </EffectSwitch>
        <EffectSwitch condition={modal.type === "show"}>
          <div className="modal-layer__mask" onClick={actions.closeModal} />
        </EffectSwitch>
      </div>
    );
  }
}

Modal.defaultProps = {
  modal: {},
  actions: {},
};

Modal.propTypes = {
  modal: PropTypes.object,
  actions: PropTypes.object,
};

export default ReduxWrapper(Modal);
