import React from "react";
import PropTypes from "prop-types";

const defaultImage = {
  file: "",
  default: 0,
  _destroy: 0,
};

class MultiUploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.images,
    };
  }

  addImage = () => {
    this.setState(prevState => ({
      images: [...prevState.images, defaultImage],
    }));
  };

  render() {
    const { images } = this.state;
    const { inputName } = this.props;

    return (
      <div className="clearfix">
        {images.map((image, index) => (
          <div key={index} className="muti-image">
            {image.id && (
              <input
                type="hidden"
                value={image.id}
                name={`${inputName}[${index}][id]`}
              />
            )}
            <div
              className="cover-image"
              style={{ backgroundImage: `url(${image.file.url})` }}
            />
            <p>
              更新
              <input type="file" name={`${inputName}[${index}][file]`} />
            </p>
            <label>
              是否删除
              <input
                name={`${inputName}[${index}][_destroy]`}
                type="hidden"
                value="0"
              />
              <input
                type="checkbox"
                value="1"
                name={`${inputName}[${index}][_destroy]`}
              />
            </label>
            <br />
            <label>
              设为默认
              <input
                name={`${inputName}[${index}][default]`}
                type="hidden"
                value="0"
              />
              <input
                type="checkbox"
                value="1"
                name={`${inputName}[${index}][default]`}
                defaultChecked={image.default}
              />
            </label>
          </div>
        ))}
        <a
          herf="javascript:;"
          className="btn btn-goldenline"
          onClick={this.addImage}
        >
          添加图片
        </a>
      </div>
    );
  }
}

MultiUploadImage.propTypes = {
  images: PropTypes.array,
  inputName: PropTypes.string,
};

MultiUploadImage.defaultProps = {
  images: [],
  inputName: "",
};

export default MultiUploadImage;
