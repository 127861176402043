/* eslint-disable import/prefer-default-export */

const initialState = {
  info: null,
};

export const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, info: action.payload };
    default:
      return state;
  }
};
