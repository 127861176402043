import React from "react";
import PropTypes from "prop-types";

import { waterFall } from "@shared/scripts/utils/tool";
import NoData from "./NoData";
import FetchMoreBtn from "./FetchMoreBtn";

const HasDataWrapper = (ignoreNoData = false, maxCount = 1) => Component => {
  class HasDataInnerWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.waterFallCount = 0;
      this.hasWaterFall = ignoreNoData || props.edges.length > 0;
    }

    componentDidMount() {
      if (!this.hasWaterFall) return;

      if (this.$wrapper) {
        waterFall($(this.$wrapper), () => {
          const { isFetchMore, hasNextPage } = this.props;
          if (isFetchMore || this.waterFallCount > maxCount || !hasNextPage) {
            return;
          }

          this.props.fetchData();
          this.waterFallCount += 1;
        });
      }
    }

    render() {
      if (!this.hasWaterFall || this.props.edges.length === 0) {
        return <NoData />;
      }

      const {
        isFetchMore,
        hasNextPage,
        LoadingComponent,
        FetchBtn,
        fetchData,
        className,
        OtherComponent,
        ...other
      } = this.props;
      return (
        <div
          className={className}
          ref={wrapper => {
            this.$wrapper = wrapper;
          }}
        >
          <Component {...other} isFetchMore={isFetchMore} />
          {LoadingComponent && <LoadingComponent isFetchMore={isFetchMore} />}
          {hasNextPage && (
            <FetchBtn isFetchMore={isFetchMore} action={() => fetchData()} />
          )}
          {OtherComponent && <OtherComponent />}
        </div>
      );
    }
  }

  HasDataInnerWrapper.propTypes = {
    edges: PropTypes.array,
    isFetchMore: PropTypes.bool,
    fetchData: PropTypes.func,
    hasNextPage: PropTypes.bool,
    LoadingComponent: PropTypes.any,
    FetchBtn: PropTypes.any,
    className: PropTypes.string,
    OtherComponent: PropTypes.any,
  };

  HasDataInnerWrapper.defaultProps = {
    edges: [],
    isFetchMore: true,
    fetchData: () => {},
    hasNextPage: false,
    LoadingComponent: null,
    OtherComponent: null,
    FetchBtn: FetchMoreBtn,
    className: "",
  };

  return HasDataInnerWrapper;
};

export default HasDataWrapper;
