import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";

import { parseData, combData } from "@shared/scripts/utils/apollo_tool";
import FetchMoreBtn from "./FetchMoreBtn";
import FetchMoreBtnWithLoading from "./FetchMoreBtnWithLoading";

const ResolveGraphqlDataWrapper = (
  params,
  LoadingComponent = null
) => Component => {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);
      this.isNotSpecialLoading = LoadingComponent === null;
      this.FetchBtn = this.isNotSpecialLoading
        ? FetchMoreBtnWithLoading
        : FetchMoreBtn;
      this.state = {
        isFetchMore: false,
      };
    }

    fetchData = (cb = () => {}) => {
      this.setState({
        isFetchMore: true,
      });

      const { fetchMore, variables } = this.props.data;
      variables.cursor = this.paramsData.pageInfo.endCursor;

      fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const propsData = parseData(params, fetchMoreResult);
          const { edges: newEdges, pageInfo } = propsData;

          return update(
            previousResult,
            combData(params, {
              edges: { $push: newEdges },
              pageInfo: { $set: pageInfo },
            })
          );
        },
      }).then(() => {
        this.setState({ isFetchMore: false });
        cb();
      });
    };

    render() {
      const { data, ...others } = this.props;
      const { isFetchMore } = this.state;

      this.paramsData = parseData(params, data);
      const {
        edges,
        pageInfo: { hasNextPage },
      } = this.paramsData;

      return (
        <Component
          {...others}
          edges={edges}
          LoadingComponent={LoadingComponent}
          hasNextPage={hasNextPage}
          FetchBtn={this.FetchBtn}
          isFetchMore={isFetchMore}
          fetchData={this.fetchData}
        />
      );
    }
  }

  Wrapper.propTypes = {
    data: PropTypes.object.isRequired,
  };

  return Wrapper;
};

export default ResolveGraphqlDataWrapper;
