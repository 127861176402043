import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import * as routes from "./routes";

// import CollectionActiveList from '../collection/CollectionActiveList';
import CategoryAll from "./CategoryAll";
import CategoryArticle from "./CategoryArticle";
// import CategoryCard from './CategoryCard';
// import CategoryReport from './CategoryReport';
// import CategoryInstitution from './CategoryInstitution';
import CategorySolution from "./CategorySolution";
// import CategoryExpert from './CategoryExpert';
import CategoryUser from "./CategoryUser";
import SearchNav from "./SearchNav";
import NoData from "../utils/NoData";

const Search = () => (
  <Router history={routes.history}>
    <div className="u-secondary-container page-search">
      <SearchNav />
      <Switch>
        <Redirect exact from="/" to={routes.all} />
        <Route path={routes.all} component={CategoryAll} />
        <Route path={routes.article} component={CategoryArticle} />
        {/* <Route path={routes.card} component={CategoryCard} />
        <Route path={routes.report} component={CategoryReport} />
        <Route path={routes.expert} component={CategoryExpert} />
        <Route path={routes.institution} component={CategoryInstitution} /> */}
        <Route path={routes.solution} component={CategorySolution} />
        <Route path={routes.user} component={CategoryUser} />
        <Route path="*" component={NoData} />
      </Switch>
      {/* <CollectionActiveList /> */}
    </div>
  </Router>
);

export default Search;
