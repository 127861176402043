/* eslint-disable no-useless-escape */
import React from "react";
import Salert from "react-s-alert";
import PropTypes from "prop-types";

import {
  mobile_rule,
  pwd_rule,
  phone_code_rule,
  nickname_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

const TIME = 60;
// eslint-disable-next-line no-unused-vars
let captcha;
const getInstance = instance => {
  captcha = instance;
};

class SignupForm extends React.Component {
  state = {
    isSend: false,
    time: TIME,
    // src: "/rucaptcha/",
  };

  componentDidMount() {
    // 只用初始化一次验证码即可
    window.initAliyunCaptcha({
      SceneId: "nepl2ukj", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "mk2426", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: this.captchaVerifyCallback.bind(this), // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: this.onBizResultCallback.bind(this), // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 320,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
  }

  componentWillUnmount() {
    // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
    document.getElementById("aliyunCaptcha-mask")?.remove();
    document.getElementById("aliyunCaptcha-window-popup")?.remove();
  }

  async captchaVerifyCallback(captchaVerifyParam) {
    let result = {};
    let error = false;
    try {
      result = await this.sendCodeCore(captchaVerifyParam);
    } catch (e) {
      error = e;
    }

    console.log("result:", result);

    if (error) {
      Salert.error(error.responseJSON.errors[0]);
      captcha.refresh();
      captcha.hide();
    } else {
      return {
        captchaResult: result.captchaResult, // 验证码验证是否通过，boolean类型，必选 result.captchaVerifyResult
        bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
    }
  }

  // 验证通过后调用
  onBizResultCallback(bizResult) {
    console.log("bizResult:", bizResult);
  }

  // safari 某些版本中 这里 直接改src state 不能正确触发 view update
  // 排查出是safari图片缓存机制的问题 加时间戳解决
  // updateRucaptcha() {
  //   this.setState({
  //     src: "/rucaptcha/?" + Date.now(),
  //   });
  // }

  sendCodeCore(captchaVerifyParam) {
    return new Promise((resolve, reject) => {
      const {
        data: { mobile },
      } = this.props;

      if (!this.state.isSend) {
        $.ajax({
          url: "/mobile_verify_code",
          method: "POST",
          datType: "json",
          headers: {
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
          data: {
            captchaVerifyParam,
            mobile: mobile.value,
          },
          success: res => {
            const timer = setInterval(() => {
              if (this.state.time === 0) {
                clearInterval(timer);
                this.setState({
                  isSend: false,
                  time: TIME,
                });
                // this.updateRucaptcha();
              }
              this.setState({
                time: this.state.time - 1,
              });
            }, 1000);
            this.setState({
              isSend: true,
            });
            resolve(res);
          },
          error: xhr => {
            console.log(xhr.status, xhr.responseJSON);
            reject(xhr);
            // this.updateRucaptcha();
          },
        });
      }
    });
  }

  verifyMobile() {
    const {
      data: { mobile },
    } = this.props;

    if (!mobile.value || !mobile.valid) {
      Salert.error("手机号不合法!");
    }
  }

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.form);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        Salert.success("注册成功");
        this.props.setSignIn(true);
        this.props.setSm(true);
        if (res && res.redirect) {
          window.location.href = res.redirect;
        } else {
          window.location.href = "/";
        }
      },
      error: xhr => {
        Salert.error(xhr.responseJSON.errors[0]);
        // this.updateRucaptcha();
      },
    });
  }

  render() {
    const {
      data: { mobile, password, name, phone_verify_code },
      changeValue,
    } = this.props;
    const { isSend, time } = this.state;

    return (
      <form
        ref="form"
        action="/signup"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...mobile}
          onChange={value => {
            changeValue(value, "mobile");
          }}
        />
        <div className="u-code">
          <CustomizeInput
            {...phone_verify_code}
            onChange={value => {
              changeValue(value, "phone_verify_code");
            }}
          />
          {(!mobile.value || !mobile.valid || isSend) && (
            <div
              className={`captcha-layer ${isSend && "captcha-layer--done"}`}
              onClick={() => this.verifyMobile()}
            />
          )}
          <div
            id="captcha-button"
            className={`send-code ${isSend && "send-code--done"}`}
          >
            {isSend ? `${time}s 重新获取` : "获取短信验证码"}
          </div>
          <div id="captcha-element" />
        </div>
        <CustomizeInput
          {...name}
          onChange={value => {
            changeValue(value, "name");
          }}
        />
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          注册
        </a>
        <p className="signin-form__tip">
          已有账号，立即
          <a
            className="signin-form__signup"
            href="javascript:;"
            alt="登录"
            onClick={this.props.switchAction}
          >
            &nbsp;登录
          </a>
        </p>
      </form>
    );
  }
}

SignupForm.propTypes = {
  setSignIn: PropTypes.func.isRequired,
  setSm: PropTypes.func.isRequired,
  switchAction: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  mobile: buildRules("user[mobile]", "手机号码", [mobile_rule]),
  password: buildRules("user[password]", "密码", [pwd_rule]),
  name: buildRules("user[name]", "昵称", [nickname_rule]),
  phone_verify_code: buildRules("user[phone_verify_code]", "验证码", [
    phone_code_rule,
  ]),
})(SignupForm);
