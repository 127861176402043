import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import COMMENTS_QUERY from "@graphql/starComments.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import UserMomentLoading from "../loading/UserMomentLoading";
import CommentNoReply from "../shared/CommentNoReply";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(COMMENTS_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      id: props.currentUserId,
    },
  }),
})
@WaterFallWrapper(["user", "star_comments"], UserMomentLoading)
class StarComments extends React.Component {
  render() {
    const { edges } = this.props;
    return edges.map(({ node }) => (
      <CommentNoReply
        key={node.id}
        comment={node}
        created_at={node.created_at}
        user={node.user}
      />
    ));
  }
}

StarComments.propTypes = {
  edges: PropTypes.array,
};

StarComments.defaultProps = {
  edges: [],
};

export default ApolloWrapper(StarComments);
