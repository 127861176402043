import React from "react";

const CountDownWrapper = (initialCount = 0) => Component => {
  class CountDownInnerWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        countDown: initialCount,
      };
      this.timeout = null;
    }

    componentDidMount() {
      if (initialCount > 0) {
        this.changeCount();
      }
    }

    componentWillUnmount() {
      this.clearTimer();
    }

    countdown = () => {
      this.setState(
        {
          countDown: 60,
        },
        () => {
          this.changeCount();
        }
      );
    };

    changeCount = () => {
      const { countDown } = this.state;
      if (countDown === 0) {
        this.clearTimer();
      } else {
        this.setState({
          countDown: countDown - 1,
        });
        this.timeout = setTimeout(() => {
          this.changeCount();
        }, 1000);
      }
    };

    clearTimer = () => {
      clearTimeout(this.timeout);
    };

    render() {
      return (
        <Component
          {...this.props}
          countDownNum={this.state.countDown}
          countdown={this.countdown}
        />
      );
    }
  }

  return CountDownInnerWrapper;
};

export default CountDownWrapper;
