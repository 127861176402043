import Salert from "react-s-alert";
import "jquery-form";
import form from "@shared/scripts/features/member_form";

const joinForm = () => {
  $(".js-vip-clause").on("click", () => {
    $("#js-modal").toggleClass("is-hidden");
  });

  form(
    () => {
      Salert.success("申请成功，请等待审核");
      window.location = "/setting/member";
    },
    xhr => {
      Salert.error(xhr.responseText);
    }
  );
};

export default joinForm;
