/* eslint-disable consistent-return */
const chinaCity = () => {
  $.fn.china_city = function() {
    return this.each(function() {
      const selects = $(this).find(".city-select");
      return selects.on("change", function() {
        const $this = $(this);
        const next_selects = selects.slice(selects.index(this) + 1);
        $("option:gt(0)", next_selects).remove();

        if (
          next_selects.first()[0] &&
          $this.val() &&
          !$this.val().match(/--.*--/)
        ) {
          return $.get(`/china_city/${$(this).val()}`, data => {
            if (data === null) return;

            $.each(data, (index, option) => {
              next_selects
                .first()[0]
                .options.add(new Option(option[0], option[1]));
            });

            return next_selects.trigger("china_city:load_data_completed");
          });
        }
      });
    });
  };

  $(".city-group").china_city();
};

export default chinaCity;
