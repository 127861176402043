import * as modalActions from "./modal";
import * as searchActions from "./search";
import * as sotaModalActions from "./sota_modal";

export default {
  ...modalActions,
  ...sotaModalActions,
  ...searchActions.collection,
  ...searchActions.searchFilter,
};
