import React from "react";
import PropTypes from "prop-types";

import EffectSwitch from "./EffectSwitch";
import Loading from "./LogoLoading";
import SpinnerLoading from "./SpinnerLoading";

const LoadingWrapper = Component => {
  class Wrapper extends React.PureComponent {
    static displayName = `LoadingWrapper(${Component.name})`;

    static propTypes = {
      data: PropTypes.object,
      isLoadMore: PropTypes.bool,
    };

    static defaultProps = {
      data: {},
      isLoadMore: false,
    };

    render() {
      const {
        data: { loading },
        isLoadMore,
      } = this.props;

      return (
        <div className="loading-wrapper">
          <EffectSwitch condition={!loading} effect="fade">
            <Component {...this.props} />
          </EffectSwitch>
          <EffectSwitch condition={loading} effect="fade">
            {isLoadMore ? <SpinnerLoading /> : <Loading />}
          </EffectSwitch>
        </div>
      );
    }
  }
  return Wrapper;
};

export default LoadingWrapper;
