import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import COMMENTS_QUERY from "@graphql/notificationSystem.graphql";
import { readNotification } from "@shared/scripts/features/api";
import ApolloWrapper from "../utils/ApolloWrapper";
import UserMomentLoading from "../loading/UserMomentLoading";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(COMMENTS_QUERY, {
  options: props => ({
    variables: {
      cursor: "",
      id: props.currentUserId,
    },
  }),
})
@WaterFallWrapper(["user", "systematics"], UserMomentLoading)
class NoticeSystem extends React.Component {
  read = graphqlId => {
    readNotification(graphqlId.replace(/^Notification\//, ""));
  };

  render() {
    const { edges } = this.props;

    return edges.map(edge => {
      const { id, content, created_at, read_at } = edge.node;
      return (
        <div
          className={`setting__comment ${read_at === null ? "has-read" : ""}`}
          key={id}
        >
          <div className="user-show__item">
            <div className="user-show__title user-show__line">
              <strong onClick={() => this.read(id)}>{content}</strong>
            </div>
            <div className="user-show__date is-right">
              {$.timeago(created_at)}
            </div>
          </div>
        </div>
      );
    });
  }
}

NoticeSystem.propTypes = {
  edges: PropTypes.array,
};

NoticeSystem.defaultProps = {
  edges: [],
};

export default ApolloWrapper(NoticeSystem);
