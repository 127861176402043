import React from "react";
import Salert from "react-s-alert";

import PayIcon from "insight/payIcon";
import PaySuccessIcon from "insight/paySuccessIcon";

class ChargeModal extends React.Component {
  apply() {
    const $form = $(this.refs.chargeForm);

    $form.ajaxSubmit({
      url: $form.attr("action"),
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      xhrFields: {
        withCredentials: true,
      },
      type: "POST",
      dataType: "json",
      success: res => {
        if (res.success) {
          Salert.success(res.message);
          window.location.reload();
          this.props.actions.closeModal();
        }
      },
    });

    return false;
  }

  render() {
    const { url, apply_pending } = this.props;

    return (
      <form ref="chargeForm" action={url} className="charge-form">
        <div id="pay_wall">
          {apply_pending === "true" ? (
            <div className="pay-content">
              <span className="pay-content_close">
                <i className="iconfont iconclose1" onClick={close} />
              </span>

              <p className="pay-content_title">申请成功</p>
              <img src={PaySuccessIcon} alt="付费" />
              <p className="modal-show">
                我们根据您的认证信息进行审核，一个工作日内将通过邮件通知审核结果
              </p>
            </div>
          ) : (
            <div className="pay-content">
              <span className="pay-content_close">
                <i className="iconfont iconclose1" onClick={close} />
              </span>

              <p className="pay-content_title">机器之心PRO付费服务，即将上线</p>
              <img src={PayIcon} alt="付费" />
              <p className="modal-show">申请成为内测用户，免费抢先体验</p>

              <div className="pay-content_footer" style={{ marginTop: "40px" }}>
                <a
                  onClick={() => {
                    this.apply();
                  }}
                >
                  立即申请
                </a>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default ChargeModal;
