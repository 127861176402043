import chinaCity from "@shared/scripts/features/china_city";

const form = (successCb, errorCb) => {
  chinaCity();

  const $form = $("#js-vip-form");
  const $inputs = $(".js-vip-input");

  $form.on("focus", ".js-vip-input, .js-vip-other", function() {
    $(this)
      .closest(".vip-form__right")
      .removeClass("is-error");
  });

  $("#js-form-submit").on("click", () => {
    let flag = true;
    $inputs.each((_, item) => {
      const $item = $(item);
      const value = $item.val().trim();
      const reg = new RegExp($item.data("reg") || "");

      if (value.length === 0 || !reg.test(value)) {
        $item.closest(".vip-form__right").addClass("is-error");
        flag = false;
      }
    });

    if (!flag) return;

    if ($('input[name="apply_info[work_info][year]"]:checked').length === 0) {
      $(".js-form-experience").addClass("is-error");
      return;
    }

    if (
      $('input[name="apply_info[application_info][buy]"]:checked').length === 0
    ) {
      $(".js-form-buy").addClass("is-error");
      return;
    }

    if (
      $('input[name="apply_info[application_info][attention][]"]:checked')
        .length === 0
    ) {
      $(".js-form-attention").addClass("is-error");
      return;
    }

    $form.ajaxSubmit({
      url: $form.attr("action"),
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      type: $form.attr("method"),
      dataType: "json",
      success: successCb,
      error: xhr => {
        errorCb(xhr.responseText);
      },
    });
  });
};

export default form;
