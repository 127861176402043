import React from "react";
import PropTypes from "prop-types";

const ArticleCategory = props => {
  const { categoryName, categoryPath } = props;
  return (
    <a
      alt={categoryName}
      className="u-btn--gray category__link article-item__category"
      href={categoryPath}
      target="_blank"
      rel="noopener noreferrer"
    >
      {categoryName}
    </a>
  );
};

ArticleCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
};

export default ArticleCategory;
