import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  basename: "/search",
});

export const all = "/all";
export const article = "/article";
export const card = "/card";
export const report = "/report";
export const institution = "/institution";
export const solution = "/solution";
export const expert = "/expert";
export const user = "/user";
