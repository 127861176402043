import React from "react";
import PropTypes from "prop-types";

class DocumentModal extends React.Component {
  constructor() {
    super();
    this.targetUrl = `${window.location.href}/detail`;
  }

  close = () => {
    this.props.actions.closeModal();
  };

  render() {
    const { email } = this.props;

    return (
      <div className="document-modal">
        <button
          className="u-reset-button document-modal__close"
          type="button"
          onClick={this.close}
        >
          <i className="iconfont icon-close2" />
        </button>

        <div className="document-modal__desc">
          <span>报告已发送至您的 </span>
          <b>{email}</b>
          <span> 邮箱，请注意查收。</span>
        </div>
        <div className="document-modal__footer">
          <a
            className="document-modal__btn t-gray"
            target="_blank"
            href={this.targetUrl}
            onClick={this.close}
          >
            在线预览
          </a>
          <a
            className="u-btn--black document-modal__btn"
            href="javascript:;"
            onClick={this.close}
          >
            知道了
          </a>
        </div>
      </div>
    );
  }
}

DocumentModal.propTypes = {
  actions: PropTypes.object.isRequired,
  email: PropTypes.string,
};

DocumentModal.defaultProps = {
  email: "认证邮箱",
};

export default DocumentModal;
