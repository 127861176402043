/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import {
  mobile_or_email_rule,
  pwd_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";
import { siteKey, isProduction } from "./constants";

const turnstileID = "#turnstile-sign-in";
class SigninForm extends React.Component {
  componentDidMount() {
    if (!isProduction) return false;
    const script = document.createElement("script");
    script.className = "my-turnstile-script";
    script.src =
      "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
    document.body.appendChild(script);

    window.onloadTurnstileCallback = function() {
      window.turnstile.render(turnstileID, {
        sitekey: siteKey,
        callback: function(token) {
          console.log(`Challenge Success ${token}`);
          $.ajax({
            url: "/去验证接口",
            method: "POST",
            datType: "json",
            data: {
              "cf-turnstile-response": token,
            },
            success: function(response) {
              if ("err" in response && response["err"] != "") {
                window.turnstile.reset(turnstileID);
                return false;
              }
              window.turnstile.remove();
              // todo btn
            },
            error: function(xhr, status, error) {
              console.error("turnstile error:", error);
              window.turnstile.reset(turnstileID);
            },
          });
        },
      });
    };
  }

  componentWillUnmount() {
    if (!isProduction) return false;
    const node = document.querySelector(".my-turnstile-script");
    node.parentNode.removeChild(node);
  }

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.signinForm);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        if (res.success) {
          Salert.success("登录成功");
          this.props.setSignIn(true);
          const { mobile_verified } = res.user;
          if (mobile_verified) {
            if (res.redirect) {
              window.location.href = res.redirect;
            } else {
              window.location.reload();
            }
          }
          this.props.setSm(mobile_verified);
        }
      },
    });
  }

  render() {
    const {
      data: { login_name, password },
      changeValue,
    } = this.props;

    return (
      <form
        ref="signinForm"
        action="/login"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        {isProduction && <div id={turnstileID.replace("#", "")} />}
        <input name="utf8" type="hidden" value="✓" />
        <input name="remember_me" type="hidden" value="0" />
        <CustomizeInput
          {...login_name}
          onChange={value => {
            changeValue(value, "login_name");
          }}
        />
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          登录
        </a>
        <p className="signin-form__tip text-right">
          <label className="signin-form__remember">
            <input
              className="signin-form__checkbox"
              type="checkbox"
              name="remember_me"
              value="1"
              placeholder="记住密码"
            />
            记住密码
          </label>
          <span>
            <a
              className="signin-form__signup signin-form__forget"
              href="/forget_password"
              alt="忘记密码"
            >
              忘记密码
              <i className="iconfont icon-tip" />
            </a>
            <a
              className="signin-form__signup"
              href="javascript:;"
              alt="创建用户"
              onClick={this.props.switchAction}
            >
              &nbsp;创建用户
            </a>
          </span>
        </p>
      </form>
    );
  }
}

SigninForm.propTypes = {
  setSignIn: PropTypes.func.isRequired,
  setSm: PropTypes.func.isRequired,
  switchAction: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  login_name: buildRules("login_name", "手机号/邮箱", [mobile_or_email_rule]),
  password: buildRules("password", "密码", [pwd_rule]),
})(SigninForm);
