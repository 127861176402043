import { openModal } from "@shared/scripts/actions/modal";
import store from "@shared/scripts/utils/store";
import likeAction from "@shared/scripts/features/like_action";

const action = () => {
  $(document).on("click", ".js-like-action", function() {
    if (store.getState().currentUser.info == null) {
      store.dispatch(openModal("signin"));
      return;
    }

    likeAction(this, true);
  });
};

export default action;
