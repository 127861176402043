import React from "react";
import PropTypes from "prop-types";
import ReduxWrapper from "../utils/ReduxWrapper";

class CollectionActiveList extends React.Component {
  openChosen = () => {
    this.props.actions.openModal("collectionShow");
  };

  render() {
    return (
      <div className="u-fix-right--list">
        <a
          className="u-fix-right--btn collection-list__item"
          href="javascript:;"
          onClick={this.openChosen}
        >
          <i className="iconfont icon-huo" />
        </a>

        <a
          className="u-fix-right--btn collection-list__item"
          href="javascript:;"
          onClick={this.openChosen}
        >
          <i className="iconfont icon-huo" />
        </a>
      </div>
    );
  }
}

CollectionActiveList.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(CollectionActiveList);
