import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import INSIGHT_QUERY from "@graphql/insightDocuments.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import DocumentItemWithLeftCover from "./DocumentItemWithLeftCover";
import DocumentItemWithRightCover from "./DocumentItemWithRightCover";
import DocumentItemInMobile from "./DocumentItemInMobile";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

const theme = {
  left: () => DocumentItemWithLeftCover,
  right: () => DocumentItemWithRightCover,
  mobile: () => DocumentItemInMobile,
};

@SkipWrapper
@graphql(INSIGHT_QUERY, {
  options: props => ({
    variables: {
      cursor: props.cursor,
      count: 10,
      category: props.category,
    },
  }),
})
@WaterFallWrapper(["documents"])
class DocumentList extends React.Component {
  DocumentItem = theme[this.props.theme]();

  render() {
    const { edges } = this.props;
    const Document = this.DocumentItem;

    return edges.map(({ node }) => <Document key={node.id} {...node} />);
  }
}

DocumentList.propTypes = {
  edges: PropTypes.array,
  theme: PropTypes.string,
};

DocumentList.defaultProps = {
  edges: [],
  theme: "left",
};

export default ApolloWrapper(DocumentList);
