import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "../../shared/ImageContainer";

const SolutionItem = ({ id, name, fields, producer }) => {
  return (
    <a
      className="industry-item"
      href={`/graph/solutions/${id}`}
      target="_blank"
      alt={name}
    >
      <div className="industry-item__body">
        <div className="u-flex">
          <h4 className="industry-solution__title u-text-limit--one">{name}</h4>
          {producer && (
            <div className="u-flex u-flex-shrink">
              <ImageContainer
                url={producer.logo_url}
                name={producer.name}
                className="industry__institution--logo t-24 u-mr-6"
                theme="t-logo"
              />
              <span className="industry-solution__label">{producer.name}</span>
            </div>
          )}
        </div>
        <div className="industry-solution__tags">
          <span className="industry-solution__label">应用领域：</span>
          {fields.map(({ id: filedId, name: filedName }) => (
            <span key={filedId} className="industry__tag">
              {filedName}
            </span>
          ))}
        </div>
      </div>
    </a>
  );
};

SolutionItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  fields: PropTypes.array,
  producer: PropTypes.object,
};

SolutionItem.defaultProps = {
  name: "",
  producer: null,
  fields: [],
};

export default SolutionItem;
