import React from "react";
import PropTypes from "prop-types";

class CustomizeInput extends React.Component {
  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { type, placeholder, valid, value, msg, name, disabled } = this.props;
    const dataArr = disabled ? { disabled: true } : {};

    return (
      <div className={`u-input__container ${valid ? "" : "is-invalid"}`}>
        <input
          name={name}
          className={`u-input__input ${value === "" ? "" : "has-value"} ${
            disabled ? "is-disabled" : ""
          }`}
          value={value}
          type={type}
          onChange={event => {
            this.onChange(event);
          }}
          autoComplete="off"
          {...dataArr}
        />
        <span className="u-input__label">{placeholder}</span>
        <p className="u-input__tip">{msg}</p>
      </div>
    );
  }
}

CustomizeInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  msg: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomizeInput.defaultProps = {
  name: "",
  type: "text",
  placeholder: "",
  valid: true,
  value: "",
  msg: "",
  disabled: false,
  onChange: () => {},
};

export default CustomizeInput;
