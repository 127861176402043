import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../ImageLinkContainer";
import SolutionItem from "../SolutionItem";
import Loading from "../../utils/LogoLoading";
import Institution from "./graph/Institution";
import Expert from "./graph/Expert";
import Techology from "./graph/Techology";

const defaultAvatar = require("image_default/expert.jpg");
const defaultLogo = require("image_default/institution.png");

class ArticleList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array,
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { list } = this.props;
    if (list.length === 0) return null;

    return (
      <div className="search-panel__item u-flex">
        <span className="search-panel__type">文章</span>
        <div className="search-panel__right">
          {list.map(({ id, path, title }) => (
            <div key={id} className="search-panel__inline">
              <a
                className="search-panel__title"
                href={path}
                alt={title}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class InstitutionList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array,
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { list } = this.props;
    if (list.length === 0) return null;

    return (
      <div className="search-panel__item u-flex">
        <span className="search-panel__type">机构</span>
        <div className="search-panel__right">
          {list.map(({ id, name, logo_url, completeness }) => {
            let link = "javascript:;";
            if (completeness !== "level1") {
              link = `/graph/institutions/${id}`;
            }

            return (
              <div
                key={id}
                className="search-panel__inline u-flex search-panel__flex"
              >
                <ImageLinkContainer
                  path={link}
                  url={logo_url || defaultLogo}
                  name={name}
                  className="search-panel__logo"
                />
                <a
                  className="search-panel__title"
                  href={link}
                  alt={name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {name}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class ExpertList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array,
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { list } = this.props;
    if (list.length === 0) return null;

    return (
      <div className="search-panel__item u-flex">
        <span className="search-panel__type">人物</span>
        <div className="search-panel__right">
          {list.map(({ id, name, avatar_url, completeness }) => {
            let link = "javascript:;";
            if (completeness !== "level1") {
              link = `/graph/experts/${id}`;
            }

            return (
              <div key={id} className="u-flex article-author">
                <ImageLinkContainer
                  path={link}
                  url={avatar_url || defaultAvatar}
                  name={name}
                  className="article-author__avatar"
                  type="avatar"
                />
                <p className="article-author__attr">
                  <a
                    alt={name}
                    className="search-panel__title search-panel__user"
                    href={link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {name}
                  </a>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class UsageList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.array,
  };

  static defaultProps = {
    list: [],
  };

  render() {
    const { list } = this.props;
    if (list.length === 0) return null;

    return (
      <div className="search-panel__item u-flex">
        <span className="search-panel__type">应用案例</span>
        <div className="search-panel__right">
          {list.map(solution => (
            <SolutionItem
              key={solution.id}
              className="search-panel__usage is-small"
              {...solution}
              path={`/graph/solutions/${solution.id}`}
            />
          ))}
        </div>
      </div>
    );
  }
}

const PannelContent = ({
  card_node,
  articles,
  institutions,
  experts,
  solutions,
}) => {
  const { institution, expert, technology } = card_node;
  return (
    <div className="search-panel__content">
      <div className="u-container">
        <div className="u-col-8">
          {institution && <Institution {...institution} />}
          {expert && <Expert {...expert} />}
          {technology && <Techology {...technology} />}
          <ArticleList list={articles.nodes} />
          <InstitutionList list={institutions.nodes} />
          <ExpertList list={experts.nodes} />
          <UsageList list={solutions.nodes} />
          {/* <div className="search-panel__item u-flex">
                <span className="search-panel__type">论文</span>
                <div className="search-panel__right">
                  {
                    times.map(time => (
                      <div key={time} className="search-panel__inline">
                        <a className="search-panel__title" href="javascript:;" alt="文章">增加检测类别？这是一份目标检测的基础指南</a>
                      </div>
                    ))
                  }
                </div>
              </div> */}
        </div>
      </div>
    </div>
  );
};

PannelContent.propTypes = {
  card_node: PropTypes.object,
  articles: PropTypes.object,
  institutions: PropTypes.object,
  experts: PropTypes.object,
  solutions: PropTypes.object,
};

PannelContent.defaultProps = {
  card_node: {
    institution: null,
    expert: null,
    technology: null,
  },
  articles: {
    nodes: [],
  },
  institutions: {
    nodes: [],
  },
  experts: {
    nodes: [],
  },
  solutions: {
    nodes: [],
  },
};

class SearchPanel extends React.PureComponent {
  render() {
    const {
      keywords,
      isFetching,
      isOpenPannel,
      data,
      preventBlur,
    } = this.props;
    if (!isOpenPannel || keywords === "") return null;

    return (
      <div className="search-panel" onMouseDown={preventBlur}>
        {isFetching && <Loading />}
        <div className="u-container">
          <div className="u-col-8">
            <div className="search-panel__jump">
              <a
                className="search-panel__link"
                href={`/search/all?keywords=${encodeURI(keywords)}`}
              >
                查看关于“
                <span className="is-hightlight">{keywords}</span>
                ”的完整结果
                <i className="iconfont icon-sreach search-panel__enter" />
              </a>
            </div>
          </div>
        </div>
        <PannelContent {...data} />
      </div>
    );
  }
}

SearchPanel.propTypes = {
  keywords: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  changeHandle: PropTypes.func.isRequired,
  isOpenPannel: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  preventBlur: PropTypes.func.isRequired,
};

SearchPanel.defaultProps = {
  isFetching: false,
};

export default SearchPanel;
