import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "./ImageLinkContainer";

class CommentNoReply extends React.Component {
  constructor() {
    super();

    this.type = {
      Topic: "专题",
      Article: "文章",
      Daily: "新闻",
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.comment && this.props.comment.id === nextProps.comment.id) {
      return false;
    }
    return true;
  }

  render() {
    const { comment, created_at, user } = this.props;
    if (comment === null) return null;

    const userPath = user.path || `/users/${user.id}`;
    return (
      <div className="user-show__item">
        <ImageLinkContainer
          path={userPath}
          url={user.avatar_url || user.avatar.url}
          name={user.name}
          className="user-show__avatar"
          type="avatar"
        />
        <div className="user-show__body">
          <div className="user-show__title">
            <a
              className="user-show__title--link"
              href={userPath}
              alt={user.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              {user.name}
            </a>
            &nbsp;在
            {this.type[comment.commentable_type]}
            <a
              className="user-show__title--link"
              href={comment.path}
              alt="评论源"
              target="_blank"
              rel="noopener noreferrer"
            >
              《&nbsp;
              {comment.title}
              &nbsp;》
            </a>
          </div>
          <time className="user-show__date">{$.timeago(created_at)}</time>
          {comment.reply_to ? (
            <div className="user-show__title">
              回复{" "}
              <a
                className="user-show__title--link"
                href={comment.reply_to.path}
                alt={comment.reply_to.name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {comment.reply_to.name}
              </a>
            </div>
          ) : (
            <span className="user-show__title--text">发表评论</span>
          )}

          <div className="user-show__content">{comment.content}</div>
        </div>
      </div>
    );
  }
}

CommentNoReply.propTypes = {
  comment: PropTypes.object,
  created_at: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

CommentNoReply.defaultProps = {
  comment: null,
};

export default CommentNoReply;
