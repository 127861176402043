/* eslint-disable function-paren-newline */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import UploadImageWrapper from "../utils/UploadImageWrapper";
import ImageContainer from "../shared/ImageContainer";

const randomcover = require("cover/theme-1.jpg");

// const followBtn = (
//   <div className="column-header__btn">
//     <a className={`category__link ${isFollow ? 'is-follow' : ''} js-like-action`} href="javascript:;" onClick={() => { this.switchFollow(); }}>
//       {
//         isFollow
//           ? '已关注'
//           : <span><i className="iconfont icon-plus u-mr-6" />关注专栏</span>
//       }
//     </a>
//   </div>
// );

class UploadAvatar extends React.PureComponent {
  static propTypes = {
    uploadSuccessCb: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
  };

  render() {
    const { uploadSuccessCb, uploadUrl } = this.props;
    return (
      <UploadImageWrapper
        containerClass="user-header__avatar-mask"
        editContainerClass="u-flex user-header__avatar-edit"
        iconfontClass="icon-xiangji"
        field="avatar"
        uploadUrl={uploadUrl}
        uploadSuccessCb={uploadSuccessCb}
        aspectRatio={1}
        params="column"
        text="更换头像"
      />
    );
  }
}

class UploadCover extends React.PureComponent {
  static propTypes = {
    uploadSuccessCb: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired,
  };

  render() {
    const { uploadSuccessCb, uploadUrl } = this.props;
    return (
      <UploadImageWrapper
        containerClass="column-header__edit"
        editContainerClass="u-btn-upload"
        iconfontClass="icon-tupian u-mr-6"
        field="cover_image"
        uploadUrl={uploadUrl}
        uploadSuccessCb={uploadSuccessCb}
        aspectRatio={16 / 3}
        params="column"
        text="编辑或上传封面图片"
      />
    );
  }
}

class ColumnHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cover_image: props.column.cover_image_url,
      avatar: props.column.avatar_url,
      // isFollow: props.is_follow,
    };
    this.uploadUrl = `/columns/${props.column.id}`;
  }

  // switchFollow() {
  //   if (this.props.currentUser.info === null;) return;
  //   this.setState({
  //     isFollow: !this.state.isFollow
  //   });
  // }

  uploadSuccessCb = (field, base64) => {
    this.setState({ [field]: base64 });
    Salert.success("更新成功");
  };

  render() {
    const { column, isAuthor } = this.props;
    const { cover_image, avatar } = this.state;

    return (
      <Fragment>
        <div
          className="column-header__cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), url(${cover_image ||
              randomcover})`,
          }}
        />
        <div className="u-flex column-header__middle">
          {isAuthor && (
            <UploadCover
              uploadUrl={this.uploadUrl}
              uploadSuccessCb={this.uploadSuccessCb}
            />
          )}
          <div className="column-header__left">
            <h3 className="column-header__title">{column.name}</h3>
            <p
              className="u-text-limit--two column-header__desc"
              ref="description"
            >
              {column.description}
            </p>
          </div>
          <div className="column-header__right">
            <ImageContainer
              url={avatar}
              name={column.name}
              className="column-header__avatar"
            />
            {isAuthor && (
              <UploadAvatar
                uploadUrl={this.uploadUrl}
                uploadSuccessCb={this.uploadSuccessCb}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

ColumnHeader.propTypes = {
  column: PropTypes.object.isRequired,
  isAuthor: PropTypes.bool,
  // is_follow: PropTypes.bool
};

ColumnHeader.defaultProps = {
  isAuthor: false,
  // is_follow: false
};

export default ColumnHeader;
