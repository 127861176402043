import React from "react";
import PropTypes from "prop-types";

const ImageContainer = ({ url, name, className, type, theme }) => {
  return (
    <div className={`u-${type}-base ${className}`}>
      <img className={`u-image-center ${theme}`} src={url} alt={name} />
    </div>
  );
};

ImageContainer.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
};

ImageContainer.defaultProps = {
  url: "",
  name: "",
  className: "",
  type: "image",
  theme: "",
};

export default ImageContainer;
