import React from "react";
import PropTypes from "prop-types";
import ImageLinkContainer from "../shared/ImageLinkContainer";
import ArticleItem from "../shared/ArticleItem";

class MomentArticle extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.article === null ||
      this.props.article.path === nextProps.article.path
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { article, createdAt, user } = this.props;

    return (
      <div className="user-show__item">
        <ImageLinkContainer
          path={user.path}
          url={user.avatar_url}
          name={user.name}
          className="user-show__avatar"
          type="avatar"
        />
        <div className="user-show__body">
          <div className="user-show__title">
            <span className="user-show__title--text">{user.name}</span>
            &nbsp;发布了
          </div>
          <div className="user-show__date">{$.timeago(createdAt)}</div>
          <div className="user-show__content">
            {article === null ? (
              <div className="user-show__nil">该文章已删除</div>
            ) : (
              <div className="user-show__article">
                <ArticleItem {...article} isHasFooterLeft={false} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MomentArticle.propTypes = {
  article: PropTypes.object,
  user: PropTypes.object,
  createdAt: PropTypes.any.isRequired,
};

MomentArticle.defaultProps = {
  article: null,
  user: null,
};

export default MomentArticle;
