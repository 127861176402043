/* eslint-disable no-new */

import React from "react";
import { NavLink } from "react-router-dom";
import Swiper from "swiper";
import { searchHeader } from "@shared/scripts/utils/site_text_constant";
import { history } from "../search/routes";

const getUrl = pathname => ({
  pathname,
  search: history.location.search,
});

class SearchNav extends React.Component {
  componentDidMount() {
    new Swiper("#js-member-header", {
      slidesPerView: "auto",
      freeMode: true,
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      mousewheel: true,
    });
  }

  render() {
    return (
      <nav
        className="u-secondary-nav search__tabs u-flex swiper-container"
        id="js-member-header"
      >
        <div className="swiper-wrapper">
          {Object.keys(searchHeader).map(key => {
            const path = `/${key}`;
            return (
              <NavLink
                key={path}
                className="swiper-slide u-secondary__item"
                activeClassName="is-active"
                to={getUrl(path)}
              >
                {searchHeader[key]}
              </NavLink>
            );
          })}
        </div>
      </nav>
    );
  }
}

export default SearchNav;
