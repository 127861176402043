/* eslint-disable no-useless-escape */
export const email_reg = /^[^@]+@([^@\.]+\.)+[^@\.]+$/;
export const mobile_or_email_reg = /^([^@]+@([^@\.]+\.)+[^@\.]+)|(\d{11})$/;
/**
 * 11手机号开头号段
 * 中国移动: 13、14、15、17、18、19
 * 中国联通: 13、14、15、16、17、18
 * 中国电信: 13、14、15、17、18、19
 * 中国广电: 19
 */
export const mobile_reg = /^1[3456789]\d{9}$/;
export const pwd_reg = /^\S{6,32}$/;
export const rucaptcha_reg = /^\S{5}$/;
export const vercode_reg = /^[0-9]{6}$/;
export const phone_code_reg = /^.{6}$/;
export const nickname_reg = /^\S{4,16}$/;
export const require_reg = /.+/;
