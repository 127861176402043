import React from "react";
import PropTypes from "prop-types";

import searchSelectors from "@shared/scripts/selectors/search";
import ReduxWrapper from "../utils/ReduxWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import SearchFilterList from "./SearchFilterList";
import FetchDataWrapper from "./FetchDataWrapper";

@FetchDataWrapper
@LoadingWrapper
class LayoutWrapper extends React.Component {
  render() {
    const {
      children,
      tagList,
      published,
      tags,
      isExactMatch,
      searchInternet,
      sortMode,
      isReFetch,
      actions,
      keywords,
      hasRightFilter,
      hasSearch,
      ...otherProps
    } = this.props;

    if (!hasRightFilter) {
      return (
        <div className="u-container page-search__container">
          <div key="search_left" className="u-col-9 u-min-item">
            {React.cloneElement(children, {
              ...otherProps,
              searchInternet,
              keywords,
              data: { loading: isReFetch },
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="u-container u-clearfix page-search__container">
        <div key="search_left" className="u-col-9 u-min-item">
          {React.cloneElement(children, {
            ...otherProps,
            searchInternet,
            keywords,
            data: { loading: isReFetch },
          })}
        </div>
        <div
          key="search_right"
          className="u-col-3 has-gutter page-search__right"
        >
          <SearchFilterList
            tagList={tagList}
            published={published}
            tags={tags}
            isExactMatch={isExactMatch}
            searchInternet={searchInternet}
            sortMode={sortMode}
            hasSearch={hasSearch}
            actions={actions}
          />
        </div>
      </div>
    );
  }
}

LayoutWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  actions: PropTypes.object.isRequired,
  published: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  isExactMatch: PropTypes.bool.isRequired,
  searchInternet: PropTypes.bool.isRequired,
  sortMode: PropTypes.string.isRequired,
  isReFetch: PropTypes.bool,
  tagList: PropTypes.array,
  keywords: PropTypes.string,
  hasRightFilter: PropTypes.bool,
  hasSearch: PropTypes.bool,
};

LayoutWrapper.defaultProps = {
  isReFetch: true,
  tagList: [],
  keywords: "",
  hasRightFilter: false,
  hasSearch: false,
};

const mapStateToProps = state => {
  const {
    publishedTime,
    tags,
    isExactMatch,
    searchInternet,
    sortMode,
  } = searchSelectors.getSearchFilter(state);
  return {
    published: publishedTime,
    tags,
    isExactMatch,
    searchInternet,
    sortMode,
    hasSearch: searchSelectors.hasSearch(state),
  };
};

export default ReduxWrapper(LayoutWrapper, mapStateToProps);
