import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { getUserInfo } from "@shared/scripts/features/api";
import ReduxWrapper from "../utils/ReduxWrapper";

const Inline = ({ title, className, children, iconClass }) => (
  <div className="u-flex user-bind__inline">
    <div className="user-bind__tip">
      <span className={`user-bind__icon ${className}`}>
        <i className={`iconfont icon-${iconClass} user-bind__icon--font`} />
      </span>
      {title}
    </div>
    <div className="user-bind__right">{children}</div>
  </div>
);

Inline.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  iconClass: PropTypes.string.isRequired,
};

class UserBind extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizations: props,
    };
  }

  wxLogin = () => {
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=wx8c9a93acb12e2b2d&scope=snsapi_login&redirect_uri=${window.location.origin}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    window.location.href = url;
  };

  fetchDate = () => {
    getUserInfo().done(res => {
      this.setState({
        authorizations: res.user.authorizations,
      });
    });
  };

  unBindThree(type = "wechat") {
    this.props.actions.openModal("myConfirm", {
      type,
      fetchDate: this.fetchDate,
    });
  }

  render() {
    const { wechat, weibo, github } = this.state.authorizations;

    return (
      <Fragment>
        <Inline
          title="微信"
          className={wechat ? "wechat" : ""}
          iconClass="wechat"
        >
          {wechat ? (
            <a
              className="u-btn--border"
              href="javascript:;"
              alt="取消绑定微信"
              onClick={() => this.unBindThree("wechat")}
            >
              取消绑定
            </a>
          ) : (
            <a
              className="u-btn--red"
              href="javascript:;"
              alt="绑定微信"
              onClick={this.wxLogin}
            >
              绑定
            </a>
          )}
        </Inline>
        <Inline title="微博" className={weibo ? "weibo" : ""} iconClass="weibo">
          {weibo ? (
            <a
              className="u-btn--border"
              href="javascript:;"
              alt="取消绑定微博"
              onClick={() => this.unBindThree("weibo")}
            >
              取消绑定
            </a>
          ) : (
            <a className="u-btn--red" href="/auth/weibo" alt="绑定微博">
              绑定
            </a>
          )}
        </Inline>
        <Inline
          title="GitHub"
          className={github ? "github" : ""}
          iconClass="github1"
        >
          {github ? (
            <a
              className="u-btn--border"
              href="javascript:;"
              alt="取消绑定GitHub"
              onClick={() => this.unBindThree("github")}
            >
              取消绑定
            </a>
          ) : (
            <a className="u-btn--red" href="/auth/github" alt="绑定GitHub">
              绑定
            </a>
          )}
        </Inline>
      </Fragment>
    );
  }
}

UserBind.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(UserBind);
