/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import PropTypes from "prop-types";

const FormWrapper = initialState => Component => {
  class FormInnerWrapper extends React.Component {
    state = initialState;

    checkFeildValid = (value, type) => {
      let flag = true;
      this.state[type].rules.forEach(rule => {
        if (flag && !rule.reg.test(value)) {
          flag = false;
          this.setState({
            [type]: { ...this.state[type], valid: false, msg: rule.msg },
          });
        }
      });

      if (flag) {
        this.setState({
          [type]: { ...this.state[type], valid: true, msg: "" },
        });
      }
    };

    changeValue = (value, type) => {
      this.setState({ [type]: { ...this.state[type], value } }, () => {
        this.checkFeildValid(value, type);
      });
    };

    isFormValidate = () => {
      let flag = true;
      Object.keys(this.state).forEach(type => {
        if (
          flag &&
          (this.state[type].value === "" || !this.state[type].valid)
        ) {
          flag = false;
        }
      });

      return flag;
    };

    render() {
      return (
        <Component
          data={this.state}
          changeValue={this.changeValue}
          isFormValidate={this.isFormValidate}
          {...this.props}
        />
      );
    }
  }

  return FormInnerWrapper;
};

FormWrapper.propTypes = {
  initialState: PropTypes.object.isRequired,
};

export default FormWrapper;
