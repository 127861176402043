import React from "react";
import PropTypes from "prop-types";

import Origin from "../../shared/graph/Origin";
// import AboutInstitutions from '../../shared/graph/AboutInstitutions';
import AboutTechnologies from "../../shared/graph/AboutTechnologies";
import AboutExperts from "../../shared/graph/AboutExperts";
import ImageContainer from "../../shared/ImageContainer";

const Institution = ({
  name,
  summary,
  logo_url,
  website,
  map_summary_urls,
  experts,
  technologies,
  pro_url,
}) => {
  return (
    <div className="search-index__block search-card">
      {logo_url && (
        <ImageContainer
          url={logo_url}
          name={name}
          className="search-card__logo"
          theme="t-logo"
        />
      )}
      {
        <a
          className="u-main-title graph__keyword"
          href={`${pro_url}`}
          target="_balnk"
        >
          {name}
        </a>
      }
      {/* <div className="search-card__institution u-flex"> */}
      {website && (
        <div className="search-card__institution">
          <a
            className="search-index__more"
            href={website}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="iconfont icon-home u-mr-6" />
            机构主页
          </a>
        </div>
      )}
      {/* <a className="u-like-icon search-card__heat" href="javscript:;" rel="noopener noreferrer" target="_blank">
        <i className="iconfont icon-huo" />17
      </a> */}
      {/* </div> */}
      <p className="graph__content">{summary}</p>
      <Origin sources={map_summary_urls} />
      {/* <div className="search-card__hr" /> */}
      {/* <div className="search-card__about">
        <div className="graph__title graph__sub">
          近期动态
        </div>
        <p className="graph__content">通常人工智能是指通过普通计算机程序的手段实现的类人智能技术</p>
        <div className="u-flex">
          <span className="search-index__more">一小时前</span>
          <a className="u-like-icon" href="javscript:;" rel="noopener noreferrer" target="_blank">
            <i className="iconfont icon-message-default" />4
          </a>
        </div>
      </div> */}
      {(experts.length > 0 || technologies.length > 0) && (
        <div className="search-card__hr" />
      )}
      {/* <AboutInstitutions institutions={institutions} /> */}
      <AboutExperts experts={experts} />
      <AboutTechnologies technologies={technologies} />
    </div>
  );
};

Institution.propTypes = {
  id: PropTypes.string.isRequired,
  completeness: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  logo_url: PropTypes.string,
  website: PropTypes.string,
  map_summary_urls: PropTypes.array,
  experts: PropTypes.array,
  technologies: PropTypes.array,
};

Institution.defaultProps = {
  summary: "",
  logo_url: null,
  website: null,
  map_summary_urls: [],
  experts: [],
  technologies: [],
};

export default Institution;
