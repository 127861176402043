import React from "react";
import PropTypes from "prop-types";

class InsightTipModal extends React.Component {
  close = () => {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  render() {
    const { avatarUrl } = this.props;

    return (
      <div className="insight-tip">
        <div className="u-container">
          <div className="insight-tip__container">
            <div className="u-image-base insight-tip__avatar">
              <img className="u-image-center" src={avatarUrl} alt="用户头像" />
            </div>
            <div className="insight-tip__content">
              <div className="insight-tip__desc">
                稍后可以在<b>「个人中心」</b>内的<b>「专业认证」</b>
                模块选择是否公开职业信息
              </div>
              <button
                className="u-reset-button u-btn--black insight-tip__btn"
                onClick={this.close}
              >
                知道了
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InsightTipModal.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
};

export default InsightTipModal;
