import React from "react";
import PropTypes from "prop-types";

class InstitutionItem extends React.PureComponent {
  render() {
    const { name, website, desc } = this.props;

    return (
      <div className="search-record__inner">
        <a className="article-item__title" href="javascript:;">
          {name}
          {website && (
            <i className="iconfont icon-home search-result__des u-ml-10" />
          )}
        </a>
        <p
          className="u-base-summary search-index__more search-record__summary"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </div>
    );
  }
}

InstitutionItem.propTypes = {
  name: PropTypes.string.isRequired,
  website: PropTypes.string,
  desc: PropTypes.string.isRequired,
};

InstitutionItem.defaultProps = {
  website: null,
};

export default InstitutionItem;
