import React from "react";
import PropTypes from "prop-types";

import { collectionPanel } from "@shared/scripts/reducers/search";
import ReduxWrapper from "../utils/ReduxWrapper";

import CollectionList from "./CollectionList";
import AddCollection from "./AddCollection";

class CollectionModal extends React.Component {
  // TODO: ADD later
  addArticleToCollection = () => {};

  closeCollectionPanel = () => {
    this.props.actions.closeModal();
    this.props.actions.closeCollection();
  };

  render() {
    const { actions, currentPanel, list } = this.props;

    if (currentPanel === collectionPanel.addTo) {
      return (
        <CollectionList
          list={list}
          onCloseClick={this.closeCollectionPanel}
          onCreateCollectionClick={actions.createCollection}
          onCollectionItemClick={this.addArticleToCollection}
        />
      );
    }
    if (currentPanel === collectionPanel.create) {
      return (
        <AddCollection
          onCloseClick={this.closeCollectionPanel}
          onSave={actions.saveCollection}
        />
      );
    }

    return null;
  }
}

CollectionModal.propTypes = {
  actions: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
};

CollectionModal.defaultProps = {};

const mapState = state => ({
  list: state.collection.list,
  currentPanel: state.collection.currentPanel,
});

export default ReduxWrapper(CollectionModal, mapState);
