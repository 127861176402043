import React from "react";
import PropTypes from "prop-types";

const PageBtn = ({ className, action }) => {
  return (
    <a
      className={`internal-reference__btn ${className}`}
      href="javascript:;"
      onClick={action}
    >
      <i className="iconfont icon-prev3" />
    </a>
  );
};

PageBtn.propTypes = {
  className: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default PageBtn;
