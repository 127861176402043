import React from "react";
import PropTypes from "prop-types";

import ImageContainer from "../../shared/ImageContainer";

const ReportActions = ({ id, keywords, reportCompatible }) => {
  return (
    <div
      className="search-incompatible js-eifen-error"
      onClick={event =>
        reportCompatible(
          event,
          JSON.stringify({
            source: "jiqizhixin",
            type: "DSAT",
            article_id: id,
            query: keywords,
          })
        )
      }
    >
      <button
        className="search-incompatible__inner u-has-tip t-bottom"
        type="button"
        data-tip="内容与检索不符"
      >
        <i className="iconfont icon-iconguanbi" />
      </button>
    </div>
  );
};

ReportActions.propTypes = {
  id: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  reportCompatible: PropTypes.func.isRequired,
};

ReportActions.defaultProps = {
  keywords: "",
};

class ArticleItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }

  reportCompatible = (event, data) => {
    event.preventDefault();
    if (data === "") return;
    $.ajax({
      url: "/api/v1/search/trace",
      method: "POST",
      data: {
        json: data,
      },
      error: () => {},
    }).always(() => {
      this.setState({
        isShow: false,
      });
    });
  };

  render() {
    if (!this.state.isShow) return null;
    const {
      title,
      path,
      author,
      content,
      published_at,
      cover_image_url,
      searchInternet,
      id,
      index,
      keywords,
      source,
    } = this.props;
    const queryKeyword = encodeURI(keywords);

    const dataArr = searchInternet
      ? {
          "data-eigen-log-info": JSON.stringify({
            type: "item",
            articleId: id,
            pageNo: index / 10,
            itemIndex: index % 10,
          }),
        }
      : {};

    const pathHasQuery =
      path.indexOf("www.jiqizhixin.com/articles/") > -1
        ? `${path}?from=synced&keyword=${queryKeyword}`
        : path;

    return (
      <div className="search-record__inner">
        <a
          className={`article-item__container search-article ${
            searchInternet ? "js-search-article" : ""
          }`}
          href={pathHasQuery}
          target="_blank"
          rel="noopener noreferrer"
          data-id={id}
          data-keywords={keywords}
          {...dataArr}
        >
          {searchInternet && (
            <ReportActions
              id={id}
              dataArr={dataArr}
              keywords={keywords}
              reportCompatible={this.reportCompatible}
            />
          )}
          <main className="article-item__right search-article__right">
            <section>
              <div
                className="article-item__title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <p
                className="u-text-limit--two u-base-summary search-article__contain"
                dangerouslySetInnerHTML={{
                  __html: content || "作者很懒，没有写简介...",
                }}
              />
            </section>
            <footer className="u-flex article-item__footer">
              <div className="article-item__author">
                {author && <span className="article-item__name">{author}</span>}
                {published_at && (
                  <time className="article-item__publish">{published_at}</time>
                )}
              </div>
              <div className="u-flex search-record__start">
                <span className="article-item__source">
                  来源:
                  {source}
                </span>
              </div>
            </footer>
          </main>
          {cover_image_url && (
            <ImageContainer
              url={`${cover_image_url}?imageView2/1/w/118/h/81`}
              name={title}
              className="search-article__cover"
            />
          )}
        </a>
      </div>
    );
  }
}

ArticleItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  author: PropTypes.string,
  content: PropTypes.string.isRequired,
  published_at: PropTypes.string,
  cover_image_url: PropTypes.string,
  searchInternet: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  keywords: PropTypes.string,
  source: PropTypes.string,
};

ArticleItem.defaultProps = {
  published_at: null,
  cover_image_url: null,
  searchInternet: false,
  keywords: "",
  source: "",
  author: "",
};

export default ArticleItem;
