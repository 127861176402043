import React from "react";
import PropTypes from "prop-types";

import NoticePanel from "./notice/NoticePanel";
import EffectSwitch from "../../utils/EffectSwitch";

class Notice extends React.Component {
  state = {
    isShowPanel: false,
    count: this.props.currentUser.notifications_count,
  };

  componentDidMount() {
    $(document).on("click", e => {
      if (!$.contains(this.refs.headerNotice, e.target)) {
        this.closePanel();
      }
    });
    // const actionCable = require("@sites/application/components/action_cable")
    //   .default;
    // const { currentUser } = this.props;
    // if (currentUser !== null) {
    //   actionCable();
    // }
  }

  setCount = count => {
    this.setState({
      count,
    });
  };

  openPanel = () => {
    this.setState({ isShowPanel: true });
  };

  closePanel = () => {
    this.setState({ isShowPanel: false });
  };

  togglePanel = () => {
    if (this.state.isShowPanel) {
      this.closePanel();
    } else {
      this.openPanel();
    }
  };

  reduceCount = () => {
    this.setCount(this.state.count - 1);
  };

  render() {
    const { count } = this.state;
    let labelContent = null;

    if (count > 0) {
      if (count < 99) {
        labelContent = <span className="header-notice__count">{count}</span>;
      } else {
        labelContent = <span className="header-notice__count">...</span>;
      }
    } else {
      labelContent = <i className="iconfont icon-Notice-" />;
    }

    return (
      <div className="header-notice" ref="headerNotice">
        <a
          href="javascript:;"
          className="header-notice__btn js-notice-btn"
          alt="通知"
          onClick={() => this.togglePanel()}
        >
          {labelContent}
        </a>
        <EffectSwitch condition={this.state.isShowPanel}>
          <NoticePanel
            reduceCount={this.reduceCount}
            setCount={this.setCount}
          />
        </EffectSwitch>
      </div>
    );
  }
}

Notice.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Notice;
