/* eslint-disable no-useless-escape */
import React from "react";
import Salert from "react-s-alert";
import PropTypes from "prop-types";

import {
  mobile_rule,
  buildRules,
  vercode_rule,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

class BindPhoneForm extends React.Component {
  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.form);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: () => {
        Salert.success("注册成功");
        window.location.href = "/";
      },
      error: xhr => {
        Salert.error(xhr.responseText);
        $(this.refs.rucaptcha).click();
      },
    });
  }

  sendCode() {
    const {
      data: { mobile },
    } = this.props;

    if (mobile.value && mobile.valid) {
      console.log("pass");
    }
  }

  render() {
    const {
      data: { mobile, phoneCode },
      changeValue,
    } = this.props;

    return (
      <form
        ref="form"
        action="/signup"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <div className="form-header-area">
          <p className="form-header-title">绑定手机号</p>
          <p className="form-header-tips">
            已检测到您绑定过的手机号码，请重新验证一下
          </p>
        </div>
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...mobile}
          onChange={value => {
            changeValue(value, "mobile");
          }}
        />
        <div className="u-pic-code u-pic-code--phone">
          <CustomizeInput
            {...phoneCode}
            onChange={value => {
              changeValue(value, "phone-code");
            }}
          />
          <div className="send-code" onClick={() => this.sendCode()}>
            发送验证码
          </div>
        </div>
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="绑定"
          onClick={() => {
            this.submit();
          }}
        >
          绑定
        </a>
        <div className="bind-form-footer">
          若您需要更换账号中已有手机号，请登录后前往「个人中心」修改，
          或使用新手机号重新 <span onClick={this.props.switchState}>注册</span>
        </div>
      </form>
    );
  }
}

BindPhoneForm.propTypes = {
  switchState: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  mobile: buildRules("mobile", "手机号", [mobile_rule]),
  phoneCode: buildRules("phoneCode", "验证码", [vercode_rule]),
})(BindPhoneForm);
