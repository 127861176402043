/* eslint-disable react/prefer-stateless-function */
import React from "react";

class InstitutionItem extends React.PureComponent {
  render() {
    return (
      <div className="search-result__item">
        <div className="u-flex search-result__institution">
          <div className="search-result__title">Facebook 人工智能研究机构</div>
          <a className="u-mute-link search-result__more" href="javascript:;">
            {" "}
            查看更多机构 &gt;
          </a>
        </div>
        <div className="search-result__des">是一位计算机科学家，他在机器</div>
      </div>
    );
  }
}

export default InstitutionItem;
