import React from "react";
import PropTypes from "prop-types";

class SolutionItem extends React.PureComponent {
  render() {
    const { name, corporation_name, id, children } = this.props;

    return (
      <a
        className="search-result__item t-block"
        href={`/graph/solutions/${id}`}
        target="_blank"
      >
        <div className="search-result__title">{name}</div>
        <div className="u-flex search-result__footer">
          <span className="search-result__usage">{corporation_name}</span>
          {children}
        </div>
      </a>
    );
  }
}

SolutionItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  corporation_name: PropTypes.string,
  children: PropTypes.element,
};

SolutionItem.defaultProps = {
  corporation_name: "",
  children: null,
};

export default SolutionItem;
