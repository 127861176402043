import React from "react";
import PropTypes from "prop-types";

import cover_default from "image_default/article_cover_default.svg";
import LikeAndCommentAction from "./LikeAndCommentAction";
import ImageLinkContainer from "./ImageLinkContainer";
import ArticleCategory from "./article/ArticleCategory";
import ImageContainer from "./ImageContainer";

const ArticleAuthor = ({ path, avatar_url, name }) => {
  return (
    <span>
      <ImageLinkContainer
        path={path}
        url={avatar_url}
        name={name}
        className="article-item__avatar"
        type="avatar"
      />
      <a
        className="article-item__name"
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    </span>
  );
};

ArticleAuthor.propTypes = {
  path: PropTypes.string.isRequired,
  avatar_url: PropTypes.string.isRequired,
  name: PropTypes.string,
};

ArticleAuthor.defaultProps = {
  name: "",
};

class ArticleItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const {
      cover_image_url,
      path,
      title,
      description,
      author,
      published_at,
      category_name,
      category_path,
      remote,
      isHasCategory,
      isHasAuthor,
      isPreciseTime,
      isHasFooterLeft,
    } = this.props;
    const herfTarget = remote ? "_self" : "_blank";

    return (
      <article className="article-item__container">
        <a
          className="js-open-modal"
          href={path}
          data-remote={remote}
          target={herfTarget}
        >
          <ImageContainer
            url={`${cover_image_url}?imageView2/1/w/260/h/188`}
            name={title}
            className="u-opacity-effect article-item__cover"
          />
        </a>
        <main className="article-item__right">
          <section>
            <a
              className="article-item__title t-strong js-open-modal"
              href={path}
              dangerouslySetInnerHTML={{ __html: title }}
              data-remote={remote}
              target={herfTarget}
            />
            <p
              className="u-text-limit--two article-item__summary"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </section>
          <footer className="u-flex article-item__footer">
            {isHasFooterLeft && (
              <div className="article-item__author">
                {isHasAuthor && <ArticleAuthor {...author} />}
                <time className="article-item__publish">
                  {isPreciseTime ? published_at : $.timeago(published_at)}
                </time>
              </div>
            )}
            <div className="article-item__other">
              {isHasCategory && (
                <ArticleCategory
                  categoryName={category_name}
                  categoryPath={category_path}
                />
              )}
              <LikeAndCommentAction {...this.props} />
            </div>
          </footer>
        </main>
      </article>
    );
  }
}

ArticleItem.propTypes = {
  cover_image_url: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.object.isRequired,
  published_at: PropTypes.string.isRequired,
  category_name: PropTypes.string,
  category_path: PropTypes.string,
  remote: PropTypes.bool,
  isHasAuthor: PropTypes.bool,
  isHasFooterLeft: PropTypes.bool,
  isHasCategory: PropTypes.bool,
  isPreciseTime: PropTypes.bool,
};

ArticleItem.defaultProps = {
  category_name: "",
  category_path: "",
  cover_image_url: cover_default,
  description: "作者很懒，没有写简介...",
  remote: false,
  isHasAuthor: true,
  isHasCategory: true,
  isPreciseTime: false,
  isHasFooterLeft: true,
};

export default ArticleItem;
