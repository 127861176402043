import {
  pageScrollToTarget,
  articleInfoNodesAction,
  sidebarWithScroll,
} from "@shared/scripts/utils/tool";
import { updateReadLaterProgress } from "@shared/scripts/features/api";
import mediumZoom from "medium-zoom";
import options from "./card_options";

const article = () => {
  const $window = $(window);

  /* go comment --start */
  const $commentIcon = $(".js-go-comment");
  $commentIcon.on("click", () => {
    pageScrollToTarget($("#comment").offset().top, 1000);
    $(".js-open-editor").click();
  });
  /* go comment --end */

  /* article sidebar */
  const $actions = $(".js-article-action");
  if ($actions.offset().top > $window.height()) {
    $(".js-article-sidebar").addClass("is-show");
    sidebarWithScroll({
      sidebarSelector: ".js-article-sidebar",
      contentSelector: ".js-article-action",
    });
  }

  const $content = $("#js-article-content");
  if ($content.length === 0) return;

  /* article image */
  mediumZoom("#js-article-content img", {
    margin: 24,
    background: "#fff",
    scrollOffset: 0,
    metaClick: false,
  });

  /* article keyword: graph card */
  $("#js-article-content mark").tooltipster(options);

  /* article related nodes */
  articleInfoNodesAction();

  /* progress bar --start */
  const $progressBar = $("#js-progress-bar");
  const articleTotalHeight = $content.height();
  const articleOffsetTop = $content.offset().top;
  const $header = $("header.header");
  let progress = 0;
  let oldScrollTop = 0;
  // let timeout = null;

  $window.on("scroll", () => {
    const scrollTop = Math.ceil($window.scrollTop());
    const barWidth = (
      ((scrollTop - articleOffsetTop) / articleTotalHeight) *
      100
    ).toFixed(2);
    progress = barWidth > 100 ? 100 : barWidth;
    $progressBar.css("width", `${progress}%`);

    if (scrollTop === 0) {
      $header.addClass("t-no-shadow");
    }

    if (scrollTop < oldScrollTop) {
      // up
      $header.removeClass("t-hidden").removeClass("t-absolute");
    } else if (scrollTop < 80) {
      $header.addClass("t-absolute");
    } else {
      $header.addClass("t-hidden").removeClass("t-no-shadow");
    }
    oldScrollTop = scrollTop;

    // card
    // $content.removeClass('is-show-keyword');
    // clearTimeout(timeout);
    // timeout = setTimeout(() => {
    //   $content.addClass('is-show-keyword');
    // }, 1500);
  });
  /* progress bar --end */

  const $readLater = $("#js-read-later");
  if ($readLater.length > 0) {
    $window.on("unload", () => {
      const num = parseInt(progress, 10);
      if (num > 0) {
        updateReadLaterProgress($readLater.data().id, num);
      }
    });
  }

  $window.scroll();
};

export default article;
