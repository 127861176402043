import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import ARTICLES_QUERY from "@graphql/columnArticles.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";
import SettingArticleItem from "./SettingArticleItem";

@SkipWrapper
@graphql(ARTICLES_QUERY, {
  options: props => ({
    variables: {
      id: props.columnId,
      count: 10,
      cursor: "",
      status: props.status,
    },
  }),
})
@WaterFallWrapper(["column", "articles"])
class SettingArticleList extends React.Component {
  render() {
    const { edges, baseUrl } = this.props;

    return edges.map(({ node }) => (
      <SettingArticleItem key={node.id} {...node} baseUrl={baseUrl} />
    ));
  }
}

SettingArticleList.propTypes = {
  edges: PropTypes.array,
  baseUrl: PropTypes.string.isRequired,
};

SettingArticleList.defaultProps = {
  edges: [],
};

export default ApolloWrapper(SettingArticleList);
