import React, { Fragment } from "react";
import PropTypes from "prop-types";

const GraphHeader = ({ id, type, name, summary, path, completeness }) => {
  return (
    <Fragment>
      <div className="graph__title">
        {completeness === "level2" ? (
          <a
            className="u-main-title graph__keyword"
            href={path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        ) : (
          <span className="graph__keyword">{name}</span>
        )}
        &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
        {type}
      </div>
      <p
        key={`${type}${id}summary`}
        className="u-text-limit--three graph__content"
      >
        {summary}
      </p>
    </Fragment>
  );
};

GraphHeader.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  completeness: PropTypes.string,
};

GraphHeader.defaultProps = {
  summary: "",
  path: "javascript:;",
  completeness: "level2",
};

export default GraphHeader;
