import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "../../shared/ImageContainer";

const defaultImage = require("image_default/institution.png");

const InstitutionInAll = ({ institutions, keywords }) => {
  if (institutions.length === 0) return null;

  return (
    <div className="search-index__block search-card">
      <div className="search-card__institution--title">
        涉及“
        {keywords}
        ”的机构
      </div>
      {institutions.map(({ id, name, logo_url, pro_url }) => {
        return (
          <a
            key={id}
            className="search-record__institution"
            href={`${pro_url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageContainer
              url={logo_url || defaultImage}
              name={name}
              className="search-record__institution--avatar"
            />
            <span className="search-record__link">{name}</span>
          </a>
        );
      })}
    </div>
  );
};

InstitutionInAll.propTypes = {
  institutions: PropTypes.array,
  keywords: PropTypes.string,
};

InstitutionInAll.defaultProps = {
  institutions: [],
  keywords: "",
};

export default InstitutionInAll;
