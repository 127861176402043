import React from "react";
import PropTypes from "prop-types";
// import { switchLike } from '@shared/scripts/features/api';

import ImageContainer from "../shared/ImageContainer";

class ColumnItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  // state = {
  //   isFollow: this.props.is_follow,
  // }

  /*
  switchFollow() {
    if (this.props.currentUser.info === null) return;
    // TODO(wangpp) 判断是否成功
    const { isFollow } = this.state;
    switchLike(this.props.path, isFollow)
      .always((res) => {
        console.log(res);

        this.setState({
          isFollow: !isFollow
        });
      });
  }
  */

  render() {
    const { path, avatar_url, description, articles_count, name } = this.props;

    return (
      <div className="column-item">
        <a
          className="u-shadow-effect column-item__inline"
          href={path}
          alt={name}
        >
          <ImageContainer
            url={avatar_url}
            name={name}
            className="column-item__avatar"
          />
          <div className="column-item__bottom">
            <h4 className="column-item__name">{name}</h4>
            <p className="u-text-limit--two column-item__summary">
              {description}
            </p>
          </div>
          {/* <a
            className={`u-btn--border column-item__right ${isFollow ? 'is-disabled' : ''}`}
            href="javascript:;"
            onClick={() => { this.switchFollow(); }}
          >
            {
              isFollow
                ? '已关注'
                : <span>关注专栏<i className="iconfont icon-plus u-ml-6" /><span>
            }
          </a> */}
          <div className="column-item__infos">
            <span className="column-item__info">共{articles_count} 篇文章</span>
          </div>
        </a>
      </div>
    );
  }
}

ColumnItem.propTypes = {
  path: PropTypes.string.isRequired,
  avatar_url: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  articles_count: PropTypes.number,
};

ColumnItem.defaultProps = {
  avatar_url: "",
  description: "",
  articles_count: 0,
};

export default ColumnItem;
