import React from "react";
import { ApolloProvider } from "react-apollo";
import client from "@shared/scripts/utils/apollo_client";

const ApolloWrapper = Component => {
  class Wrapper extends React.PureComponent {
    client = client();

    render() {
      return (
        <ApolloProvider client={this.client}>
          <Component {...this.props} />
        </ApolloProvider>
      );
    }
  }

  Wrapper.displayName = `ApolloProvider(${Component.name})`;
  return Wrapper;
};

export default ApolloWrapper;
