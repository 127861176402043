import React from "react";
import PropTypes from "prop-types";

import ImageLinkContainer from "../../shared/ImageLinkContainer";

class UserItem extends React.PureComponent {
  render() {
    const { id, nickname, avatar_url, bio } = this.props;

    return (
      <div className="search-result__item u-flex">
        <ImageLinkContainer
          path={`/users/${id}`}
          url={avatar_url}
          name={nickname}
          className="search-result__avatar"
        />
        <div className="search-result__user">
          <a
            className="search-result__title"
            href={`/users/${id}`}
            target="_blank"
            alt={nickname}
          >
            {nickname}
          </a>
          <span className="u-mute-link u-text-limit--two">{bio}</span>
        </div>
      </div>
    );
  }
}

UserItem.propTypes = {
  id: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  avatar_url: PropTypes.string,
  bio: PropTypes.string,
};

UserItem.defaultProps = {
  avatar_url: "",
  bio: "",
};

export default UserItem;
