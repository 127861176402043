import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";

import { parseData, combData } from "@shared/scripts/utils/apollo_tool";

const FetchData = params => Component => {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isFetching: false,
        isFetchMore: false,
      };
      this.checkedTags = [];
    }

    fetchMore = () => {
      const { fetchMore, variables } = this.props.data;

      this.setState({ isFetchMore: true });
      variables.cursor = this.paramsData.pageInfo.endCursor;

      fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { edges, pageInfo } = parseData(params, fetchMoreResult);
          return update(
            previousResult,
            combData(params, {
              edges: { $push: edges },
              pageInfo: { $set: pageInfo },
            })
          );
        },
      }).then(() => {
        this.setState({ isFetchMore: false });
      });
    };

    tagsChange = checkedTags => {
      this.fetchFilter(checkedTags);
    };

    fetchFilter(checkedTags = []) {
      const { fetchMore, variables } = this.props.data;
      variables.filterTags = checkedTags;

      this.setState({ isFetching: true });
      variables.cursor = "";

      fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { edges, pageInfo } = parseData(params, fetchMoreResult);
          return update(
            previousResult,
            combData(params, {
              edges: { $set: edges },
              pageInfo: { $set: pageInfo },
            })
          );
        },
      }).then(() => {
        this.setState({ isFetching: false });
      });
    }

    render() {
      const { data, ...others } = this.props;
      const { isFetching, isFetchMore } = this.state;
      this.paramsData = parseData(params, data);
      const {
        edges,
        pageInfo: { hasNextPage },
      } = this.paramsData;

      return (
        <Component
          {...others}
          edges={edges}
          isFetching={isFetching}
          isFetchMore={isFetchMore}
          hasNextPage={hasNextPage}
          fetchData={this.fetchMore}
          tagsChange={checkedTags => {
            this.tagsChange(checkedTags);
          }}
        />
      );
    }
  }

  Wrapper.propTypes = {
    data: PropTypes.object.isRequired,
    tagsList: PropTypes.array,
  };

  Wrapper.defaultProps = {
    tagsList: [],
  };

  return Wrapper;
};

export default FetchData;
