import React from "react";
import PropTypes from "prop-types";

const NavHeader = () => {
  return (
    <div className="user-info">
      <a
        className="user-info__img"
        href="/"
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  );
};

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.navItem.pages.includes(props.currentPage),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { subItems } = this.props.navItem;
    let listSubItems = null;

    const { isOpen } = this.state;
    const hasLink = this.props.navItem.link;

    if (subItems) {
      listSubItems = subItems.map(subItem => (
        <li
          key={subItem.id}
          className={`nav-item is-sub ${
            this.props.currentPage === subItem.title_en ? "is-active" : ""
          }`}
        >
          <a className="nav-item__link" href={subItem.link}>
            {subItem.title}
          </a>
        </li>
      ));
    }

    if (hasLink) {
      return (
        <li
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          className={`nav-item ${
            this.props.currentPage === this.props.navItem.title_en
              ? "is-active"
              : ""
          }`}
        >
          <span className={`glyphicon , ${this.props.navItem.icon}`} />
          <a className="nav-item__link" href={this.props.navItem.link}>
            {this.props.navItem.title}
          </a>
        </li>
      );
    }

    return (
      <div className={`nav-main ${isOpen ? "is-open" : ""}`}>
        <li
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          className="nav-item"
        >
          <span className={`glyphicon , ${this.props.navItem.icon}`} />
          <span className="nav-item__link">
            {this.props.navItem.title}
            <span className="glyphicon glyphicon-menu-right nav-main__arrow" />
          </span>
        </li>
        <ul className="nav__sub">{listSubItems}</ul>
      </div>
    );
  }
}

NavItem.propTypes = {
  currentPage: PropTypes.string,
  navItem: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    subItems: PropTypes.array,
    pages: PropTypes.array,
    title_en: PropTypes.string,
  }),
};

NavItem.defaultProps = {
  navItem: {},
  currentPage: "",
};

const Sidebar = props => {
  const navItems = [
    {
      id: 1,
      title: "数据分析",
      title_en: "statistics",
      icon: "glyphicon-th-large",
      link: "/admin",
      pages: [],
    },
    {
      id: 2,
      title: "内容管理",
      icon: "glyphicon-inbox",
      pages: [
        "articles",
        "comments",
        "tags",
        "special_columns",
        "dailies",
        "topics",
        "banners",
      ],
      subItems: [
        {
          id: 1,
          title: "文章管理",
          title_en: "articles",
          link: "/admin/articles?q[published]=true",
        },
        {
          id: 2,
          title: "评论管理",
          title_en: "comments",
          link: "/admin/comments",
        },
        {
          id: 3,
          title: "专栏管理",
          title_en: "special_columns",
          link: "/admin/special_columns",
        },
        {
          id: 4,
          title: "专题管理",
          title_en: "topics",
          link: "/admin/topics?status=published",
        },
        {
          id: 5,
          title: "AI Daily",
          title_en: "dailies",
          link: "/admin/dailies",
        },
        {
          id: 6,
          title: "Banner 管理",
          title_en: "banners",
          link: "/admin/banners?category=home",
        },
        {
          id: 7,
          title: "标签管理",
          title_en: "tags",
          link: "/admin/tags",
        },
        {
          id: 8,
          title: "PDF管理",
          title_en: "pdf",
          link: "/admin/reports",
        },
      ],
    },
    {
      id: 3,
      title: "用户管理",
      title_en: "users",
      icon: "glyphicon-user",
      link: "/admin/users",
      pages: [],
    },
    {
      id: 4,
      title: "广告管理",
      title_en: "advertisements",
      icon: "glyphicon-usd",
      link: "/admin/advertisements",
      pages: [],
    },
    {
      id: 7,
      title: "申请管理",
      icon: "glyphicon-send",
      page: "applies",
      pages: ["memberships", "applies"],
      subItems: [
        {
          id: 1,
          title: "会员管理",
          title_en: "memberships",
          link: "/admin/memberships",
        },
        {
          id: 2,
          title: "会员申请",
          title_en: "applies",
          link: "/admin/applies?q[accepted]=true",
        },
      ],
    },
    {
      id: 8,
      title: "活动相关",
      icon: "glyphicon-th-list",
      page: "applies",
      pages: ["events", "guests", "partners"],
      subItems: [
        {
          id: 1,
          title: "活动管理",
          title_en: "events",
          link: "/admin/events",
        },
        {
          id: 2,
          title: "嘉宾管理",
          title_en: "guests",
          link: "/admin/guests",
        },
        {
          id: 3,
          title: "合作方管理",
          title_en: "partners",
          link: "/admin/partners",
        },
      ],
    },
  ];
  const infoItems = [
    {
      id: 5,
      title: "指南",
      icon: "glyphicon-search",
      page: "handbook",
      pages: ["usages", "industries", "technicals", "corporations"],
      subItems: [
        {
          id: 1,
          title: "案例管理",
          title_en: "usages",
          link: "/admin/usages",
        },
        {
          id: 2,
          title: "应用领域管理",
          title_en: "industries",
          link: "/admin/industries",
        },
        {
          id: 3,
          title: "技术分类管理",
          title_en: "technicals",
          link: "/admin/technicals",
        },
        {
          id: 4,
          title: "提供方管理",
          title_en: "corporations",
          link: "/admin/corporations",
        },
      ],
    },
    {
      id: 6,
      title: "信息平台",
      icon: "glyphicon-th",
      page: "platform",
      pages: [
        "technologies",
        "experts",
        "institutions",
        "papers",
        "products",
        "resources",
        "publications",
        "use_cases",
        "examples",
      ],
      subItems: [
        {
          id: 1,
          title: "技术",
          title_en: "technologies",
          link: "/admin/technologies",
        },
        {
          id: 2,
          title: "人物",
          title_en: "experts",
          link: "/admin/experts",
        },
        {
          id: 3,
          title: "机构",
          title_en: "institutions",
          link: "/admin/institutions",
        },
        {
          id: 4,
          title: "论文",
          title_en: "papers",
          link: "/admin/papers",
        },
        {
          id: 5,
          title: "产品",
          title_en: "products",
          link: "/admin/products",
        },
        {
          id: 6,
          title: "资源",
          title_en: "resources",
          link: "/admin/resources",
        },
        {
          id: 7,
          title: "会议及期刊",
          title_en: "publications",
          link: "/admin/publications",
        },
        {
          id: 8,
          title: "应用场景",
          title_en: "use_cases",
          link: "/admin/use_cases",
        },
        {
          id: 9,
          title: "应用实例",
          title_en: "examples",
          link: "/admin/examples",
        },
      ],
    },
  ];
  return (
    <div className="sidebar">
      <NavHeader />
      <ul className="navs">
        {props.onlyAnalyst
          ? infoItems.map(item => (
              <NavItem
                key={item.id}
                navItem={item}
                currentPage={props.current_page}
              />
            ))
          : navItems
              .concat(infoItems)
              .map(item => (
                <NavItem
                  key={item.id}
                  navItem={item}
                  currentPage={props.current_page}
                />
              ))}
      </ul>
    </div>
  );
};

Sidebar.propTypes = {
  current_page: PropTypes.string,
};

Sidebar.defaultProps = {
  current_page: "",
};

export default Sidebar;
