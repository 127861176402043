import React from "react";
import PropTypes from "prop-types";

import NoticeComment from "../shared/header/notice/NoticeComment";
import NoticeDaily from "../shared/header/notice/NoticeDaily";

class NoticeTip extends React.Component {
  state = {
    isShow: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isShow: false,
      });
    }, 5000);
  }

  hiddenEle() {
    this.setState({
      isShow: false,
    });
  }

  render() {
    const { type } = this.props;

    if (this.state.isShow) {
      if (type === "comment") {
        return (
          <NoticeDaily
            hiddenEle={() => {
              this.hiddenEle();
            }}
          />
        );
      }

      return (
        <NoticeComment
          hiddenEle={() => {
            this.hiddenEle();
          }}
        />
      );
    }

    return null;
  }
}

NoticeTip.propTypes = {
  type: PropTypes.string,
};

NoticeTip.defaultProps = {
  type: "comment",
};

export default NoticeTip;
