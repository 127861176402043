import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import COLUMNS_QUERY from "@graphql/myColumns.graphql";
import ReduxWrapper from "../../utils/ReduxWrapper";
import ApolloWrapper from "../../utils/ApolloWrapper";
import LoadingWrapper from "../../utils/LoadingWrapper";
import ImageLinkContainer from "../ImageLinkContainer";
import NoData from "../../utils/NoData";

@graphql(COLUMNS_QUERY, {
  options: props => ({
    variables: {
      id: props.currentUser.info.id,
    },
  }),
})
@LoadingWrapper
class MyColumnModal extends React.Component {
  render() {
    if (this.props.data === null) return null;
    const {
      actions,
      data: {
        user: { columns },
      },
    } = this.props;

    return (
      <div className="signin-modal column-modal">
        <div className="column-modal__container">
          <p className="column-modal__my">我的专栏</p>
          <div className="column-modal__list">
            {columns.edges.length === 0 && <NoData />}
            {columns.edges.map(edge => {
              const { node } = edge;
              return (
                <div key={node.path} className="column-modal__item">
                  <ImageLinkContainer
                    path={node.path}
                    url={node.avatar_url}
                    name={node.name}
                    className="column-modal__cover"
                  />
                  <div className="column-modal__middle">
                    <h2 className="column-modal__title">{node.name}</h2>
                    <p className="column-modal__other">
                      <span className="column-modal__contain">
                        文章
                        <span className="column-modal__count">
                          {node.articles_count}
                        </span>
                      </span>
                      <a
                        className="u-btn--black column-modal__enter"
                        href={node.path}
                        alt="我的专栏"
                      >
                        进入专栏
                      </a>
                    </p>
                    <p className="u-text-limit--one column-modal__description">
                      {node.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={actions.closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>
    );
  }
}

MyColumnModal.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object,
};

MyColumnModal.defaultProps = {
  data: null,
};

export default ApolloWrapper(ReduxWrapper(MyColumnModal));
