import { email_reg, mobile_reg } from "./all_reg_exp";

/**
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => {
  return value.length >= minLength;
};

/**
 * @param  value
 * @return
 */
const requiredValidator = value => {
  return value.trim() !== "";
};

/**
 * @param value
 * @return
 */
const emailValidator = value => {
  return email_reg.test(String(value).toLowerCase());
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
const mobileValidator = value => {
  return mobile_reg.test(String(value).toLowerCase());
};

const validate = (value, rules) => {
  let isValid = true;

  Object.keys(rules).forEach(rule => {
    switch (rule) {
      case "minLength":
        isValid = isValid && minLengthValidator(value, rules[rule]);
        break;

      case "isRequired":
        isValid = isValid && requiredValidator(value);
        break;

      case "isEmail":
        isValid = isValid && emailValidator(value);
        break;

      case "isMobile":
        isValid = isValid && mobileValidator(value);
        break;

      default:
        isValid = true;
    }
  });

  return isValid;
};

export default validate;
