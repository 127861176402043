import React from "react";

const NoticeLoading = () => {
  return (
    <div className="notice-loading">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  );
};

export default NoticeLoading;
