import React from "react";
// import PropTypes from 'prop-types';

import cover_default from "image_default/article_cover_default.svg";
import ImageContainer from "../../shared/ImageContainer";

class EventItem extends React.PureComponent {
  render() {
    const { brief_img, link, location, published_at, title } = {
      brief_img: "",
      link: "javascript:;",
      location: "北京市朝阳区酒仙桥9号幸运加速器",
      published_at: "2018/12/23  14:00-17:00",
      title: "INTERFACE#4：从技术到产品，搜狗为我们解读了神经机器翻译的现状",
    };

    return (
      <div className="search-record__inner">
        <div className="article-item__container">
          <a href="javascript:;" target="_blank" rel="noopener noreferrer">
            <ImageContainer
              url={brief_img || cover_default}
              name={title}
              className="article-item__cover"
            />
          </a>
          <div className="article-item__right">
            <a
              className="article-item__title"
              href={link}
              dangerouslySetInnerHTML={{ __html: title }}
              data-remote={this.remote}
              target={this.remote ? "_self" : "_blank"}
            />
            <div className="article-item__footer">
              <span className="article-item__publish">
                <i className="iconfont icon-shijian u-mr-6" />
                <time>{published_at}</time>
              </span>
              <div className="u-flex search-record__flex">
                <span className="article-item__publish">
                  <i className="iconfont icon-huodongdidian u-mr-6" />
                  {location}
                </span>
                <a
                  className="u-btn--red search-record__btn"
                  href="javascript:;"
                >
                  报名中
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventItem;
