import { updateUser } from "@shared/scripts/actions/user";
import store from "../utils/store";

export default () => {
  const $login = $("#js-login-functionality");
  const userData = $login.data("user");
  if (userData) {
    store.dispatch(updateUser(userData));
    $login.remove();
  }
};
