import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import "jquery.qrcode";

import DAILIES_QUERY from "@graphql/dailies.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import LoadingWrapper from "../utils/LoadingWrapper";
import HasDataWrapper from "../utils/HasDataWrapper";
import DailyWrapper from "./DailyWrapper";
import DailyFooter from "./DailyFooter";
import MDailyTime from "./MDailyTime";

@graphql(DAILIES_QUERY, {
  options: {
    variables: {
      cursor: "",
    },
  },
})
@LoadingWrapper
@DailyWrapper
@HasDataWrapper(true, 2)
class MDailies extends React.Component {
  render() {
    const { dailies } = this.props;

    return (
      <div ref="container">
        {Object.keys(dailies).map(key => (
          <div className="daily__everyday" key={key}>
            <MDailyTime time={key} />
            <div className="daily__list">
              {dailies[key].map(({ id, path, likes_count, url, title }) => {
                return (
                  <div className="daily__item" key={id}>
                    <a
                      className="daily__title js-open-modal"
                      href={path}
                      data-remote="true"
                    >
                      {title}
                    </a>
                    <DailyFooter
                      url={url}
                      likesCount={likes_count}
                      path={path}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

MDailies.propTypes = {
  dailies: PropTypes.array,
};

MDailies.defaultProps = {
  dailies: [],
};

export default ApolloWrapper(MDailies);
