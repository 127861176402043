import React from "react";
import PropTypes from "prop-types";

const ArticleSimpleItemLoading = props => {
  return (
    <div
      className={`column-item category-show__article u-loading ${
        props.isFetchMore ? "is-show" : ""
      }`}
    >
      <div className="article-simple__item article-simple__loading" />
    </div>
  );
};

ArticleSimpleItemLoading.propTypes = {
  isFetchMore: PropTypes.bool,
};

ArticleSimpleItemLoading.defaultProps = {
  isFetchMore: false,
};

export default ArticleSimpleItemLoading;
