import React from "react";
import { string, bool, arrayOf, object, func } from "prop-types";
import _ from "lodash";

import {
  publishedTime,
  sortAllMode,
} from "@shared/scripts/utils/site_text_constant";
import {
  setHistorySearchParam,
  // setSearchParam,
  getValueFromString,
  resetHistoryAllFilterParams,
} from "@shared/scripts/utils/url";
import * as routes from "./routes";
import SwitchButton from "../shared/SwitchButton";
import searchTip from "search-filter-tip.png";

const SelectPannel = ({ list, onItemClick, checkedValue }) => (
  <div className="search-filter__pannel">
    {Object.keys(list).map(key => {
      const isActiveClass = key === checkedValue ? "is-active" : "";
      return (
        <a
          key={key}
          className={`u-flex search-filter__inline ${isActiveClass}`}
          href="javascript:;"
          onClick={() => onItemClick(key)}
        >
          {list[key]}
          <i className="iconfont icon-true search-filter__checked" />
        </a>
      );
    })}
  </div>
);

SelectPannel.propTypes = {
  list: object.isRequired,
  checkedValue: string.isRequired,
  onItemClick: func.isRequired,
};

const Tag = ({ value, onClick, isSelected }) => {
  const onTagClick = () => onClick(value);
  return (
    <a
      href="javascript:;"
      className={`u-btn--gray search-filter__link ${
        isSelected ? "is-active" : ""
      }`}
      alt={value}
      onClick={onTagClick}
    >
      {value}
    </a>
  );
};

Tag.propTypes = {
  value: string.isRequired,
  onClick: func.isRequired,
  isSelected: bool.isRequired,
};

class SearchFilterList extends React.Component {
  static propTypes = {
    actions: object.isRequired,
    published: string.isRequired,
    tags: arrayOf(string).isRequired,
    // isExactMatch: bool.isRequired,
    sortMode: string.isRequired,
    searchInternet: bool.isRequired,
    tagList: arrayOf(string).isRequired,
    hasSearch: bool,
    disabled: bool,
  };

  static defaultProps = {
    hasSearch: false,
    disabled: false,
  };

  state = {
    showPannelName: "",
  };

  componentDidMount() {
    this.oldHistorySearch = routes.history.location.search;
    this.updateStoreUrlChange(this.oldHistorySearch);

    routes.history.listen(location => {
      const { search } = location;

      if (search === this.oldHistorySearch) {
        return;
      }

      this.oldHistorySearch = search;
      this.updateStoreUrlChange(search);
    });
  }

  onSwitchInternet = () => {
    // if (this.props.disabled) return;
    // const { history } = routes;
    /* muti filter will delete */
    // const searchParams = resetHistoryAllFilterParams(history);
    // history.push({
    //   search: setSearchParam({
    //     currentSearchString: searchParams,
    //     targetParam: "search_internet",
    //     value,
    //   }),
    // });
  };

  onExactSearchModeSwitch = isExactMatch => {
    routes.history.push({
      search: setHistorySearchParam({
        history: routes.history,
        targetParam: "is_exact_match",
        value: isExactMatch,
      }),
    });
  };

  onTagClick = tag => {
    if (this.props.disabled) return;

    let { tags } = this.props;
    if (_.includes(tags, tag)) {
      _.remove(tags, t => t === tag);
    } else {
      tags = tags.concat(tag);
    }

    routes.history.push({
      search: setHistorySearchParam({
        history: routes.history,
        targetParam: "tags[]",
        value: tags,
      }),
    });
  };

  updateStoreUrlChange = currentSearchString => {
    const {
      published,
      isExactMatch,
      tags,
      searchInternet,
      sortMode,
    } = getValueFromString(currentSearchString);
    const { actions, hasSearch } = this.props;
    if (published !== null) actions.updatePublishFilter(published);
    if (isExactMatch !== null) actions.updateMatchMode(isExactMatch);
    if (tags !== null) actions.updateTagFilter(tags);
    if (hasSearch && searchInternet !== null)
      actions.updateSearchMode(searchInternet);
    if (sortMode !== null) actions.updateSortMode(sortMode);
  };

  changeItemInPannel = (value, target) => {
    routes.history.push({
      search: setHistorySearchParam({
        history: routes.history,
        targetParam: target,
        value,
      }),
    });

    this.switchPannel("");
  };

  changePublished = value => {
    this.changeItemInPannel(value, "published");
  };

  changeSortMode = value => {
    this.changeItemInPannel(value, "sort");
  };

  resetAllMode = () => {
    if (this.props.disabled) return;

    const { history } = routes;
    history.push({
      search: resetHistoryAllFilterParams(history),
    });
  };

  switchPannel = showPannelName => {
    const oldPannelName = this.state.showPannelName;
    let newPannelName = showPannelName;
    if (oldPannelName === showPannelName) {
      newPannelName = "";
    }

    this.setState({
      showPannelName: newPannelName,
    });
  };

  render() {
    // const { published, isExactMatch, tags, sortMode, disabled, tagList } = this.props;
    const { showPannelName } = this.state;
    const {
      tags,
      tagList,
      published,
      sortMode,
      hasSearch,
      // searchInternet,
    } = this.props;

    return (
      <div className="search-filter" ref="filterPannel">
        {hasSearch && (
          <div className="search-filter__item">
            <span>全网搜索：</span>
            <div className="search-filter__right">
              <img
                className="search-filter__right-tip"
                alt="tip"
                src={searchTip}
              />
              <SwitchButton
                id="search-internet"
                // isActive={searchInternet}
                isActive={false}
                onSwitch={this.onSwitchInternet}
                theme="t-red"
              />
            </div>
          </div>
        )}
        <div className="search-filter__item">
          <span className="search-filter__left">发布时间：</span>
          <div
            className="search-filter__right"
            onClick={() => this.switchPannel("published")}
          >
            {publishedTime[published]}
            <i className="iconfont icon-right" />
            {showPannelName === "published" && (
              <SelectPannel
                list={publishedTime}
                onItemClick={this.changePublished}
                checkedValue={published}
              />
            )}
          </div>
        </div>
        <div className="search-filter__item">
          <span className="search-filter__left">排序：</span>
          <div
            className="search-filter__right"
            onClick={() => this.switchPannel("sort")}
          >
            {sortAllMode[sortMode]}
            <i className="iconfont icon-right" />
            {showPannelName === "sort" && (
              <SelectPannel
                list={sortAllMode}
                onItemClick={this.changeSortMode}
                checkedValue={sortMode}
              />
            )}
          </div>
        </div>
        {/* <div className="search-filter__item">
          <span className="search-filter__left">精确匹配：</span>
          <div className="search-filter__right">
            <SwitchButton
              id="search-accurate"
              isActive={isExactMatch}
              onSwitch={this.onExactSearchModeSwitch}
              theme="t-black"
            />
          </div>
        </div> */}
        <div className="search-filter__item">
          <span className="search-filter__left">根据标签：</span>
          <div className="search-filter__right">
            {tagList.map(tag => {
              const isActive = _.includes(tags, tag);
              return (
                <Tag
                  key={tag}
                  value={tag}
                  onClick={this.onTagClick}
                  isSelected={isActive}
                />
              );
            })}
          </div>
        </div>
        <a
          className="search-index__more search-filter__reset"
          href="javascript:;"
          onClick={this.resetAllMode}
        >
          清除结果
        </a>
      </div>
    );
  }
}

export default SearchFilterList;
