import React from "react";
import PropTypes from "prop-types";
import ActionCable from "actioncable";

const List = ({ list }) => {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>标题</th>
          <th>分类</th>
          <th>来源</th>
          <th>发布时间</th>
          <th>作者</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        {list.map(({ id, headline, type, author, source, pubdate }) => {
          if (id === undefined) return null;

          return (
            <tr key={id}>
              <td>{headline}</td>
              <td>{type}</td>
              <td>{source}</td>
              <td>{pubdate}</td>
              <td>{author}</td>
              <td>
                <a
                  className="btn btn-golden btn-sm margin-right"
                  href={`/admin/eigen_sources/${id}`}
                >
                  查看
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
};

class PushInRealTime extends React.Component {
  constructor() {
    super();
    this.state = {
      news: [],
      isFailed: false,
    };
  }

  componentDidMount() {
    this.createWebSocket();
    // if faild no render?
  }

  componentWillUnmount() {
    this.cable.subscriptions.remove(this.notification);
  }

  createWebSocket() {
    this.cable = ActionCable.createConsumer("/websocket");
    const that = this;

    this.notification = this.cable.subscriptions.create("EigenSourceChannel", {
      connected() {
        // eslint-disable-next-line no-console
        console.log("EigenSourceChannel connected");
      },
      disconnected() {
        // eslint-disable-next-line no-console
        console.log("disconnected");
        this.cable.disconnect();
        return this.uninstall();
      },
      rejected() {
        // eslint-disable-next-line no-console
        console.log("rejected");
        return this.uninstall();
      },
      away() {
        return this.perform("away");
      },
      received(data) {
        const { news } = that.state;
        that.setState({
          news: [...news, data.source],
        });
      },
      uninstall() {
        this.setData({
          isFailed: true,
        });
        this.cable.subscriptions.remove(this.notification);
        // eslint-disable-next-line no-console
        console.log("uninstall");
      },
    });
  }

  render() {
    const { news, isFailed } = this.state;

    return (
      <div className="table-bg table-responsive">
        {isFailed && <p>连接已断开,请重试！</p>}
        {news.length === 0 ? <p>暂无消息</p> : <List list={news} />}
      </div>
    );
  }
}

export default PushInRealTime;
