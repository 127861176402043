export default () => {
  if ($("#nCov")) {
    $("#ncov-tab").on("click", e => {
      if ($(e.target).attr("link") == "#ai") {
        window.scrollTo(0, $("#daily").height() + 500);
      }
      // $('#ncov-tab').find('a').each((i, dom) => {
      //   if (dom.hash == e.target.hash) {
      //     $(dom).addClass('active');
      //   } else {
      //     $(dom).removeClass('active');
      //   }
      // })
    });
    $("#ncov-footer").css("display", "flex");
    $("#footer").css("display", "none");
  } else {
    $("#ncov-footer").css("display", "none");
    $("#footer").css("display", "flex");
  }

  $(".detail-btn").on("click", e => {
    const parentDom = $(e.target).parent();
    const detailBtnDom = parentDom.find(".detail-btn");
    const hideDom = parentDom.find(".hide-part");
    $(hideDom).addClass("show-part");
    $(detailBtnDom).css("display", "none");
  });

  $(".solution-summary").each(i => {
    const solutionDetailBtnDom = $(".solution-item")
      .eq(i)
      .find(".solution-detail-btn");
    const summaryDom = $(".solution-item")
      .eq(i)
      .find(".solution-summary");
    if ($(summaryDom).height() > 58) {
      $(summaryDom).addClass("u-text-limit--three");
    } else {
      $(solutionDetailBtnDom).css("display", "none");
    }
  });

  $(".solution-detail-btn").on("click", e => {
    const parentDom = $(e.target).parent();
    const detailBtnDom = parentDom.find(".solution-detail-btn");
    const summaryDom = parentDom.find(".solution-summary");

    $(summaryDom).removeClass("u-text-limit--three");
    $(detailBtnDom).css("display", "none");
  });
};
