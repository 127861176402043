import { switchLike } from "@shared/scripts/features/api";

const action = target => {
  const $this = $(target);
  const url = $this.data("path");
  if (url === "" || url === undefined || url === null) return;

  switchLike(url, $this.hasClass("is-active")).done(res => {
    const $btns = $(`.js-like-action[data-path="${url}"]`);
    const content = res.likes_count > 0 ? res.likes_count : "";

    if (res.liked) {
      $btns.each((_, btn) => {
        const $btn = $(btn);
        $btn.addClass("is-active");
        $($btn.find(".iconfont"))
          .removeClass("icon-like-default")
          .addClass("icon-like-hover");
        $($btn.find("span")).html(content);
      });
    } else {
      $btns.each((_, btn) => {
        const $btn = $(btn);
        $btn.removeClass("is-active");
        $($btn.find(".iconfont"))
          .addClass("icon-like-default")
          .removeClass("icon-like-hover");
        $($btn.find("span")).html(content);
      });
    }
  });
};

export default action;
