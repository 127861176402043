import React from "react";
import PropTypes from "prop-types";

import ImageContainer from "./ImageContainer";

class DocumentItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { cover_image_url, path, title, description } = this.props;

    return (
      <article className="article-item__container">
        <a className="js-open-modal" href={path}>
          <ImageContainer
            url={`${cover_image_url}?imageView2/1/w/260/h/188`}
            name={title}
            className="u-opacity-effect article-item__cover"
          />
        </a>
        <main className="article-item__right">
          <section>
            <a
              className="article-item__title t-strong js-open-modal"
              href={path}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className="u-text-limit--two article-item__summary"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </section>
        </main>
      </article>
    );
  }
}

DocumentItem.propTypes = {
  cover_image_url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DocumentItem;
