import { flowRight } from "lodash";

import ResolveGraphqlDataWrapper from "./ResolveGraphqlDataWrapper";
import HasDataWrapper from "./HasDataWrapper";
import LoadingWrapper from "./LoadingWrapper";

const WaterFallWrapper = (
  params,
  LoadingComponent = null,
  ignoreNoData = false,
  maxCount = 1
) => Component => {
  return flowRight(
    LoadingWrapper,
    ResolveGraphqlDataWrapper(params, LoadingComponent),
    HasDataWrapper(ignoreNoData, maxCount)
  )(Component);
};

export default WaterFallWrapper;
