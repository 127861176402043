import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import INSIGHT_QUERY from "@graphql/insightArticles.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItem from "./ArticleItem";
import ArticleItemInMobile from "./ArticleItemInMobile";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(INSIGHT_QUERY, {
  options: props => ({
    variables: {
      cursor: props.cursor,
      count: 10,
      insight: true,
      insight_id: props.insight_id,
    },
  }),
})
@WaterFallWrapper(["articles"])
class ArticleList extends React.Component {
  ArticleItem = this.props.isMobile ? ArticleItemInMobile : ArticleItem;

  render() {
    const { edges } = this.props;
    const Article = this.ArticleItem;

    return (
      <div className="insight-item__wrapper insight-item__content t-articles">
        {edges.map(({ node }) => (
          <Article key={node.id} {...node} />
        ))}
      </div>
    );
  }
}

ArticleList.propTypes = {
  edges: PropTypes.array,
  isMobile: PropTypes.bool,
};

ArticleList.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(ArticleList);
