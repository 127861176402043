import React from "react";
import PropTypes from "prop-types";

const DailiesLoading = props => {
  return (
    <div
      className={`daily-every u-loading ${props.isFetchMore ? "is-show" : ""}`}
    >
      <div className="daily-every__list">
        <div className="daily-every__inline daily__loading" />
      </div>
    </div>
  );
};

DailiesLoading.propTypes = {
  isFetchMore: PropTypes.bool,
};

DailiesLoading.defaultProps = {
  isFetchMore: false,
};

export default DailiesLoading;
