import React from "react";

import Loading from "@components/utils/LogoLoading";
import { getUrlQuery } from "@shared/scripts/utils/tool";
import {
  getPeriodicals,
  getInnerRerencesByPeriodical,
  getInnerRerencesByCategory,
} from "@shared/scripts/features/api";
import CategoryList from "./inner_reference/CategoryList";
import PhaseList from "./inner_reference/PhaseList";
import PageBtn from "./inner_reference/PageBtn";
import PhaseContent from "./inner_reference/PhaseContent";

const tabs = ["智慧农业", "智慧金融", "智能零售", "智能制造", "智慧医疗"];

class InnerReference extends React.Component {
  state = {
    activeIndex: 0,
    activePhaseIndex: -1,
    page: 1,
    isOpenPannel: false,
    fetchTheme: "first",
    list: [],
    phaseContentList: [],
    hasNextPage: true,
  };

  componentDidMount() {
    this.categoryName = "";
    const { sequential } = getUrlQuery(window.location.search);

    if (sequential !== undefined) {
      this.fetchPeriodicals(1, {
        sequential,
      }).always(() => {
        const { list } = this.state;

        let activeId = null;
        let activeIndex = null;
        list.forEach((item, index) => {
          if (item.active) {
            activeId = item.id;
            activeIndex = index;
          }
        });
        if (activeId !== null && activeIndex !== null) {
          this.openPannel(activeId, activeIndex);
        }
      });
    } else {
      this.fetchPeriodicals(1);
    }
  }

  fetchPeriodicals = (page, data = {}) => {
    return getPeriodicals(page, data)
      .done(res => {
        const { periodicals, hasNextPage, current_page } = res;
        this.setState({
          hasNextPage,
          list: periodicals,
          activeIndex: -1,
          page: current_page,
          isOpenPannel: false,
        });
      })
      .always(() => {
        this.toggleFetching("close");
      });
  };

  fetchTopics = (
    page,
    categoryName = this.categoryName,
    index = this.state.activeIndex
  ) => {
    this.categoryName = categoryName;
    getInnerRerencesByCategory(categoryName, page)
      .done(res => {
        const { items, hasNextPage, current_page } = res;

        this.setState({
          hasNextPage,
          list: items,
          activeIndex: index,
          page: current_page,
          isOpenPannel: false,
        });
      })
      .always(() => {
        this.toggleFetching("close");
      });
  };

  fetchMore = page => {
    const { activeIndex } = this.state;
    this.toggleFetching("start", () => {
      if (activeIndex === -1) {
        this.fetchPeriodicals(page);
      } else {
        this.fetchTopics(page);
      }
    });
  };

  pagePrev = () => {
    const { page } = this.state;
    if (page === 1) return;
    this.fetchMore(page - 1);
  };

  pageNext = () => {
    const { hasNextPage, page } = this.state;
    if (!hasNextPage) return;
    this.fetchMore(page + 1);
  };

  toggleFetching = (theme, cb) => {
    this.setState(
      {
        fetchTheme: theme,
      },
      cb
    );
  };

  openPannel = (id, index) => {
    if (this.state.activePhaseIndex === index) {
      this.setState({
        isOpenPannel: false,
        activePhaseIndex: -1,
      });
      return;
    }

    getInnerRerencesByPeriodical(id).done(res => {
      this.setState({
        isOpenPannel: true,
        phaseContentList: res.items,
        activePhaseIndex: index,
      });
    });
  };

  render() {
    if (this.state.fetchTheme === "first") return <Loading />;

    const {
      activeIndex,
      page,
      isOpenPannel,
      list,
      hasNextPage,
      phaseContentList,
      activePhaseIndex,
    } = this.state;
    if (activeIndex === -1 && list.length === 0) return null;

    return [
      <div key="internal-reference-list" className="u-container">
        <div className="internal-reference">
          <div className="u-flex">
            <h1 className="u-block__title">行业内参</h1>
            <div className="u-block__right">
              <a
                className={`u-block__tab ${
                  activeIndex === -1 ? "is-active" : ""
                }`}
                href="javascript:;"
                onClick={() => this.fetchPeriodicals(1)}
              >
                全部
              </a>
              <div className="u-block__tab internal-reference__container">
                分类
                <div className="internal-reference__dropdown">
                  {tabs.map((value, index) => (
                    <a
                      key={value}
                      className={`internal-reference__category ${
                        activeIndex === index ? "is-active" : ""
                      }`}
                      href="javascript:;"
                      onClick={() => this.fetchTopics(1, value, index)}
                    >
                      {value}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="internal-reference__list">
            {this.state.fetchTheme === "start" && (
              <Loading className="t-has-white-bg" />
            )}
            {activeIndex === -1 ? (
              <PhaseList list={list} openPannel={this.openPannel} />
            ) : (
              <CategoryList list={list} />
            )}
            {page !== 1 && (
              <PageBtn
                className="internal-reference__prev"
                action={this.pagePrev}
              />
            )}
            {hasNextPage && (
              <PageBtn
                className="internal-reference__next"
                action={this.pageNext}
              />
            )}
          </div>
        </div>
      </div>,
      <PhaseContent
        key="internal-reference-phase"
        isShow={isOpenPannel}
        list={phaseContentList}
        activePhaseIndex={activePhaseIndex}
      />,
    ];
  }
}

export default InnerReference;
