import React from "react";
import PropTypes from "prop-types";

import HasDataWrapper from "../utils/HasDataWrapper";
// import SearchAddChosen from './SearchAddChosen';
import InstitutionItem from "./items/InstitutionItem";
import ArticleItem from "./items/ArticleItem";
import CardItem from "./items/CardItem";
import ExpertItem from "./items/ExpertItem";
import PaperItem from "./items/PaperItem";
import SolutionItem from "../shared/SolutionItem";
import UserItem from "./items/UserItem";

const theme = {
  institutions: () => InstitutionItem,
  articles: () => ArticleItem,
  technologies: () => CardItem,
  experts: () => ExpertItem,
  papers: () => PaperItem,
  solutions: () => SolutionItem,
  users: () => UserItem,
};

@HasDataWrapper(true)
class CategoryList extends React.Component {
  Item = theme[this.props.params]();

  render() {
    const { edges, searchInternet, keywords } = this.props;
    const { Item } = this;

    return edges.map((edge, index) => (
      <div key={edge.id} className="search-record__item">
        {/* <SearchAddChosen path={`/${params}/${edge.id}`} /> */}
        <Item
          {...edge}
          index={index}
          searchInternet={searchInternet}
          keywords={keywords}
        />
      </div>
    ));
  }
}

CategoryList.propTypes = {
  edges: PropTypes.array,
  searchInternet: PropTypes.bool,
  params: PropTypes.string.isRequired,
  keywords: PropTypes.string,
};

CategoryList.defaultProps = {
  edges: [],
  searchInternet: false,
  keywords: "",
};

export default CategoryList;
