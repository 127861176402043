import React from "react";
import PropTypes from "prop-types";
import ImageContainer from "../../ImageContainer";
import GraphHeader from "./GraphHeader";
import AboutTechnologies from "../../graph/AboutTechnologies";
import AboutInstitutions from "../../graph/AboutInstitutions";

const Expert = ({
  id,
  name,
  summary,
  institutions,
  avatar_url,
  technologies,
  completeness,
}) => {
  return (
    <div className="search-panel__graph">
      <div className="u-flex">
        <div>
          <GraphHeader
            id={id}
            type="人物"
            name={name}
            summary={summary}
            completeness={completeness}
          />
        </div>
        <ImageContainer
          url={avatar_url}
          name={name}
          className="tips-card__avatar"
        />
      </div>
      <AboutTechnologies technologies={technologies} />
      <AboutInstitutions institutions={institutions} />
    </div>
  );
};

Expert.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  institutions: PropTypes.array,
  avatar_url: PropTypes.string,
  technologies: PropTypes.array,
  completeness: PropTypes.string,
};

Expert.defaultProps = {
  summary: "",
  institutions: [],
  avatar_url: "",
  technologies: [],
  completeness: "level2",
};

export default Expert;
