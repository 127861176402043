import React from "react";
import PropTypes from "prop-types";

const ImageLinkContainer = ({ path, url, name, className, type }) => {
  return (
    <a className={`u-${type}-base ${className}`} href={path} target="_blank">
      <img className="u-image-center" src={url} alt={name} />
    </a>
  );
};

ImageLinkContainer.propTypes = {
  path: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};

ImageLinkContainer.defaultProps = {
  path: "",
  url: "",
  name: "",
  className: "",
  type: "image",
};

export default ImageLinkContainer;
