import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import "react-select/dist/react-select.css";

class SearchSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      model: props.model,
    };
  }

  onChange(value) {
    this.setState({
      value,
    });
  }

  getData(keyword, url) {
    let modelName = url;
    let insightOnly = false;
    let exploreOnly = false;
    if (this.props.hasType) {
      modelName = $("#js-fetch-type").val();
      const category = $("#banner-category-type").val();
      insightOnly = category === "insight";
      exploreOnly = category === "explore";
    }

    if (!keyword) {
      return Promise.resolve({ options: [] });
    }
    let fetch_url = "";
    if (this.state.model === "tag" || this.state.model === "special_column") {
      fetch_url = `/admin/${url}?q[name_or_pinyin_or_pinyin_abbr_cont]=${keyword}`;
    } else if (this.state.model === "user") {
      fetch_url = `/admin/${url}?q[name_or_username_or_pinyin_or_pinyin_abbr_cont]=${keyword}`;
    } else if (this.state.model === "article") {
      fetch_url =
        modelName === "advertisements"
          ? `/admin/${modelName}?q[title_cont]=${keyword}`
          : `/admin/${modelName}?q[title_cont]=${keyword}&q[published]=true`;
      if (modelName === "articles" && insightOnly === true) {
        fetch_url += "&q[is_insight_true]=1";
      }
      if (modelName === "articles" && exploreOnly === true) {
        fetch_url += "&q[explored_true]=1";
      }
      if (modelName === "pro/researches" && insightOnly === true) {
        fetch_url += "&q[insightable_true]=1";
      }
    } else if (this.state.model === "inner_reference") {
      fetch_url = `/admin/${url}/inner_reference`;
    } else if (this.state.model === "article_filter") {
      fetch_url = `/${url}?q=${keyword}`;
    } else if (this.state.model === "periodical") {
      fetch_url = `/admin/${url}?q=${keyword}`;
    } else if (this.state.model === "dynamic") {
      fetch_url = `/admin/${modelName}?q[title_cont]=${keyword}&status=published`;
    } else if (this.state.model === "institutions") {
      fetch_url = `/admin/graph/${url}?name=${keyword}&account_uniq=true`;
    } else if (this.state.model === "partner") {
      fetch_url = `/admin/${url}?q[name_or_url_cont]=${keyword}`;
    } else if (this.state.model === "topic") {
      fetch_url = `/admin/${url}/card?q[title_cont]=${keyword}`;
    } else if (this.state.model === "guest") {
      fetch_url = `/admin/${url}?q[name_or_company_cont]=${keyword}`;
    } else if (this.state.model === "chart") {
      fetch_url = `/admin/pro/researches/charts?q[title_cont]=${keyword}`;
    } else if (this.state.model === "document") {
      fetch_url = `/admin/pro/researches/documents?q[title_cont]=${keyword}`;
    } else if (this.state.model === "dataset") {
      fetch_url = `/admin/pro/datasets?name=${keyword}`;
    } else if (this.state.model === "member") {
      fetch_url = `/admin/${url}/member?q[name_or_username_or_pinyin_or_pinyin_abbr_cont]=${keyword}`;
    }
    return fetch(fetch_url, {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    })
      .then(response => response.json())
      .then(json => {
        // eslint-disable-next-line no-console
        console.log(json[modelName]);
        return { options: json[modelName] };
      });
  }

  render() {
    const placeholder = "支持中文/拼音搜索";
    const { name, url, multi, creatable, joinValues, valueKey } = this.props;
    let labelKey = "";
    if (
      this.state.model === "article" ||
      this.state.model === "inner_reference" ||
      this.state.model === "article_filter" ||
      this.state.model === "periodical" ||
      this.state.model === "dynamic" ||
      this.state.model === "chart" ||
      this.state.model === "document" ||
      this.state.model === "topic"
    ) {
      labelKey = "title";
    } else if (this.state.model === "tag") {
      labelKey = "selector_name";
    } else {
      labelKey = "name";
    }
    const Selector = creatable ? Select.AsyncCreatable : Select.Async;

    return (
      <Selector
        multi={multi}
        value={this.state.value}
        placeholder={placeholder}
        onChange={val => {
          this.onChange(val);
        }}
        valueKey={valueKey}
        delimiter=","
        joinValues={joinValues}
        labelKey={labelKey}
        name={name}
        filterOptions={creatable ? undefined : false}
        loadOptions={keyword => {
          return this.getData(keyword, url);
        }}
      />
    );
  }
}

SearchSelector.propTypes = {
  multi: PropTypes.bool,
  joinValues: PropTypes.bool,
  creatable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  url: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string,
  valueKey: PropTypes.string,
  hasType: PropTypes.bool,
};

SearchSelector.defaultProps = {
  multi: true,
  creatable: false,
  joinValues: false,
  value: [],
  url: "",
  model: "",
  name: "",
  valueKey: "id",
  hasType: false,
};

export default SearchSelector;
