import React from "react";
import PropTypes from "prop-types";

import {
  vercode_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";

class VerificationCode extends React.Component {
  submit() {
    if (!this.props.isFormValidate()) return;

    this.props.sendVerify(this.props.data.reset_code.value);
  }

  render() {
    const {
      data: { reset_code },
      changeValue,
    } = this.props;

    return (
      <form
        ref="form"
        action="/verify_code"
        className="signin-form js-forget-form"
      >
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...reset_code}
          onChange={value => {
            changeValue(value, "reset_code");
          }}
        />
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={() => {
            this.submit();
          }}
        >
          下一步
        </button>
      </form>
    );
  }
}

VerificationCode.propTypes = {
  sendVerify: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  reset_code: buildRules("reset_code", "验证码", [vercode_rule]),
})(VerificationCode);
