import React from "react";
import PropTypes from "prop-types";

import ReduxWrapper from "../utils/ReduxWrapper";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";
import BindPhoneForm from "./BindPhoneForm";

class SignModal extends React.Component {
  // signin register phone
  state = {
    model: "signin",
  };

  switchState(type) {
    this.setState({
      model: type,
    });
  }

  render() {
    const { closeModal } = this.props.actions;
    const { model } = this.state;

    const forms = {
      signin: <SigninForm switchState={() => this.switchState("register")} />,
      register: <SignupForm switchState={() => this.switchState("signin")} />,
      phone: <BindPhoneForm switchState={() => this.switchState("register")} />,
    };

    return (
      <div
        className={`sota-signin-modal js-signin-modal ${model === "phone" &&
          "signin-modal--phone"}`}
      >
        <div className="signin-modal__left">
          {forms[model]}
          {/* <a
            className="signin-form__btn weibo"
            href="/auth/weibo"
            title="微博登录"
          >
            <i className="iconfont icon-weibo" />
          </a> */}
        </div>
        <div className="signin-modal__right">
          <div className="signin-modal__body" />
        </div>
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>
    );
  }
}

SignModal.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(SignModal);
