import React from "react";
import ProduceFilter from "./ProduceFilter";
import SolutionItem from "./item/SolutionItem";

const categories = [
  { id: "industries", name: "行业", icon: "icon-lingyu", hasFilter: false },
  { id: "scenarios", name: "场景", icon: "icon-Scenes", hasFilter: true },
  { id: "technologies", name: "技术", icon: "icon-jishu", hasFilter: true },
  { id: "institutions", name: "机构", icon: "icon-jigou1", hasFilter: true },
];

const Solutions = props => {
  const Container = ProduceFilter(categories, SolutionItem, "solutions");
  return <Container {...props} />;
};

export default Solutions;
