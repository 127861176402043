import React from "react";
import PropTypes from "prop-types";

class FormInput extends React.Component {
  state = {
    isFocus: false,
  };

  inputFocus = () => {
    this.setState({
      isFocus: true,
    });
  };

  inputChange = event => {
    this.setState({ isFocus: false });
    this.props.changeHandler(event);
  };

  render() {
    const { label, wrong, name, value, required } = this.props;
    const { isFocus } = this.state;

    return (
      <div className="insight-modal__group">
        <div className={`insight-modal__label ${required ? "required" : ""}`}>
          {label}：
        </div>
        <div
          className={`insight-modal__input ${isFocus ? "t-focus" : ""} ${
            !wrong ? "" : "is-error"
          }`}
        >
          <input
            type="text"
            className="u-reset-input insight-modal__value"
            name={name}
            defaultValue={value}
            placeholder={`请输入${label}`}
            onFocus={this.inputFocus}
            onChange={event => this.inputChange(event)}
          />
          <span className="insight-modal__error">请输入正确的{label}</span>
        </div>
      </div>
    );
  }
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  wrong: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export default FormInput;
