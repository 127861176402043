import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

class Paginate extends React.Component {
  handlePageClick = target => {
    this.props.loadPaginate(Number(target.selected));
  };

  render() {
    const { pageCount } = this.props;
    if (pageCount === 0) return null;

    const { initialPage, forcePage } = this.props;
    return (
      <ReactPaginate
        containerClassName="industry-paginate"
        pageClassName="industry-paginate__item"
        breakClassName="industry-paginate__item"
        pageLinkClassName="industry-paginate__inline"
        previousClassName="industry-paginate__item t-prev-btn"
        nextClassName="industry-paginate__item "
        disabledClassName="is-disabled"
        activeClassName="is-active"
        previousLabel={<i className="iconfont icon-prev3" />}
        nextLabel={<i className="iconfont icon-next2" />}
        breakLabel="..."
        disableInitialCallback
        initialPage={initialPage}
        forcePage={forcePage}
        pageCount={pageCount}
        onPageChange={this.handlePageClick}
      />
    );
  }
}

Paginate.propTypes = {
  initialPage: PropTypes.number,
  pageCount: PropTypes.number,
  loadPaginate: PropTypes.func.isRequired,
  forcePage: PropTypes.number,
};

Paginate.defaultProps = {
  forcePage: undefined,
  initialPage: 0,
  pageCount: 12,
};

export default Paginate;
