import React from "react";
import PropTypes from "prop-types";

import LikeAndCommentAction from "./LikeAndCommentAction";

class ReportItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.path === nextProps.path) {
      return false;
    }
    return true;
  }

  render() {
    const { path, title, description, created_at } = this.props;

    return (
      <div className="report-item">
        <a
          className="article-item__title"
          href={path}
          alt={title}
          target="_blank"
        >
          {title}
          <span className="report__icon">
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#coloricon-pdf-" />
            </svg>
          </span>
        </a>
        <div className="u-text-limit--two u-base-summary">{description}</div>
        <div className="u-flex">
          <time className="article-item__publish">{$.timeago(created_at)}</time>
          <div className="article-item__other">
            <LikeAndCommentAction {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

ReportItem.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  created_at: PropTypes.string,
};

ReportItem.defaultProps = {
  description: "",
  created_at: "",
};

export default ReportItem;
