import React from "react";
import PropTypes from "prop-types";

class CardItem extends React.PureComponent {
  render() {
    const { name, summary } = this.props;

    return (
      <div className="search-record__inner">
        <a className="article-item__title" href="javascript:;">
          {name}
        </a>
        <p
          className="u-text-limit--three u-base-summary search-index__more search-record__summary"
          dangerouslySetInnerHTML={{ __html: summary }}
        />
      </div>
    );
  }
}

CardItem.propTypes = {
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
};

CardItem.defaultProps = {
  summary: null,
};

export default CardItem;
