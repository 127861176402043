import React from "react";
import PropTypes from "prop-types";

const SortHeader = ({ totalCount, typeName }) => {
  return (
    <div className="u-flex industry-result--header">
      <span>
        共&nbsp;
        <b className="industry-result--header__count">{totalCount}</b>
        &nbsp;条
        {typeName}
      </span>
      {/* <div>
        <a className={`industry-result--header__action ${sort === 'time' ? 'is-active' : ''}`} href="javascript:;" onClick={() => switchSort('time')}>
          <i className="iconfont icon-hot u-mr-6" />
          按时间排序
        </a>
        <a className={`industry-result--header__action ${sort === 'heat' ? 'is-active' : ''}`} href="javascript:;" onClick={() => switchSort('heat')}>
          <i className="iconfont icon-hot u-mr-6" />
          按机构热度排序
        </a>
      </div> */}
    </div>
  );
};

SortHeader.propTypes = {
  // switchSort: PropTypes.func.isRequired,
  // sort: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  typeName: PropTypes.string,
};

SortHeader.defaultProps = {
  typeName: "数据",
};

export default SortHeader;
