import React from "react";
import { getSearchResultByKeyword } from "@shared/scripts/features/api";

class SearchModal extends React.Component {
  state = {
    keyword: "",
    categories: [],
    tags: [],
  };

  changeHandle = value => {
    this.setState({ keyword: value });

    if (typeof this.timer !== "undefined") clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    //   this.fetchData(value).always(() => {
    //     clearTimeout(this.timer);
    //   });
    // }, 300);
  };

  fetchData = keyword => {
    getSearchResultByKeyword(keyword).done(data => {
      const { categories, tags } = data;
      this.setState({ categories, tags });
    });
  };

  addFocus = e => {
    e.preventDefault();
    this.refs.searchInput.focus();
  };

  render() {
    const { categories, tags, keyword } = this.state;

    return (
      <form className="header-search" action="/search/all">
        <a
          className="header-search__btn is-first"
          href="javascript:;"
          alt="搜索"
        >
          <i className="iconfont icon-sreach" />
        </a>
        <input
          ref="searchInput"
          className="header-search__input"
          type="text"
          name="keywords"
          placeholder="搜索"
          value={this.state.keyword}
          onChange={e => {
            this.changeHandle(e.target.value);
          }}
        />
        <a
          className="header-search__btn js-switch-search"
          href="javascript:;"
          alt="关闭搜索"
        >
          <i className="iconfont icon-close" />
        </a>
        <div
          className={`header-search__box ${keyword === "" ? "" : "is-visible"}`}
          ref="searchBox"
        >
          <a
            className="header-search__item"
            href={`/search/article?keywords=${encodeURI(keyword)}`}
          >
            搜索&nbsp;“
            <b>{keyword}</b>”
          </a>
          {categories.length > 0 && (
            <div className="header-search__sub">分类</div>
          )}
          {categories.map(category => (
            <a
              key={category.id}
              className="header-search__item"
              href={`/categories/${category.name}`}
            >
              <div className="header-search__main">{category.name}</div>
            </a>
          ))}
          {tags.length > 0 && <div className="header-search__sub">标签</div>}
          {tags.map(tag => (
            <a key={tag.id} className="header-search__item" href="/categories">
              <div className="header-search__main">{tag.name}</div>
            </a>
          ))}
        </div>
      </form>
    );
  }
}

export default SearchModal;
