import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import COLUMN_QUERY from "@graphql/column.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItemLoading from "../loading/ArticleItemLoading";
import ArticleItem from "../shared/ArticleItem";
import MArticleItem from "../shared/MArticleItem";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import SkipWrapper from "../utils/SkipWrapper";

@SkipWrapper
@graphql(COLUMN_QUERY, {
  options: props => ({
    variables: {
      id: props.id,
      count: 10,
      cursor: "MTI =",
    },
  }),
})
@WaterFallWrapper(["column", "articles"], ArticleItemLoading)
class ColumnShow extends React.Component {
  articleItem = this.props.isMobile ? MArticleItem : ArticleItem;

  render() {
    const Article = this.articleItem;

    return this.props.edges.map(edge => (
      <Article key={edge.node.id} {...edge.node} theme="author" />
    ));
  }
}

ColumnShow.propTypes = {
  edges: PropTypes.array,
  isMobile: PropTypes.bool,
};

ColumnShow.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(ColumnShow);
