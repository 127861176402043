import React from "react";
import ProduceFilter from "./ProduceFilter";
import InstitutiontItem from "./item/InstitutionItem";

const categories = [
  {
    id: "rounds",
    name: "获投状况",
    icon: "icon-huotouqingkuang",
    hasFilter: false,
  },
  { id: "industries", name: "行业", icon: "icon-lingyu", hasFilter: false },
  { id: "technologies", name: "技术", icon: "icon-jishu", hasFilter: true },
];

const Institutions = props => {
  const Container = ProduceFilter(categories, InstitutiontItem, "institutions");
  return <Container {...props} />;
};

export default Institutions;
