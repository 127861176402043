/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";
import { deleteTarget } from "@shared/scripts/features/api";

class SettingArticleItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.id === nextProps.id) {
      return false;
    }
    return true;
  }

  status = () => {
    const {
      deleted_at,
      path,
      likes_count,
      comments_count,
      status,
    } = this.props;
    if (deleted_at) {
      return (
        <div className="article-item__state is-red">
          <i className="iconfont icon-prompt u-mr-6" />
          已删除
        </div>
      );
    }

    switch (status) {
      case "published":
        return (
          <div className="article-item__state">
            <a
              className="u-like-icon article-item__link js-like-action"
              href="javascript:;"
              data-path={path}
            >
              <i className="iconfont icon-like-default" />
              <span>{likes_count}</span>
            </a>
            <a
              className="u-like-icon article-item__link"
              href={`${path}#comment`}
            >
              <i className="iconfont icon-message-default" />
              {comments_count}
            </a>
          </div>
        );
      case "failed":
        return (
          <div className="article-item__state is-red">
            <i className="iconfont icon-prompt u-mr-6" />
            审核未通过
          </div>
        );
      case "draft":
        return (
          <div className="article-item__state is-red">
            <i className="iconfont icon-prompt u-mr-6" />
            草稿
          </div>
        );
      case "pending":
        return (
          <div className="article-item__state is-draft">
            <i className="iconfont icon-prompt u-mr-6" />
            待审核
          </div>
        );
      default:
        return (
          <div className="article-item__state is-red">
            <i className="iconfont icon-prompt u-mr-6" />
            未知状态
          </div>
        );
    }
  };

  deleteArticle = () => {
    const { path, baseUrl } = this.props;
    if (confirm("是否删除此文章")) {
      deleteTarget(`${baseUrl}${path}`).done(() => {
        Salert.success("删除成功");
        location.reload();
      });
    }
  };

  render() {
    const {
      path,
      cover_image_url,
      title,
      deleted_at,
      baseUrl,
      id,
      status,
    } = this.props;
    const hrefLink = status === "published" ? path : "javascript:;";

    return (
      <div className="article-item__container">
        <div className="article-item__cover">
          <a
            className="u-image-cover"
            href={hrefLink}
            style={{ backgroundImage: `url(${cover_image_url})` }}
            alt={title}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
        <div className="article-item__right">
          <a
            className="article-item__title"
            href={hrefLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
          {this.status()}
        </div>
        <div className="article-item__operates">
          {!deleted_at && (
            <a
              className="article-item__operate"
              href="javascript:;"
              alt="删除"
              onClick={this.deleteArticle}
            >
              <i className="iconfont icon-delete-default" />
            </a>
          )}
          <a
            className="article-item__operate"
            href={`${baseUrl}/articles/${id.replace(/^Article\//, "")}/edit`}
            alt="编辑"
          >
            <i className="iconfont icon-edit1" />
          </a>
        </div>
      </div>
    );
  }
}

SettingArticleItem.propTypes = {
  path: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cover_image_url: PropTypes.string,
  title: PropTypes.string,
  deleted_at: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  likes_count: PropTypes.number,
  comments_count: PropTypes.number,
};

SettingArticleItem.defaultProps = {
  cover_image_url: "",
  title: "",
  deleted_at: null,
  likes_count: 0,
  comments_count: 0,
};

export default SettingArticleItem;
