import React from "react";
import PropTypes from "prop-types";
import no_data from "no_data.png";

const NoData = ({ className }) => {
  return (
    <div className={`notice-none ${className}`}>
      <img
        className="notice-none__cover"
        src={no_data}
        width="150"
        alt="暂无数据"
      />
      <p className="notice-none__title">暂无数据</p>
    </div>
  );
};

NoData.propTypes = {
  className: PropTypes.string,
};

NoData.defaultProps = {
  className: "is-container",
};

export default NoData;
