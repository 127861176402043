import URLSearchParams from "url-search-params";
import { union } from "lodash";

export const setSearchParam = ({ currentSearchString, targetParam, value }) => {
  const searchParams = new URLSearchParams(currentSearchString);

  if (Array.isArray(value)) {
    searchParams.delete(targetParam);
    value.forEach(item => searchParams.append(targetParam, item));
  } else {
    searchParams.set(targetParam, value);
  }
  return searchParams.toString();
};

// newParams: [{target: 'search', value: ''}]
export const setMultiSearchParam = (searchString, newParams = []) => {
  return newParams.reduce(
    (currentSearchString, { target, value }) =>
      setSearchParam({ currentSearchString, targetParam: target, value }),
    searchString
  );
};

export const deleteSearchParam = ({ currentSearchString, targetParam }) => {
  const searchParams = new URLSearchParams(currentSearchString);
  searchParams.delete(targetParam);
  return searchParams.toString();
};

export const deleteMutiSearchParam = (searchString, newParams = []) => {
  return newParams.reduce(
    (currentSearchString, target) =>
      deleteSearchParam({ currentSearchString, targetParam: target }),
    searchString
  );
};

export const setHistorySearchParam = ({ history, targetParam, value }) =>
  setSearchParam({
    currentSearchString: history.location.search,
    targetParam,
    value,
  });

export const deleteHistorySearchParam = (history, targetParam) =>
  deleteSearchParam({
    currentSearchString: history.location.search,
    targetParam,
  });

export const resetHistoryAllFilterParams = history =>
  deleteMutiSearchParam(history.location.search, [
    "tags[]",
    "sort",
    "is_exact_match",
    "published",
  ]);

export const getValueFromString = urlQueryString => {
  const searchParams = new URLSearchParams(urlQueryString);
  const searchInternet = searchParams.get("search_internet") || "true";
  return {
    sortMode: searchParams.get("sort") || "overall",
    searchInternet: searchInternet === "true",
    isExactMatch: searchParams.get("is_exact_match") === "true",
    published: searchParams.get("published") || "0",
    tags: union(searchParams.getAll("tags[]")),
  };
};

export const getParamsFromHistory = (history, params) => {
  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get(params) || "";
};

export const getKeywordsFromHistory = history =>
  getParamsFromHistory(history, "keywords");
