import { pageScrollToTarget, setActiveNav } from "@shared/scripts/utils/tool";

const catalog = () => {
  const $window = $(window);
  const $catalog = $("#js-catalog-container");
  const offsetTop = $("#js-graph-header").innerHeight();
  const $catalogItems = $(".js-graph-catalog");
  const $blocks = $(".js-graph-block");
  const offsetHeight = 64; // header height
  const secDirectory = $("#js-graph-content h2");
  let activeIndex = 0;

  // add content directory
  if (secDirectory.length > 0) {
    const $directoryContainer = $("#js-content-directory");
    let directHtml = "";
    secDirectory.each((index, item) => {
      directHtml += `<div class="graph-catalog__sub" data-index="${index}">${item.innerHTML}</div>`;
    });

    $directoryContainer.html(directHtml);

    // add event
    $directoryContainer.on("click", ".graph-catalog__sub", function() {
      const index = this.getAttribute("data-index");
      const $target = $($("#js-graph-content h2")[index]);
      pageScrollToTarget($target.offset().top - offsetHeight, 800);
    });
  }

  $window.on("scroll", () => {
    const scrollTop = $window.scrollTop();

    $catalog.toggleClass("is-fixed", scrollTop >= offsetTop);
    activeIndex = setActiveNav(
      $blocks,
      $catalogItems,
      scrollTop,
      offsetHeight,
      activeIndex
    );
  });

  $catalogItems.on("click", function() {
    const $target = $($(this).data("target"));
    pageScrollToTarget($target.offset().top - offsetHeight, 800);
  });

  $window.scroll();
};

export default catalog;
