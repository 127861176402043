import React from "react";
import PropTypes from "prop-types";

const Header = ({ title, desc }) => {
  return (
    <div className="insight-modal__header">
      <h5 className="insight-modal__title">{title}</h5>
      <div className="insight-modal__desc">{desc}</div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default Header;
