import React from "react";
import PropTypes from "prop-types";

const LikeAndCommentAction = props => {
  const { path, likes_count, comments_count, className, iconClassName } = props;

  return (
    <span className={className}>
      <a
        className={`u-like-icon ${iconClassName} js-like-action`}
        href="javascript:;"
        data-path={path}
      >
        <i className="iconfont icon-like-default" />
        <span>{likes_count > 0 && likes_count}</span>
      </a>
      <a className={`u-like-icon ${iconClassName}`} href={`${path}#comment`}>
        <i className="iconfont icon-message-default" />
        {comments_count > 0 && comments_count}
      </a>
    </span>
  );
};

LikeAndCommentAction.propTypes = {
  path: PropTypes.string.isRequired,
  likes_count: PropTypes.number,
  comments_count: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

LikeAndCommentAction.defaultProps = {
  likes_count: 0,
  comments_count: 0,
  className: "",
  iconClassName: "article-item__link",
};

export default LikeAndCommentAction;
