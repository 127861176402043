import React from "react";
import PropTypes from "prop-types";

const Technologies = ({ technologies }) => {
  if (technologies.length === 0) return null;

  return (
    <span className="industry-solution__tags--industry">
      <span className="industry-solution__label">技术：</span>
      {technologies.map(({ id, name }) => (
        <a
          key={id}
          href={`/graph/technologies/${id}`}
          alt={name}
          className="industry__tag"
          target="_blank"
        >
          {name}
        </a>
      ))}
    </span>
  );
};

Technologies.propTypes = {
  technologies: PropTypes.array,
};

Technologies.defaultProps = {
  technologies: [],
};

export default Technologies;
