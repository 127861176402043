import React from "react";
import PropTypes from "prop-types";
import cover_default from "image_default/article_cover_default.svg";
import LikeAndCommentAction from "../../shared/LikeAndCommentAction";
import ImageLinkContainer from "../../shared/ImageLinkContainer";

class MPublishArticle extends React.PureComponent {
  render() {
    const { path, title, cover_image_url, description } = this.props;

    return (
      <div className="user-show__article">
        <a
          className="u-text-limit--two article-mini__title"
          href={path}
          alt="文章"
        >
          {title}
        </a>
        <div className="u-flex article-mini__center">
          <ImageLinkContainer
            path={path}
            url={cover_image_url}
            name={title}
            className="article-mini__cover"
          />
          <a className="u-text-limit--four article-mini__summary">
            {description}
          </a>
        </div>
        <LikeAndCommentAction
          {...this.props}
          className="article-mini__action"
        />
      </div>
    );
  }
}

MPublishArticle.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cover_image_url: PropTypes.string,
};

MPublishArticle.defaultProps = {
  cover_image_url: cover_default,
  description: "",
};

export default MPublishArticle;
