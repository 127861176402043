import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";
import success_image from "insight/success.svg";
import { submitInsightForm } from "@shared/scripts/features/api";

import Header from "./Header";
import SwitchButton from "../../shared/SwitchButton";

class Complete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.msg.reveal || false,
    };
  }

  onSwitchOpen = () => {
    const nextStatus = !this.state.isOpen;

    submitInsightForm({ reveal: nextStatus })
      .done(() => {
        this.setState({
          isOpen: nextStatus,
        });
      })
      .fail(() => {
        Salert.error("请重试");
      });
  };

  render() {
    const { isOpen } = this.state;
    const {
      currentUser,
      className,
      msg: { pubinfo },
      hasFooter,
      next,
    } = this.props;

    return (
      <div className={`insight-modal__complete ${className}`}>
        <img
          className="insight-modal__complete--image"
          src={success_image}
          alt="认证成功"
        />
        <Header title="恭喜你，专业认证成功" desc="快去阅读你感兴趣的内容吧" />

        <div className="u-flex insight-modal__open">
          <span className="insight-modal__sub">在评论时呈现我的职务信息</span>
          <SwitchButton
            theme="t-sub-black"
            isActive={isOpen}
            onSwitch={this.onSwitchOpen}
          />
        </div>

        <div className="insight-modal__user">
          <div className="u-avatar-base insight-modal__avatar">
            <img
              className="u-image-center"
              src={currentUser.avatar_url}
              alt={currentUser.nickname}
            />
          </div>
          <div>
            {isOpen ? (
              <>
                <div>
                  <span className="insight-modal__nickname">
                    {currentUser.nickname}
                  </span>
                  <span className="insight--pro">Pro</span>
                </div>
                <div className="insight-modal__msg">{pubinfo}</div>
              </>
            ) : (
              <span className="insight-modal__nickname">
                {currentUser.nickname}
              </span>
            )}
          </div>
        </div>
        {hasFooter && (
          <div className="insight-modal__footer">
            <button
              type="button"
              className="u-reset-button u-btn--black insight-modal__btn"
              onClick={next}
            >
              继续浏览
            </button>
          </div>
        )}
      </div>
    );
  }
}

Complete.propTypes = {
  currentUser: PropTypes.object.isRequired,
  msg: PropTypes.object,
  className: PropTypes.string,
  hasFooter: PropTypes.bool,
  next: PropTypes.func,
};

Complete.defaultProps = {
  msg: {},
  className: "",
  hasFooter: true,
  next: () => {},
};

export default Complete;
