import React from "react";
import PropTyeps from "prop-types";

class ArticleItem extends React.PureComponent {
  render() {
    const {
      title,
      type,
      published_at,
      author,
      path,
      isCategoryAll,
    } = this.props;

    return (
      <div className="search-result__item">
        <a
          className="search-result__title u-text-limit--two"
          href={path}
          target="_blank"
        >
          {title}
        </a>
        <div className="u-flex search-result__footer">
          <span className="u-mute-link">
            {isCategoryAll ? (
              <span className="search-result__type">{type}</span>
            ) : (
              author
            )}
            &nbsp; · &nbsp;
            <time>{$.timeago(published_at)}</time>
          </span>
        </div>
      </div>
    );
  }
}

ArticleItem.propTypes = {
  isCategoryAll: PropTyeps.bool,
  type: PropTyeps.string,
  title: PropTyeps.string.isRequired,
  published_at: PropTyeps.string,
  author: PropTyeps.string,
  path: PropTyeps.string,
};

ArticleItem.defaultProps = {
  isCategoryAll: false,
  published_at: null,
  author: "",
  type: "",
  path: "javascript:;",
};

export default ArticleItem;
