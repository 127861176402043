import React from "react";
import PropTypes from "prop-types";

import EmailForm from "./EmailForm";

const EmailModal = props => {
  const {
    actions: { closeModal },
    ...otherProps
  } = props;
  return (
    <div className="user-set__modal">
      <a
        className="signin-modal__close js-switch-sign"
        href="javascript:;"
        onClick={closeModal}
      >
        <i className="iconfont icon-iconguanbi" />
      </a>
      <div className="forget__container">
        <EmailForm {...otherProps} closeModal={closeModal} />
      </div>
    </div>
  );
};

EmailModal.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchDate: PropTypes.func.isRequired,
};

export default EmailModal;
