import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const MDailyTime = props => {
  const { time } = props;

  return (
    <div className="daily-time">
      <i className="iconfont icon-riliriqi daily-time__icon" />
      <span>{dayjs(time).format("M月D日")}</span>
    </div>
  );
};

MDailyTime.propTypes = {
  time: PropTypes.string.isRequired,
};

export default MDailyTime;
