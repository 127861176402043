import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";

import MOMENTS_QUERY from "@graphql/timelineByCategory.graphql";
import ApolloWrapper from "../utils/ApolloWrapper";
import ArticleItemLoading from "../loading/ArticleItemLoading";
import SkipWrapper from "../utils/SkipWrapper";
import WaterFallWrapper from "../utils/WaterFallWrapper";
import ArticleItem from "../shared/ArticleItem";
import MArticleItem from "../shared/MArticleItem";
import TopicItem from "../shared/TopicItem";
import ReportItem from "../shared/ReportItem";

@SkipWrapper
@graphql(MOMENTS_QUERY, {
  options: props => ({
    variables: {
      cursor: "MTI=",
      count: 12,
      category: props.category,
    },
  }),
})
@WaterFallWrapper(["timelines"], ArticleItemLoading)
class MembershipTimeline extends React.Component {
  articleItem = this.props.isMobile ? MArticleItem : ArticleItem;

  render() {
    const { edges } = this.props;
    const Article = this.articleItem;
    return edges.map(edge => {
      const { node } = edge;
      let content = null;

      if (node.content_type === "Article") {
        content = (
          <Article
            {...node.content}
            isHasCategory={false}
            isHasAuthor={false}
            theme="time"
          />
        );
      } else if (node.content_type === "Topic") {
        content = <TopicItem {...node.content} />;
      } else if (node.content_type === "Report") {
        content = <ReportItem {...node.content} />;
      }

      return (
        <div className="member-category__item" key={node.id}>
          {content}
        </div>
      );
    });
  }
}

MembershipTimeline.propTypes = {
  edges: PropTypes.array,
  isMobile: PropTypes.bool,
};

MembershipTimeline.defaultProps = {
  edges: [],
  isMobile: false,
};

export default ApolloWrapper(MembershipTimeline);
