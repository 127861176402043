const column = () => {
  const $container = $("#js-column-header");
  const $window = $(window);
  const headerHeight = Math.ceil($container.innerHeight() - 60);

  $window.on("scroll", () => {
    if (Math.ceil($window.scrollTop()) >= headerHeight) {
      $container.addClass("is-fixed");
    } else {
      $container.removeClass("is-fixed");
    }
  });
};

export default column;
