import React from "react";
import PropTypes from "prop-types";
import { WORK, STUDENT } from "@shared/scripts/utils/contant";
import student_status_image from "insight/student.svg";
import work_status_image from "insight/work.svg";

import Header from "./Header";

class Step1 extends React.Component {
  next = () => {
    const { goStep2 } = this.props;
    goStep2();
  };

  render() {
    const { checkStatus, setCheckStatus } = this.props;

    return (
      <div className="insight-modal__step">
        <Header
          title="请选择你的身份"
          desc="我们会对不同身份提供更精准的信息服务"
        />

        <div className="u-flex insight-modal__allstatus">
          <button
            className={`u-reset-button insight-modal__status ${
              checkStatus === WORK ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setCheckStatus(WORK)}
          >
            <img src={work_status_image} width="100%" alt="work" />
          </button>
          <button
            className={`u-reset-button insight-modal__status ${
              checkStatus === STUDENT ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setCheckStatus(STUDENT)}
          >
            <img src={student_status_image} width="100%" alt="student" />
          </button>
        </div>

        <div className="insight-modal__footer">
          <div className="insight-modal__desc">
            请放心选择，以后还可以在个人中心更改
          </div>
          <button
            type="button"
            className="u-reset-button u-btn--black insight-modal__btn"
            onClick={this.next}
          >
            下一步
          </button>
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  goStep2: PropTypes.func.isRequired,
  checkStatus: PropTypes.string.isRequired,
  setCheckStatus: PropTypes.func.isRequired,
};

export default Step1;
