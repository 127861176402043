import React from "react";
import PropTypes from "prop-types";

import {
  vercode_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import { sudo } from "@shared/scripts/features/api";
import CustomizeInput from "../../shared/CustomizeInput";
import FormWrapper from "../../utils/FormWrapper";
import CountDownWrapper from "../../utils/CountDownWrapper";

class VerificationForm extends React.Component {
  sendCode = () => {
    if (this.props.countDownNum > 0) return;
    this.props.sendVerifyCode(this.props.countdown);
  };

  next = () => {
    if (!this.props.isFormValidate()) {
      return;
    }

    sudo(this.props.data.code.value).done(() => {
      this.props.nextStep();
    });
  };

  render() {
    const {
      data: { code },
      checkedType,
      user,
      changeValue,
      countDownNum,
    } = this.props;

    return (
      <div className="user-set__form">
        <p className="forget__resend-tip">验证身份</p>
        <form>
          <CustomizeInput
            value={checkedType === 0 ? user.mobile : user.email.email}
            placeholder={`验证${checkedType === 0 ? "手机号" : "邮箱"}`}
            disabled
          />
          <div className="u-pic-code">
            <CustomizeInput
              {...code}
              onChange={value => {
                changeValue(value, "code");
              }}
            />
            <button
              type="button"
              className={`u-reset-button u-input__btn ${
                countDownNum === 0 ? "" : "is-disabled"
              }`}
              onClick={this.sendCode}
              alt="获取验证码"
            >
              {countDownNum === 0 ? "获取验证码" : `重新发送(${countDownNum})`}
            </button>
          </div>
        </form>
        <button
          className="u-btn--black signin-form__submit"
          type="button"
          onClick={this.next}
        >
          下一步
        </button>
      </div>
    );
  }
}

VerificationForm.propTypes = {
  checkedType: PropTypes.number,
  user: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  sendVerifyCode: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
  countDownNum: PropTypes.number.isRequired,
  countdown: PropTypes.func.isRequired,
};

VerificationForm.defaultProps = {
  checkedType: 0,
};

export default FormWrapper({
  code: buildRules("user[phone_verify_code]", "验证码", [vercode_rule]),
})(CountDownWrapper(60)(VerificationForm));
