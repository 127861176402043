import React from "react";
import { NavLink } from "react-router-dom";
import { searchHeader } from "@shared/scripts/utils/site_text_constant";
import * as routes from "./routes";

const getUrl = pathname => ({
  pathname,
  search: routes.history.location.search,
});

const SearchNav = () => {
  return (
    <div className="u-secondary-nav">
      <nav className="u-container">
        <div className="search-header__types">
          {Object.keys(searchHeader).map(key => {
            const path = `/${key}`;
            return (
              <NavLink
                className="u-borbot"
                key={key}
                activeClassName="is-active"
                to={getUrl(path)}
              >
                <span className="u-borbot__item">{searchHeader[key]}</span>
              </NavLink>
            );
          })}
        </div>
      </nav>
    </div>
  );
};

export default SearchNav;
