import React from "react";
import { render } from "react-dom";

import UserInfo from "@components/setting/UserInfo";
import { getUserInfo } from "@shared/scripts/features/api";

const set = () => {
  getUserInfo().done(res => {
    const { user } = res;
    render(<UserInfo user={user} />, document.getElementById("js-user-info"));
  });
};

export default set;
