import React from "react";
import PropTypes from "prop-types";

import notice_none from "notice_none.png";
import {
  getReplyNotifications,
  readNotification,
  readAllNotifications,
} from "@shared/scripts/features/api";
import NoticeComment from "./NoticeComment";
import NoticeSystematic from "./NoticeSystematic";
// import NoticeDaily from './NoticeDaily';
import NoticeLoading from "./NoticeLoading";

const NoticeNone = ({ title }) => {
  return (
    <div className="notice-none">
      <img
        className="notice-none__cover"
        src={notice_none}
        width="150"
        alt="暂无通知"
      />
      <p className="notice-none__title">{title}</p>
    </div>
  );
};

NoticeNone.propTypes = {
  title: PropTypes.string,
};

NoticeNone.defaultProps = {
  title: "暂无内容",
};

class NoticePanel extends React.Component {
  state = {
    activeIndex: 0,
    notifications: [],
    systematic: [],
    isLoading: true,
  };

  componentDidMount() {
    // fetch data
    getReplyNotifications().done(({ notifications, systematic }) => {
      this.setState({ notifications, systematic, isLoading: false });
      this.props.setCount(notifications.length + systematic.length);
    });
  }

  read = id => {
    return readNotification(id).done(() => {
      this.props.reduceCount();
    });
  };

  readAll = () => {
    readAllNotifications().done(() => {
      $(this.refs.noticePanel)
        .find(".notice__item")
        .addClass("is-read");
    });
  };

  switchTab(index) {
    if (index === this.state.activeIndex) return;

    this.setState({ activeIndex: index });
  }

  render() {
    const { activeIndex, notifications, systematic, isLoading } = this.state;

    const replyContentList = notifications.map((item, index) => {
      return (
        <NoticeComment
          data={item}
          key={index}
          onRead={() => this.read(item.id)}
        />
      );
    });

    const NoticeSystematicList = systematic.map((item, index) => {
      return (
        <NoticeSystematic
          data={item}
          key={index}
          onRead={() => this.read(item.id)}
        />
      );
    });

    const notificationLength = notifications.length;
    const systematicLength = systematic.length;

    return (
      <div className="notice-panel" ref="noticePanel">
        <div className="notice-panel__header">
          <a
            className={`notice-panel__link ${
              activeIndex === 0 ? "is-active" : ""
            }`}
            href="javascript:;"
            alt="回复"
            onClick={() => {
              this.switchTab(0);
            }}
          >
            回复
            {notificationLength > 0 && <span>({notificationLength})</span>}
          </a>
          <a
            className={`notice-panel__link ${
              activeIndex === 1 ? "is-active" : ""
            }`}
            href="javascript:;"
            alt="通知"
            onClick={() => {
              this.switchTab(1);
            }}
          >
            通知
            {systematicLength > 0 && <span>({systematicLength})</span>}
          </a>
        </div>
        <div className="notice-panel__body">
          <div
            className={`notice-panel__list ${
              activeIndex === 0 ? "is-active" : ""
            }`}
          >
            {isLoading ? (
              <NoticeLoading />
            ) : notificationLength <= 0 ? (
              <NoticeNone />
            ) : (
              replyContentList
            )}
          </div>
          <div
            className={`notice-panel__list ${
              activeIndex === 1 ? "is-active" : ""
            }`}
          >
            {isLoading ? (
              <NoticeLoading />
            ) : systematicLength <= 0 ? (
              <NoticeNone title="暂无通知" />
            ) : (
              NoticeSystematicList
            )}
          </div>
        </div>
        <div className="notice-panel__footer">
          <a
            className="notice-panel__link"
            href="javascript:;"
            onClick={() => this.readAll()}
            alt="全部标记为已读"
          >
            全部标记为已读
          </a>
          <a
            className="notice-panel__right"
            href="/setting/notice"
            alt="查看全部"
          >
            查看全部
          </a>
        </div>
      </div>
    );
  }
}

NoticePanel.propTypes = {
  reduceCount: PropTypes.func.isRequired,
  setCount: PropTypes.func.isRequired,
};

export default NoticePanel;
