import React from "react";
import PropTypes from "prop-types";
import ahoy from "ahoy.js";
import EffectSwitch from "../utils/EffectSwitch";

class ReportSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      isShow: false,
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.AddTimeout();
  }

  componentWillReceiveProps(nextProps) {
    const { id, keywords } = this.props;
    const { id: nextId, keywords: nextKeywords } = nextProps;

    if (id !== nextId || keywords !== nextKeywords) {
      this.clearMyTimeOut();
      this.AddTimeout();
    }
  }

  componentWillUnmount() {
    this.clearMyTimeOut();
  }

  AddTimeout = () => {
    this.timeout = setTimeout(() => {
      this.toggleShow(true);

      this.timeout = setTimeout(() => {
        this.toggleShow(false);
      }, 30000);
    }, 3000);
  };

  clearMyTimeOut = () => {
    clearTimeout(this.timeout);
  };

  toggleShow = status => {
    this.setState({
      isShow: status,
    });

    if (!status) {
      this.clearMyTimeOut();
    }
  };

  say = type => {
    const { id, keywords } = this.props;
    ahoy.track("Trace", { id, klass: "Article", keywords, related: type });
    this.toggleShow(false);
  };

  sayNo = () => {
    this.say(false);
  };

  sayYes = () => {
    this.say(true);
  };

  render() {
    return (
      <EffectSwitch condition={this.state.isShow}>
        <div className="u-report-search">
          <div className="u-text-right">
            <span
              className="u-report-search--close"
              onClick={() => {
                this.toggleShow(false);
              }}
            >
              <i className="iconfont icon-iconguanbi" />
            </span>
          </div>
          <p className="u-report-search--title">该文章与本次搜索是否匹配?</p>
          <div className="u-text-right">
            <button
              className="u-report-search--btn"
              type="button"
              onClick={this.sayNo}
            >
              否
            </button>
            <button
              className="u-report-search--btn u-ml-10"
              type="button"
              onClick={this.sayYes}
            >
              是
            </button>
          </div>
        </div>
      </EffectSwitch>
    );
  }
}

ReportSearch.propTypes = {
  keywords: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ReportSearch.defaultProps = {
  keywords: "",
};

export default ReportSearch;
